import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

function DropdownModal({body,icon}) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
          <span className="absolute -inset-1.5" />
          {icon}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-3 py-3 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            {body}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DropdownModal;
