import { Tab } from "@headlessui/react";
import { CSVLink } from "react-csv";
import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ListFiles({ files }) {
    return (
        <div>
            {files.length > 0 &&
                files.map((file, index) => (
                    <p
                        key={index}
                        className="text-sm p-4 tracking-wider border"
                    >
                        {file.name}
                    </p>
                ))}
            {files.length === 0 && (
                <p className="text-start p-4 text-sm">
                    Currently, there is no process log available.
                </p>
            )}
        </div>
    );
}

function LogsTab({ processedfiles, unprocessedfiles }) {
    return (
        <div className="border mt-4">
            <Tab.Group as="div">
                <Tab.List className="flex justify-start bg-gray-50 border-b overflow-auto">
                    <div className="flex gap-2 md:gap-3">
                        <Tab
                            key={1}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <p className="text-sm pt-4 tracking-wider">
                                Processed Files{" "}
                                <span className="rounded-full p-1 bg-red-100">
                                    {processedfiles && processedfiles.length}
                                    {!processedfiles && "0"}
                                </span>
                            </p>
                        </Tab>
                        <Tab
                            key={2}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <p className="text-sm pt-4 tracking-wider">
                                Unprocessed Files{" "}
                                <span className="rounded-full p-1 bg-red-100">
                                    {unprocessedfiles &&
                                        unprocessedfiles.length}
                                    {!unprocessedfiles && "0"}
                                </span>
                            </p>
                        </Tab>
                    </div>
                </Tab.List>
                <Tab.Panels className="overflow-auto">
                    <Tab.Panel
                        key={1}
                        className={classNames(
                            "bg-white",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        <ListFiles files={processedfiles} />
                    </Tab.Panel>
                    <Tab.Panel
                        key={2}
                        className={classNames(
                            "bg-white",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        <ListFiles files={unprocessedfiles} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

const TranscriptionProcessLogs = ({
    logs,
    processedfiles,
    unprocessedfiles,
}) => {
    const csvData = logs.map((record, index) => ({
        "#": index + 1,
        "File Name": record.filename,
        Status: record.status,
        "Status Code": record.status_code,
        "Status Message": record.status_message,
        "Transcribed Detected lang": record.transcribed_detected_lang,
        "API Version": record.api_version,
        "Confidence Score": record.confidence_score,
    }));
    return (
        <div>
            {logs.length === 0 && (
                <p className="px-3 py-4">
                    Please complete the transcription process to view the logs.
                </p>
            )}
            {logs.length > 0 && (
                <>
                    <div className="flex justify-start p-4">
                        <CSVLink
                            className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                            data={csvData}
                            filename="current_logs.csv"
                        >
                            Download Logs
                        </CSVLink>
                    </div>
                    <table className="table-auto overflow-auto border-collapse whitespace-nowrap text-center w-full">
                        <thead>
                            <tr className="bg-gray-200 border-b">
                                <th className="px-4 py-2 border">#</th>
                                <th className="px-4 py-2 border">File Name</th>
                                <th className="px-4 py-2 border">Status</th>
                                <th className="px-4 py-2 border">
                                    Status Code
                                </th>
                                <th className="px-4 py-2 border">
                                    Status Message
                                </th>
                                <th className="px-4 py-2 border">
                                    Detected Lang
                                </th>
                                <th className="px-4 py-2 border">
                                    API Version
                                </th>
                                <th className="px-4 py-2 border">
                                    Confidence Score
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map((log, index) => (
                                <tr
                                    key={index}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                >
                                    <td className="border px-4 py-2">
                                        {index + 1}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {log.filename}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {log.status}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {log.status_code}
                                    </td>
                                    <td
                                        className="border px-4 py-2"
                                        title={log.status_message}
                                    >
                                        {log.status_message !== null &&
                                        log.status_message !== undefined
                                            ? log.status_message.slice(0, 20) +
                                              (log.status_message.length > 20
                                                  ? "..."
                                                  : "")
                                            : null}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {log.transcribed_detected_lang}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {log.api_version}
                                    </td>
                                    <td className="border px-4 py-2">
                                        {typeof log.confidence_score ===
                                        "number"
                                            ? log.confidence_score.toFixed(2)
                                            : log.confidence_score}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            <LogsTab
                processedfiles={processedfiles}
                unprocessedfiles={unprocessedfiles}
            />
        </div>
    );
};

export default TranscriptionProcessLogs;
