import React from 'react';
import { pageSizes } from './Contants';

const PageSizeDropdown = ({ dataLength, pageSize, handlePageChange, totalRows }) => {

    let filteredPageSizes = pageSizes.filter(size => size <= dataLength);

    if (dataLength < 10) {
        filteredPageSizes = [dataLength];
    } else {
        if (dataLength > 0 && !filteredPageSizes.includes(Math.min(...pageSizes))) {
            filteredPageSizes.unshift(Math.min(...pageSizes));
        }
        if (dataLength > 0 && !filteredPageSizes.includes(dataLength)) {
            filteredPageSizes.push(dataLength);
        }
    }

    return (
        <div className='flex flex-row items-center gap-2'>
            <span>Shows</span>
            <div className="sm:col-span-3">
                <div>
                    <select
                        id="records"
                        name="records"
                        value={pageSize}
                        onChange={handlePageChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                        {filteredPageSizes.map((page) => (
                            <option className='p-1' key={page} value={page}>{page}</option>
                        ))}
                    </select>
                </div>
            </div>
            <span>of {totalRows}</span>
        </div>
    );
};

export default PageSizeDropdown;
