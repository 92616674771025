import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../imports';

function VideoPlayer({filename, filetype}) {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/s3files/downloadfile`,
          {
            project_code: sessionStorage.getItem('projectCode'),
            file_name: filename,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            responseType: 'json',
          }
        );

        // // Decode the base64-encoded content to binary
        // const binaryContent = atob(response.data.download_content);

        // // Create a Blob from the binary content
        // const blob = new Blob([new Uint8Array([...binaryContent].map((char) => char.charCodeAt(0)))], {
        //   type: 'video/mp4',
        // });

        // // Create a URL object from the Blob
        // const url = window.URL.createObjectURL(blob);

        setVideoUrl(response.data.download_content);
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchData();
  }, [filename]);

  return (
    <div>
      {videoUrl && (
        <video width="640" height="360" controls>
          <source src={videoUrl} type={filetype} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default VideoPlayer;
