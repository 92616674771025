import React from "react";
import { Link } from "react-router-dom";
import images from "../../imports";
import { activity_log } from "../../imports";

function Admin() {
    const items = [
        {
            imgSrc: images["documents-folder--v1.png"],
            alt: "documents-folder--v1",
            text: "Project Management",
            to: "/project_management",
        },
        {
            imgSrc: images["conference-background-selected.png"],
            alt: "conference-background-selected",
            text: "User Management",
            to: "/user_management",
        },
        {
            imgSrc: images["icons8-exchange-48.png"],
            alt: "client-mgmt",
            text: "Client Management",
            to: "/client_management",
        },
        {
            imgSrc: images["role.png"],
            alt: "userrole-mgmt",
            text: "User Role Management",
            to: "/user_role_management",
        },
        {
            imgSrc: images["time-machine.png"],
            alt: "time-machine",
            text: "Logs Management",
            to: "/logs_management",
        },
        {
            imgSrc: images["icons8-product-documents-96.png"],
            alt: "time-machine",
            text: "Closed Project Triggers",
            to: "/closed_project_triggers",
        },
        {
            imgSrc: activity_log,
            alt: "activity_log",
            text: "Activity Log",
            to: "/activity_log",
        },
        {
            imgSrc: images["icons8-backend-development-48.png"],
            alt: "sql_execution",
            text: "SQL Execution",
            to: "/sql_execution",
        }
    ];
    return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            {items.map((item, index) => (
                <Link
                    to={item.to}
                    key={index}
                    className="bg-yellow-300 cursor-default lg:cursor-pointer flex flex-col tracking-wider space-y-4 items-center rounded-md py-20 p-5"
                >
                    <img
                        width="40"
                        height="40"
                        src={item.imgSrc}
                        alt={item.alt}
                    />
                    <p>{item.text}</p>
                </Link>
            ))}
        </div>
    );
}

export default Admin;
