import React from 'react'
import ActiveServiceUrls from './ActiveServiceUrls'
import InstanceMonitor from './InstanceMonitor'

function ServerMonitoring() {
  return (
    <div className='flex flex-col lg:flex-row gap-y-2 gap-x-4'><div className='w-full lg:w-1/2'><InstanceMonitor/></div><div className='w-full lg:w-1/2'><ActiveServiceUrls/></div></div>
  )
}

export default ServerMonitoring