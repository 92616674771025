import React, { useEffect, useState } from 'react'
import { ixologo, API_URL } from '../../imports'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError]=useState(false);
  const [rememberme,setRememberme]=useState(false);

  const navigate = useNavigate();

  const clearError = () => {
    setTimeout(() => {
        setUsernameError("");
        setPasswordError("");
        setLoginError("");
    }, 2000);
  };

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    const cookieOptions = [
      `${name}=${value}`,
      `expires=${expires.toUTCString()}`,
      'path=/',
      'secure', // Include 'secure' attribute for secure connections (HTTPS)
      'samesite=strict', // Include 'samesite' attribute for strict same-site enforcement
      'readonly', // Include 'readonly' attribute to prevent modification by JavaScript
    ].join('; ');
    document.cookie = cookieOptions;
};

const getCookie = (name) => {
    const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    return cookieValue ? cookieValue[2] : null;
};
  
const deleteCookie = (name) => {
    const cookieOptions = [
      `${name}=`,
      'expires=Thu, 01 Jan 1970 00:00:01 GMT',
      'path=/',
      'samesite=strict', // Include 'samesite' attribute for strict same-site enforcement
    ].join('; ');
    document.cookie = cookieOptions;
};


  const validateUsername = (value) => {
    // Regular expression for no spaces in between and not starting with a space
    const usernamePattern = /^[^\s]+$/;
    const errormsgusername= usernamePattern.test(value);
    return errormsgusername;
  };

  const validatePassword = (value) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()?_+])[\w!@#$%^&*?()_+]{8,}$/;
    const pwdError = passwordPattern.test(value);
    return pwdError;
  };
   
  const loginSubmit = async(event) => {
      event.preventDefault();
      event.preventDefault();

  // Input validation
  setUsernameError('');
  setPasswordError('');

  if (!username) {
    setUsernameError('Please enter your user id or email.');
  } else if (!validateUsername(username)) {
    setUsernameError('Please enter a valid email address or user id.');
  }

  if (!password) {
    setPasswordError('Please enter your password.');
  } else if (!validatePassword(password)) {
    setPasswordError('Please enter a valid password.');
  }

  if (username && password && validateUsername(username) && validatePassword(password)) {
      axios
              .post(`${API_URL}/auth/login`, {
                  username: username,
                  password: password,
              })
              .then((response) => {
                  if (response.data!==undefined){
                    localStorage.setItem("access_token",response.data.access_token);
                    localStorage.setItem("role",response.data.role);
                    localStorage.setItem("level",response.data.level);
                    localStorage.setItem("login_occurred",true);
                    
                    if(rememberme===true){
                      const rememberData = { storedUsername: username, storedPassword: password };
                      setCookie('rememberMegnadAutomation', JSON.stringify(rememberData), 7); // Store remember data in cookies for 7 days                         
                    }
                    else {
                      deleteCookie('rememberMegnadAutomation'); // Remove remember data from cookies
                    }
                  
                    if (response.data.role === "ADMIN") {
                        navigate("/admin");
                    } else if (response.data.role === "NONADMIN" && response.data.level===2) {
                        navigate("/projectmanager");
                    } else if (response.data.role === "NONADMIN" && response.data.level!==2) {
                      navigate("/projects");
                    }

                  } else{
                    setLoginError(true);
                    window.document.getElementById("errdsp").innerHTML="Oops something went wrong please try again";
                    clearError();
                  }
              })
              .catch((error) => {
                setLoginError(true);
                const errorMessage = handleApiError(error);
                window.document.getElementById("errdsp").innerHTML = errorMessage;
                clearError();
        });
  }};

  const auth_local = localStorage.getItem("access_token"); // determine if authorized, from context or however you're doing it
  const isAuthorized = ( auth_local ); // determine if authorized based on isAdmin prop
  const role_local = localStorage.getItem("role"); // determine if authorized, from context or however you're doing it
  const isRole = ( role_local ); // determine if authorized based on isAdmin prop
  const level_local = localStorage.getItem("level"); // determine if authorized, from context or however you're doing it
  const isLevel = ( level_local ); // determine if authorized based on isAdmin prop

  useEffect(() => {
      if(isAuthorized){
          if (isRole === "ADMIN") {
            navigate("/admin");
          } else if (isRole === "NONADMIN" && isLevel==="2") {
            navigate("/projectmanager");
          } else if (isRole === "NONADMIN" && isLevel!==2) {
            navigate("/projects");
          }
      }
  }, [isAuthorized,isRole,isLevel,navigate]);

    // Use effect to check if a login has occurred in other tabs
    useEffect(() => {
      const rememberMeData = getCookie('rememberMegnadAutomation');
      if (rememberMeData) {
            const { storedUsername, storedPassword } = JSON.parse(rememberMeData);
            setUsername(storedUsername);
            setPassword(storedPassword);
            setRememberme(true);
      }
      const handleStorageChange = () => {
        const loginOccurred = localStorage.getItem("login_occurred");
        if (loginOccurred === "true") {
          // Clear the flag
          localStorage.removeItem("login_occurred");
          // Reload the page
          window.location.reload();
        }
      };
  
      window.addEventListener("storage", handleStorageChange);
  
      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }, []);
  
  return (
    <div className='flex flex-col w-full md:w-3/5 xl:w-3/6 2xl:w-2/5 gap-4'>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src={ixologo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${loginError ? 'block' : 'hidden'}`}></p>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-5" onSubmit={loginSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                User ID / Email
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="email"
                  value={username}
                  onChange={(event) =>
                      setUsername(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  ${usernameError ? 'ring-red-300' : ''}`}
                />
                 {usernameError && <p className="text-xs mt-2 text-red-500">{usernameError}</p>}
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) =>
                      setPassword(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${passwordError ? 'ring-red-300' : ''}`}
                />
                 {passwordError && <p className="text-xs mt-2 text-red-500">{passwordError}</p>}
              </div>
            </div>
            <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      checked={rememberme} 
                      onChange={(e) => setRememberme(e.target.checked)}
                      className="h-4 w-4 me-1.5 rounded border text-indigo-600 focus:ring-indigo-600"
                    />Remember Me
                </label>
                <div className="text-sm">
                  <Link to='/forgotpassword' className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </Link>
                </div>
      </div>
            <div>
              <button
                type="submit"
                className="flex w-full tracking-widest justify-center rounded bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
          </div>
    </div>
  )
}

export default Login