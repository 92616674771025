import React, { useCallback, useEffect, useState } from 'react';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon, EyeIcon } from '@heroicons/react/24/outline';
import { initialpageSize } from '../Common/Contants';
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports'
import { DebouncedInput } from '../Common/Filters';
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import PageSizeDropdown from '../Common/PageSizeDropdown';
import ReadModal from '../Common/ReadModal';
import { formattedDate } from '../Common/CommonUtils';

export default function AlterTableLogs() {
    const [Loader, setLoader] = useState(true)
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    let [isOpen, setIsOpen] = useState(false);
    let [modalTitle, setModalTitle] = useState('');  
    const [jsonValue,setJsonValue]=useState(null);
  
    function closeModal() {
      setIsOpen(false)
    }

    const getProcessResponse = useCallback(async(id) =>{
        axios.get(
                `${API_URL}/alter/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setJsonValue(response.data.data);
            })
            .catch((error) => {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
            })
    },[]);

    const openReadModal = useCallback((e)=> {
        getProcessResponse(e.id)
        setModalTitle(`SQL Execution of ${formattedDate(e.creation_date)}`);
        setIsOpen(true);
    },[getProcessResponse])

    const ReloadData = async () => {
        setLoader(true);
        axios.get(`${API_URL}/alter`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    };

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'index',
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: '#',
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: 'message',
                enableColumnFilter: false,
                size: 700,
                header: () => <span>Process Message</span>
            },
            {
                accessorKey: 'creation_date',
                enableColumnFilter: false,
                size: 50,
                header: 'Created Date',
                cell: ({ row }) => (
                    <div>{formattedDate(row.original.creation_date)}</div>
                )
            },
            {
                accessorKey: 'creation_by',
                enableColumnFilter: false,
                size: 50,
                header: 'Created By',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                  <div className='flex justify-center'><div className={`${row.original.status === true ? 'bg-green-300  text-green-900' : 'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}>{row.original.status === true ? "Success" : "Failed"}</div></div>
                )
              },
            {
                accessorKey: 'id',
                header: 'Actions',
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className='flex gap-2 items-center justify-center'>
                        <button className="p-1 rounded-md border" onClick={() => openReadModal(row.original)}>
                            <EyeIcon className='h-4 text-blue-800' />
                        </button>
                    </div>
                )
            },
        ],
        [openReadModal]
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState('');
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);


    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0)
    };

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(`${API_URL}/alter`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoader(false);
                    }, 100);
                });
        };
        fetchInitialData();
    }, []);


    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <ReadModal isOpen={isOpen} closeModal={closeModal} title={modalTitle}
                modalInputs={
                <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                {jsonValue && (
                    <pre className="pretxtwrap text-sm">
                        {jsonValue}
                    </pre>
                )}
                {!jsonValue && "No Response Found."}
                </div>
                } 
            />
            <div className='flex justify-between m-3 gap-2'>
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className='flex gap-2'>
                    <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
                        <ArrowPathIcon className='h-5 text-green-700' />
                    </button>
                </div>
            </div>
            {Loader && <FetchingDataLoader />}
            {!Loader &&
                <>
                    <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto'>
                        <table className='flex-inline w-full border-collapse'>
                            <thead className='bg-gray-100'>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className='border p-2 whitespace-nowrap'
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{ width: header.getSize() }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none justify-start'
                                                                    : 'justify-center'
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: ' 🔼',
                                                                    desc: ' 🔽',
                                                                }[header.column.getIsSorted()] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td className='border p-2' style={{ height: `${paddingTop}px` }} />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr key={row.id}>
                                                            {row.getVisibleCells().map((cell) => {
                                                                return (
                                                                    <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                                                        {cell.column.id === "index" && (
                                                                            <center>{renderTableCellIndex(row)}</center>
                                                                        )}
                                                                        {cell.column.id !== "index" &&
                                                                        flexRender(
                                                                            cell.column
                                                                                .columnDef
                                                                                .cell,
                                                                            cell.getContext()
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }

                            </tbody>
                        </table>
                    </div>
                    {
                        rows.length > 0 && (
                            <div className='flex gap-1 justify-between flex-row-reverse m-3'>
                                <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
                                    <button title='First Page' onClick={() => setPageIndex(0)}
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                    <button title='Previous Page'
                                        onClick={() => setPageIndex(pageIndex - 1)}
                                        disabled={pageIndex === 0}
                                        aria-current="page"
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                    {pageNumbers.length===1 &&
                                        <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={1}
                                        onClick={()=>setPageIndex(0)}
                                        >
                                        {1}
                                        </button>
                                    }
                                    {pageNumbers.length>1 && pageNumbers.map((pageNumber, index) => {
                                        if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                        if(table.getPageCount()!==0 && table.getPageCount()===1 && table.getPageCount()===pageNumber) {
                                            return (
                                            <button
                                                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                key={index}
                                                onClick={()=>setPageIndex(pageNumber-1)}
                                            >
                                            {pageNumber}
                                            </button>
                                            );
                                        }
                                        else if(table.getPageCount()!==1 && table.getPageCount()>pageNumber) {
                                            return (
                                            <button
                                                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                key={index}
                                                onClick={()=>setPageIndex(pageNumber)}
                                            >
                                            {pageNumber + 1}
                                            </button>
                                            );
                                        }
                                        else if(table.getPageCount()===0  && table.getPageCount()===pageNumber){
                                            return (
                                            <button
                                                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                key={index}
                                                onClick={()=>setPageIndex(0)}
                                            >
                                            {1}
                                            </button>
                                            );
                                        }
                                        } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                        return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                        }
                                        return null;
                                    })}
                                    <button title='Next Page' onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === (table.getPageCount() - 1)}
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                    <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </nav>
                                <PageSizeDropdown
                                    dataLength={table.getPrePaginationRowModel().rows.length}
                                    pageSize={pageSize}
                                    handlePageChange={handlePageChange}
                                    totalRows={table.getPrePaginationRowModel().rows.length}
                                />
                            </div>
                        )
                    }
                </>
            }
        </div >
    );
}