import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react'

function ConfirmationPopup({ isOpenConfirmPopup, confirmationMsg, closeConfirmPopup, onStateChange, checkVisible, checkState, onCheckChange }) {
  return (
    <Transition appear show={isOpenConfirmPopup} as={Fragment}>
<Dialog
  as="div"
  className="relative align-middle justify-center flex z-10"
  onClose={closeConfirmPopup}
>
  {/* <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="position-fixed inset-0 bg-black opacity-25" />
  </Transition.Child> */}

  <div className="fixed inset-0 overflow-y-auto">
    <div className="flex align-middle justify-center p-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Dialog.Panel className="transform overflow-hidden font-GoogleSans tracking-wider rounded-lg bg-white p-3 text-start justify-center align-middle shadow border transition-all">
          <div className="mt-2 text-break">
            <p className='flex text-sm'><ExclamationTriangleIcon className="me-1 text-red-600" style={{height:"22px",width:"22px"}}/>{confirmationMsg}</p>
          </div>
          {checkVisible && <div className='flex items-center justify-end gap-x-2 mt-2'><input type="checkbox" checked={checkState} onChange={onCheckChange} className='rounded'/><p className='text-sm'>OFF the Server, once processing is done</p></div>}
          <div className="flex gap-2 w-100 justify-end mt-4">
          <button
               className="inline-flex justify-center rounded-lg border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
               onClick={onStateChange}
            >
              Yes
            </button>
            <button
               className="inline-flex justify-center rounded-lg border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
               onClick={closeConfirmPopup}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>
</Transition>
  )
}

export default ConfirmationPopup