import React, { Fragment, useEffect, useState } from "react";
import { Combobox, Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { setProjectFilterOptions } from "../../actions";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URL } from '../../imports'
import { handleApiError } from "../Common/APIUtils";

function ProjectFilter() {
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState('');
  const [prquery, setprQuery] = React.useState('');
  const [selectedGroup, setSelectedGroup] = useState("");
  const [beforeSelectedGroup, setBeforeSelectedGroup] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  // const [selectedTag, setSelectedTag] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [projectGroups, setProjectGroups] = useState([]);
  const [projects, setProjects] = useState([]);
  // const [tags, setTags] = useState([]);

  useEffect(()=>{
  const fetchProjects = async () => {
    axios.get(`${API_URL}/projectusers/filters`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                )}`,
            },
        })
        .then((response) => {
            setProjectGroups(response.data.data.project_grp_list);
            setProjects(response.data.data.project_list);
            // setTags(response.data.data.project_tag_list);
        })
        .catch((error) => {
            const errorMessage = handleApiError(error);
            console.log(errorMessage)
            // setMsg(errorMessage);
            // openPopup();
        });
};
fetchProjects();
}, []);

  useEffect(() => {
    if(selectedGroup!==beforeSelectedGroup){setBeforeSelectedGroup(selectedGroup);setSelectedProject('')}
    const sessionStorageData = {
      proj_grp_code: selectedGroup,
      project_code: selectedGroup !== beforeSelectedGroup ? '' : selectedProject,
      // proj_tag_code: selectedTag,
      project_status: selectedStatus ? selectedStatus : undefined,
    };

    dispatch(setProjectFilterOptions(sessionStorageData));
  }, [selectedGroup, selectedProject, selectedStatus, beforeSelectedGroup, dispatch]);

  const filteredItems = query === ''
  ? projectGroups
  : projectGroups.filter((item) =>
        item && item['proj_grp_name'].toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
    );

    const filteredProjects = projects.filter(item => {
      const isGroupMatch = selectedGroup ? item.proj_grp_code === selectedGroup : true;
      const isQueryMatch = prquery 
        ? item.project_name.toLowerCase().replace(/\s+/g, '').includes(prquery.toLowerCase().replace(/\s+/g, ''))
        : true;
    
      return isGroupMatch && isQueryMatch;
    });
    

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="relative justify-center border border-blue-300 bg-blue-100 px-2 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
          <span className="absolute -inset-1.5" />
          <FunnelIcon className="h-5 w-5" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 p-2 space-y-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            <label
              htmlFor="push-everything"
              className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
            >
              Project Group
            </label>
            <div className="w-full">
              <Combobox value={selectedGroup} onChange={setSelectedGroup}>
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left border border-gray-300 p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                    <Combobox.Input
                      className="w-full border-none py-1 pl-2.5 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                      displayValue={(value) => projectGroups.find(item => item.proj_grp_code === value)?.proj_grp_name || 'All'}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                  >
                    <Combobox.Options className="absolute z-20 mt-1 max-h-48 md:max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-0 border border-gray-300 shadow-lg focus:outline-none focus:ring-0 sm:text-sm">
                      {filteredItems.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none px-4 py-1 text-gray-700">
                          No Project Groups Found
                        </div>
                      ) : (
                        <>
                        <Combobox.Option
                            className={({ active }) =>
                              `relative cursor-default select-none py-1 pl-10 pr-4 ${
                                active ? 'bg-indigo-900 text-white' : 'text-gray-900'
                              }`
                            }
                            value={''}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block whitespace-pre-wrap text-xs md:text-sm ${selected ? 'font-medium' : 'font-normal'}`}
                                >
                                  All
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-indigo-900'
                                    }`}
                                  >
                                    <CheckIcon
                                      className="h-4 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        {filteredItems.map((item, index) => (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-1 pl-10 pr-4 ${
                                active ? 'bg-indigo-900 text-white' : 'text-gray-900'
                              }`
                            }
                            value={item.proj_grp_code}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block whitespace-pre-wrap break-words text-xs md:text-sm ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {item.proj_grp_name}
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-indigo-900'
                                    }`}
                                  >
                                    <CheckIcon
                                      className="h-4 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                        </>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
          <div>
            <label
              htmlFor="push-everything"
              className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
            >
              Project
            </label>
            <div className="w-full">
              <Combobox value={selectedProject} onChange={setSelectedProject}>
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left border border-gray-300 p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                    <Combobox.Input
                      className="w-full border-none py-1 pl-2.5 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                      displayValue={(value) => projects.find(item => item.project_code === value)?.project_name || 'All'}
                      onChange={(event) => setprQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setprQuery('')}
                  >
                    <Combobox.Options className="absolute z-20 mt-1 max-h-48 md:max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-0 border border-gray-300 shadow-lg focus:outline-none focus:ring-0 sm:text-sm">
                      {filteredProjects.length === 0 && prquery !== '' ? (
                        <div className="relative cursor-default select-none px-4 py-1 text-gray-700">
                          No Project Groups Found
                        </div>
                      ) : (
                        <>
                        <Combobox.Option
                            className={({ active }) =>
                              `relative cursor-default select-none py-1 pl-10 pr-4 ${
                                active ? 'bg-indigo-900 text-white' : 'text-gray-900'
                              }`
                            }
                            value={''}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block whitespace-pre-wrap text-xs md:text-sm ${selected ? 'font-medium' : 'font-normal'}`}
                                >
                                  All
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-indigo-900'
                                    }`}
                                  >
                                    <CheckIcon
                                      className="h-4 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        {filteredProjects.map((item, index) => (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-1 pl-10 pr-4 ${
                                active ? 'bg-indigo-900 text-white' : 'text-gray-900'
                              }`
                            }
                            value={item.project_code}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block whitespace-pre-wrap break-words text-xs md:text-sm ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {item.project_name}
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-indigo-900'
                                    }`}
                                  >
                                    <CheckIcon
                                      className="h-4 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                        </>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
          {/* <div>
            <label
              htmlFor="push-everything"
              className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
            >
              Tag
            </label>
            <select
              id="process"
              name="process"
              className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
            >
              <option value="">All</option>
                {tags.map((tag, index) => (
                  <option key={index} value={tag.proj_tag_code}>
                    {tag.proj_tag_name}
                  </option>
                ))}
            </select>
          </div> */}
          <div>
            <label
              htmlFor="push-everything"
              className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
            >
              Status
            </label>
            <select
              id="process"
              name="process"
              className="block w-44 md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value={1}>Open</option>
              <option value={0}>Hold</option>
              <option value={2}>Close</option>
            </select>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ProjectFilter;
