import React from "react";

function ButtonsBar({ onArchiveClick, onRestoreClick, onDeleteClick }) {
    return (
        <div className="resp-table-h overflow-auto">
            <div className="flex gap-2">
                <div>
                    <button
                        className="border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={onArchiveClick}
                    >
                        Archive
                    </button>
                </div>
                <div>
                    <button
                        className="border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={onRestoreClick}
                    >
                        Restore
                    </button>
                </div>
                <div className="flex gap-2">
                    <button
                        className="border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={onDeleteClick}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ButtonsBar;
