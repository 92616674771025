import React, { useState, useCallback, useEffect } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { DebouncedInput, IndeterminateCheckbox } from "./Filters";
import FetchingDataLoader from "./FetchingDataLoader";
import {
    ArrowPathIcon,
    EyeIcon,
    ArrowDownTrayIcon,
    FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import ReadModal from "./ReadModal";
import { API_URL } from "../../imports";
import axios from "axios";
import IndexwiseJsonTable from "./IndexwiseJsonTable";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import TitlewiseJsonTable from "./TitlewiseJsonTable";
import JSZip from "jszip";
import DownloadingLoader from "./DownloadingLoader";
import ResponseLoader from "./ResponseLoader";
import SingleDownloadLoader from "./SingleDownloadLoader";

export default function TransposeFlatteningMappingProcess({ fetchDataURL }) {
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [sorting, setSorting] = React.useState([]);
    const [rowSelection, setRowSelection] = React.useState({});
    let [modalTitle, setModalTitle] = useState(false);
    let [isOpenRead, setIsOpenRead] = useState(false);
    const [Loader, setLoader] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [transposeViewState, setTransposeViewState] = useState(false);
    let [jsonValue, setJsonValue] = useState({});
    const [data, setData] = useState([]);
    let [downloadLoader, setDownloadLoader] = useState(false);
    let [downloadpqLoader, setDownloadPQLoader] = useState(false);
    let [singleDownloadLoader, setSingleDownloadLoader] = useState(false);
    let [responseLoader, setResponseLoader] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    function closeReadModal() {
        setIsOpenRead(false);
    }

    const getProcessName = (fetchDataURLprop) => {
        switch (fetchDataURLprop) {
            case "gettransposedfiles":
                return "Transpose";
            case "getflattenedfiles":
                return "Flattening";
            case "getmappedfiles":
                return "Mapping";
            default:
                return "";
        }
    };

    const openReadModal = useCallback(
        (e) => {
            setResponseLoader(true);
            axios
                .post(
                    `${API_URL}/Transpose/getresponse/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        id: e.id,
                        process_code: getProcessName(fetchDataURL),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const decodedJsonString = atob(response.data.response);
                    setJsonValue(JSON.parse(decodedJsonString));
                    if (fetchDataURL === "gettransposedfiles") {
                        setModalTitle(`Transpose of ${e.filename}`);
                    } else if (fetchDataURL === "getflattenedfiles") {
                        setModalTitle(`Flattening of ${e.filename}`);
                    } else if (fetchDataURL === "getmappedfiles") {
                        setModalTitle(`Mapping of ${e.filename}`);
                    }

                    setResponseLoader(false);
                    setIsOpenRead(true);
                    // setJsonValue(response.data.data);

                    // Decode the base64 string
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setResponseLoader(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [fetchDataURL, setModalTitle, openPopup, setIsOpenRead]
    );

    const handleCsvDownload = useCallback(
        (file) => {
            setSingleDownloadLoader(true);
            axios
                .post(
                    `${API_URL}/Transpose/getCSV/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        id: file.id,
                        process_code: getProcessName(fetchDataURL),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const binaryContent = atob(response.data.csv);

                    // Create a Uint8Array from the binary content
                    const uint8Array = new Uint8Array(binaryContent.length);
                    for (let i = 0; i < binaryContent.length; i++) {
                        uint8Array[i] = binaryContent.charCodeAt(i);
                    }

                    // Create a Blob from the Uint8Array
                    const blob = new Blob([uint8Array], {
                        type: "text/csv",
                    }); // Adjust type based on your file type

                    // Create a URL object from the Blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element
                    const link = document.createElement("a");
                    link.href = url;
                    link.download =
                        getProcessName(fetchDataURL) +
                        "_" +
                        file.filename +
                        ".csv";

                    // Append the link to the document body
                    document.body.appendChild(link);

                    setSingleDownloadLoader(false);

                    // Trigger the download
                    link.click();

                    // Cleanup
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setSingleDownloadLoader(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [fetchDataURL, openPopup]
    );

    // const DownloadCSVAPI = async (id_list) => {
    //     setRowSelection({});
    //     const zip = new JSZip();
    //     axios
    //         .post(
    //             `${API_URL}/Transpose/getMultipleCSV/${sessionStorage.getItem(
    //                 "projectCode"
    //             )}`,
    //             {
    //                 filesid_list: id_list,
    //                 process_code: getProcessName(fetchDataURL),
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem(
    //                         "access_token"
    //                     )}`,
    //                 },
    //             }
    //         )
    //         .then(async (response) => {
    //             setDownloadLoader(false);

    //             if (getProcessName(fetchDataURL) === "Transpose") {
    //                 // Decode the base64-encoded content to binary
    //                 const rawbinaryContent = atob(response.data.raw_csv);

    //                 const refinedbinaryContent = atob(
    //                     response.data.refined_csv
    //                 );

    //                 zip.file(`combined_raw.csv`, rawbinaryContent);
    //                 zip.file(`combined_refined.csv`, refinedbinaryContent);
    //                 // Generate the zip file

    //                 const zipContent = await zip.generateAsync({
    //                     type: "blob",
    //                 });

    //                 // Create a download link and trigger the download
    //                 const downloadLink = document.createElement("a");
    //                 downloadLink.href = URL.createObjectURL(zipContent);
    //                 downloadLink.download = `${getProcessName(
    //                     fetchDataURL
    //                 )}_${sessionStorage.getItem("projectName")}.zip`;
    //                 downloadLink.click();
    //             } else {
    //                 // Decode the base64-encoded content to binary
    //                 const binaryContent = atob(response.data.csv);

    //                 // Create a Uint8Array from the binary content
    //                 const uint8Array = new Uint8Array(binaryContent.length);
    //                 for (let i = 0; i < binaryContent.length; i++) {
    //                     uint8Array[i] = binaryContent.charCodeAt(i);
    //                 }

    //                 // Create a Blob from the Uint8Array
    //                 const blob = new Blob([uint8Array], {
    //                     type: "text/csv",
    //                 }); // Adjust type based on your file type

    //                 // Create a URL object from the Blob
    //                 const url = window.URL.createObjectURL(blob);

    //                 // Create a link element
    //                 const link = document.createElement("a");
    //                 link.href = url;
    //                 link.download = `${getProcessName(
    //                     fetchDataURL
    //                 )}_${sessionStorage.getItem("projectName")}.csv`;

    //                 // Append the link to the document body
    //                 document.body.appendChild(link);

    //                 // Trigger the download
    //                 link.click();

    //                 // Cleanup
    //                 document.body.removeChild(link);
    //                 window.URL.revokeObjectURL(url);
    //             }
    //         })
    //         .catch((error) => {
    //             const errorMessage = handleApiError(error);
    //             setDownloadLoader(false);
    //             setMsg(errorMessage);
    //             setIsSuccess(false);
    //             openPopup();
    //         });
    // };

    // Everything in zipped format
    // const DownloadCSVAPI = async (id_list) => {
    //     setRowSelection({});
    //     const zip = new JSZip();
    //     axios
    //         .post(
    //             `${API_URL}/Transpose/getMultipleCSV/${sessionStorage.getItem(
    //                 "projectCode"
    //             )}`,
    //             {
    //                 filesid_list: id_list,
    //                 process_code: getProcessName(fetchDataURL),
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem(
    //                         "access_token"
    //                     )}`,
    //                 },
    //             }
    //         )
    //         .then(async (response) => {
    //             setDownloadLoader(false);

    //             if (getProcessName(fetchDataURL) === "Transpose") {
    //                 // Decode the base64-encoded content to binary
    //                 const rawbinaryContent = atob(response.data.raw_csv);
    //                 const refinedbinaryContent = atob(
    //                     response.data.refined_csv
    //                 );

    //                 zip.file(`combined_raw.csv`, rawbinaryContent);
    //                 zip.file(`combined_refined.csv`, refinedbinaryContent);
    //             } else {
    //                 // Decode the base64-encoded content to binary
    //                 const binaryContent = atob(response.data.csv);

    //                 // Create a Uint8Array from the binary content
    //                 const uint8Array = new Uint8Array(binaryContent.length);
    //                 for (let i = 0; i < binaryContent.length; i++) {
    //                     uint8Array[i] = binaryContent.charCodeAt(i);
    //                 }

    //                 // Add the CSV file to the ZIP
    //                 zip.file(
    //                     `${getProcessName(
    //                         fetchDataURL
    //                     )}_${sessionStorage.getItem("projectName")}.csv`,
    //                     uint8Array
    //                 );
    //             }

    //             // Generate the zip file
    //             const zipContent = await zip.generateAsync({ type: "blob" });

    //             // Create a download link and trigger the download
    //             const downloadLink = document.createElement("a");
    //             downloadLink.href = URL.createObjectURL(zipContent);
    //             downloadLink.download = `${getProcessName(
    //                 fetchDataURL
    //             )}_${sessionStorage.getItem("projectName")}.zip`;
    //             downloadLink.click();
    //         })
    //         .catch((error) => {
    //             const errorMessage = handleApiError(error);
    //             setDownloadLoader(false);
    //             setMsg(errorMessage);
    //             setIsSuccess(false);
    //             openPopup();
    //         });
    // };
    const DownloadCSVAPI = async (id_list, download_format) => {
        setRowSelection({});
        const zip = new JSZip();
        axios
            .post(
                `${API_URL}/Transpose/getMultipleCSV/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    filesid_list: id_list,
                    process_code: getProcessName(fetchDataURL),
                    option: download_format
                },
                {
                    headers: {
                        // Authorization: `Bearer ${localStorage.getItem(
                        //     "access_token"
                        // )}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then(async (response) => {
                if(download_format==='csv'){setDownloadLoader(false)}
                else{setDownloadPQLoader(false)}
                if (getProcessName(fetchDataURL) === "Transpose") {
                    // Decode the base64-encoded content to binary
                    const rawbinaryContent = atob(response.data.raw_parquet);
                    const refinedbinaryContent = atob(
                        response.data.refined_parquet
                    );
                    
                    zip.file(`combined_raw.${download_format}`, rawbinaryContent, {
                        binary: true,
                    });
                    zip.file(`combined_refined.${download_format}`, refinedbinaryContent, {
                        binary: true,
                    });
                } else {
                    // Decode the base64-encoded content to binary
                    const binaryContent = atob(response.data.parquet);

                    // Add the Parquet file to the ZIP
                    zip.file(
                        `${getProcessName(
                            fetchDataURL
                        )}_${sessionStorage.getItem("projectName")}.${download_format}`,
                        binaryContent,
                        { binary: true }
                    );
                }

                // Generate the zip file
                const zipContent = await zip.generateAsync({ type: "blob" });

                // Create a download link and trigger the download
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(zipContent);
                downloadLink.download = `${getProcessName(
                    fetchDataURL
                )}_${sessionStorage.getItem("projectName")}.zip`;
                downloadLink.click();
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                if(download_format==='csv'){setDownloadLoader(false)}
                else{setDownloadPQLoader(false)}
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };
    const handleDownloadCSV = (download_format) => {
        if(download_format==='csv'){setDownloadLoader(true)}
        else{setDownloadPQLoader(true)}
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
            const fileIds = selectedFiles.map((file) => file.id);
            DownloadCSVAPI(fileIds, download_format);
        } else if (selectedFiles.length === data.length) {
            DownloadCSVAPI("All", download_format);
        } else {
            DownloadCSVAPI("All", download_format);
        }
    };

    // const handleZipDownload = useCallback(async () => {
    //     const zip = new JSZip();
    //     let combined = [];
    //     let combined_raw = [];
    //     let combined_refined = [];
    //     // Add each JSON file to the zip
    //     const selectedProcessedFiles = Object.keys(rowSelection)
    //         .filter((index) => rowSelection[index])
    //         .map((index) => data[index]);
    //     if (selectedProcessedFiles.length > 0) {
    //         selectedProcessedFiles.forEach((file, index) => {
    //             // Decode the base64-encoded content to binary
    //             let binaryContent = atob(file.csv);
    //             let binaryContent_raw_transpose =
    //                 fetchDataURL === "gettransposedfiles"
    //                     ? atob(file.raw_csv)
    //                     : null;
    //             let binaryContent_refined_transpose =
    //                 fetchDataURL === "gettransposedfiles"
    //                     ? atob(file.refined_csv)
    //                     : null;

    //             // If it's not the first file, remove the first row
    //             if (fetchDataURL !== "gettransposedfiles" && index !== 0) {
    //                 const lines = binaryContent.split("\n");
    //                 lines.shift(); // Remove the first row
    //                 lines.forEach((line, lineNumber) => {
    //                     const columns = line.split(",");
    //                     // columns.shift(); // Remove the first column
    //                     lines[lineNumber] = columns.join(",");
    //                 });
    //                 binaryContent = lines.join("\n");
    //             } else if (fetchDataURL !== "gettransposedfiles") {
    //                 const lines = binaryContent.split("\n");
    //                 lines.forEach((line, lineNumber) => {
    //                     const columns = line.split(",");
    //                     // columns.shift(); // Remove the first column
    //                     lines[lineNumber] = columns.join(",");
    //                 });
    //                 binaryContent = lines.join("\n");
    //             } else if (
    //                 fetchDataURL === "gettransposedfiles" &&
    //                 index === 0
    //             ) {
    //                 // RAW
    //                 const raw_lines = binaryContent_raw_transpose.split("\n");
    //                 raw_lines.shift(); // Remove the first row
    //                 binaryContent_raw_transpose = raw_lines.join("\n");
    //                 // REFINED
    //                 const refined_lines =
    //                     binaryContent_refined_transpose.split("\n");
    //                 refined_lines.shift(); // Remove the first row
    //                 binaryContent_refined_transpose = refined_lines.join("\n");
    //             } else {
    //                 // RAW
    //                 const raw_lines = binaryContent_raw_transpose.split("\n");
    //                 raw_lines.shift(); // Remove the first row
    //                 raw_lines.shift();
    //                 binaryContent_raw_transpose = raw_lines.join("\n");
    //                 // REFINED
    //                 const refined_lines =
    //                     binaryContent_refined_transpose.split("\n");
    //                 refined_lines.shift(); // Remove the first row
    //                 refined_lines.shift();
    //                 binaryContent_refined_transpose = refined_lines.join("\n");
    //             }

    //             if (fetchDataURL === "gettransposedfiles") {
    //                 // RAW
    //                 const uint8Array_raw = new Uint8Array(
    //                     binaryContent_raw_transpose.length
    //                 );
    //                 for (
    //                     let i = 0;
    //                     i < binaryContent_raw_transpose.length;
    //                     i++
    //                 ) {
    //                     uint8Array_raw[i] =
    //                         binaryContent_raw_transpose.charCodeAt(i);
    //                     combined_raw.push(uint8Array_raw[i]);
    //                 }
    //                 // REFINED
    //                 const uint8Array_refined = new Uint8Array(
    //                     binaryContent_refined_transpose.length
    //                 );
    //                 for (
    //                     let i = 0;
    //                     i < binaryContent_refined_transpose.length;
    //                     i++
    //                 ) {
    //                     uint8Array_refined[i] =
    //                         binaryContent_refined_transpose.charCodeAt(i);
    //                     combined_refined.push(uint8Array_refined[i]);
    //                 }
    //             } else {
    //                 // Create a Uint8Array from the binary content
    //                 const uint8Array = new Uint8Array(binaryContent.length);
    //                 for (let i = 0; i < binaryContent.length; i++) {
    //                     uint8Array[i] = binaryContent.charCodeAt(i);
    //                     combined.push(uint8Array[i]);
    //                 }
    //             }
    //             // zip.file(`${file.filename}.csv`, uint8Array);
    //         });
    //     } else {
    //         data.forEach((file, index) => {
    //             let binaryContent = atob(file.csv);
    //             let binaryContent_raw_transpose =
    //                 fetchDataURL === "gettransposedfiles"
    //                     ? atob(file.raw_csv)
    //                     : null;
    //             let binaryContent_refined_transpose =
    //                 fetchDataURL === "gettransposedfiles"
    //                     ? atob(file.refined_csv)
    //                     : null;
    //             // If it's not the first file, remove the first row
    //             if (fetchDataURL !== "gettransposedfiles" && index !== 0) {
    //                 const lines = binaryContent.split("\n");
    //                 lines.shift(); // Remove the first row
    //                 lines.forEach((line, lineNumber) => {
    //                     const columns = line.split(",");
    //                     // columns.shift(); // Remove the first column
    //                     lines[lineNumber] = columns.join(",");
    //                 });
    //                 binaryContent = lines.join("\n");
    //             } else if (fetchDataURL !== "gettransposedfiles") {
    //                 const lines = binaryContent.split("\n");
    //                 lines.forEach((line, lineNumber) => {
    //                     const columns = line.split(",");
    //                     // columns.shift(); // Remove the first column
    //                     lines[lineNumber] = columns.join(",");
    //                 });
    //                 binaryContent = lines.join("\n");
    //             } else if (
    //                 fetchDataURL === "gettransposedfiles" &&
    //                 index === 0
    //             ) {
    //                 // RAW
    //                 const raw_lines = binaryContent_raw_transpose.split("\n");
    //                 raw_lines.shift(); // Remove the first row
    //                 binaryContent_raw_transpose = raw_lines.join("\n");
    //                 // REFINED
    //                 const refined_lines =
    //                     binaryContent_refined_transpose.split("\n");
    //                 refined_lines.shift(); // Remove the first row
    //                 binaryContent_refined_transpose = refined_lines.join("\n");
    //             } else {
    //                 // RAW
    //                 const raw_lines = binaryContent_raw_transpose.split("\n");
    //                 raw_lines.shift(); // Remove the first row
    //                 raw_lines.shift();
    //                 binaryContent_raw_transpose = raw_lines.join("\n");
    //                 // REFINED
    //                 const refined_lines =
    //                     binaryContent_refined_transpose.split("\n");
    //                 refined_lines.shift(); // Remove the first row
    //                 refined_lines.shift();
    //                 binaryContent_refined_transpose = refined_lines.join("\n");
    //             }

    //             if (fetchDataURL === "gettransposedfiles") {
    //                 // RAW
    //                 const uint8Array_raw = new Uint8Array(
    //                     binaryContent_raw_transpose.length
    //                 );
    //                 for (
    //                     let i = 0;
    //                     i < binaryContent_raw_transpose.length;
    //                     i++
    //                 ) {
    //                     uint8Array_raw[i] =
    //                         binaryContent_raw_transpose.charCodeAt(i);
    //                     combined_raw.push(uint8Array_raw[i]);
    //                 }
    //                 // REFINED
    //                 const uint8Array_refined = new Uint8Array(
    //                     binaryContent_refined_transpose.length
    //                 );
    //                 for (
    //                     let i = 0;
    //                     i < binaryContent_refined_transpose.length;
    //                     i++
    //                 ) {
    //                     uint8Array_refined[i] =
    //                         binaryContent_refined_transpose.charCodeAt(i);
    //                     combined_refined.push(uint8Array_refined[i]);
    //                 }
    //             } else {
    //                 // Create a Uint8Array from the binary content
    //                 const uint8Array = new Uint8Array(binaryContent.length);
    //                 for (let i = 0; i < binaryContent.length; i++) {
    //                     uint8Array[i] = binaryContent.charCodeAt(i);
    //                     combined.push(uint8Array[i]);
    //                 }
    //             }
    //             // zip.file(`${file.filename}.csv`, uint8Array);
    //         });
    //     }

    //     if (fetchDataURL === "gettransposedfiles") {
    //         zip.file(`combined_raw.csv`, combined_raw);
    //         zip.file(`combined_refined.csv`, combined_refined);
    //     } else if (fetchDataURL !== "gettransposedfiles") {
    //         zip.file(`combined.csv`, combined);
    //     }

    //     // Generate the zip file

    //     const zipContent = await zip.generateAsync({ type: "blob" });

    //     // Create a download link and trigger the download
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = URL.createObjectURL(zipContent);
    //     downloadLink.download = `${getProcessName(
    //         fetchDataURL
    //     )}_${sessionStorage.getItem("projectName")}.zip`;
    //     downloadLink.click();
    // }, [data, rowSelection, fetchDataURL]);

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>File Name</span>,
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border disabled:text-gray-200 text-green-800"
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-4" />
                        </button>
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => handleCsvDownload(row.original)}
                        >
                            <ArrowDownTrayIcon className="h-4 text-blue-600" />
                        </button>
                    </div>
                ),
            },
        ],
        [openReadModal, handleCsvDownload]
    );

    useEffect(() => {
        setLoader(true);
        if (fetchDataURL === "gettransposedfiles") {
            setTransposeViewState(true);
        } else {
            setTransposeViewState(false);
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/Transpose/${fetchDataURL}/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        fetchData();
    }, [fetchDataURL, openPopup]);

    const ReloadData = useCallback(() => {
        setLoader(true);
        const reloadTranscribedData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/Transpose/${fetchDataURL}/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        reloadTranscribedData();
    }, [fetchDataURL, openPopup]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    const tableContainerRef = React.useRef(null);

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();

        return (
            <input
                type="text"
                value={columnFilterValue || ""}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex justify-between m-3 gap-2">
                <DebouncedInput
                    value={globalFilter ?? ""}
                    onChange={(value) => setGlobalFilter(String(value))}
                    placeholder="Search..."
                />
                <button
                    className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                    onClick={ReloadData}
                >
                    <ArrowPathIcon className="h-5 text-green-700" />
                </button>
            </div>
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div>
                        {transposeViewState && (
                            <IndexwiseJsonTable data={jsonValue} />
                        )}{" "}
                        {!transposeViewState && (
                            <TitlewiseJsonTable data={jsonValue} />
                        )}
                    </div>
                }
            />
            {singleDownloadLoader && <SingleDownloadLoader />}
            {responseLoader && <ResponseLoader />}
            {Loader && <FetchingDataLoader />}
            {/* {!Loader && data.length === 0 && <NoDataFound />} */}
            {/* {!Loader && rows.length === 0 && <NoDataFound />} */}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="overflow-auto resp-tfmtable-h border-y"
                    >
                        <table className={`flex-inline w-full border-collapse`}>
                            <thead className="bg-gray-200">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort()
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rows.length === 0 ? (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            colSpan={
                                                table.getHeaderGroups()[0]
                                                    .headers.length
                                            }
                                            style={{ textAlign: "center" }}
                                        >
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {paddingTop > 0 && (
                                            <tr>
                                                <td
                                                    className="border p-2"
                                                    style={{
                                                        height: `${paddingTop}px`,
                                                    }}
                                                />
                                            </tr>
                                        )}
                                        {virtualRows.map((virtualRow) => {
                                            const row = rows[virtualRow.index];
                                            return (
                                                <tr
                                                    key={row.id}
                                                    className="bg-white transition duration-300 ease-in-out hover:bg-gray-100"
                                                >
                                                    {row
                                                        .getVisibleCells()
                                                        .map((cell) => {
                                                            return (
                                                                <td
                                                                    className="border p-2 whitespace-nowrap"
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                >
                                                                    {cell.column
                                                                        .id ===
                                                                        "index" &&
                                                                        renderTableCellIndex(
                                                                            row
                                                                        )}
                                                                    {cell.column
                                                                        .id !==
                                                                        "index" &&
                                                                        flexRender(
                                                                            cell
                                                                                .column
                                                                                .columnDef
                                                                                .cell,
                                                                            cell.getContext()
                                                                        )}
                                                                </td>
                                                            );
                                                        })}
                                                </tr>
                                            );
                                        })}
                                        {paddingBottom > 0 && (
                                            <tr>
                                                <td
                                                    className="border p-2"
                                                    style={{
                                                        height: `${paddingBottom}px`,
                                                    }}
                                                />
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!singleDownloadLoader &&
                        !responseLoader &&
                        data.length > 0 &&
                        rows.length > 0 && (
                            <div className="flex gap-2 justify-start m-3">
                                <button
                                    type="button"
                                    onClick={()=>handleDownloadCSV('csv')}
                                    disabled={downloadLoader}
                                    className={`inline-flex justify-center rounded-lg border border-transparent bg-blue-100 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer ${
                                        downloadLoader ? "px-2" : "px-3"
                                    }`}
                                >
                                    {!downloadLoader && (
                                        <>
                                            <FolderArrowDownIcon
                                                className="h-4"
                                                title="Download zip file"
                                            />{" "}
                                            &nbsp;Download CSV
                                        </>
                                    )}
                                    {downloadLoader && <DownloadingLoader />}
                                </button>
                                <button
                                    type="button"
                                    onClick={()=>handleDownloadCSV('parquet')}
                                    disabled={downloadpqLoader}
                                    className={`inline-flex justify-center rounded-lg border border-transparent bg-blue-100 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer ${
                                        downloadLoader ? "px-2" : "px-3"
                                    }`}
                                >
                                    {!downloadpqLoader && (
                                        <>
                                            <FolderArrowDownIcon
                                                className="h-4"
                                                title="Download zip file"
                                            />{" "}
                                            &nbsp;Download Parquet
                                        </>
                                    )}
                                    {downloadpqLoader && <DownloadingLoader />}
                                </button>
                            </div>
                        )}
                </>
            )}
        </div>
    );
}
