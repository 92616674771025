import React, { useState } from 'react';
import NoMatchFound from './NoMatchFound';

const IndexwiseJsonTable = ({ data }) => {
  let [expandedColumns, setExpandedColumns] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  if (!data) {
    return <div>No data to display.</div>;
  }

  const columns = Object.keys(data);
  const rows = Object.keys(data[columns[0]]);

  const renderTableCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'boolean') {
      return value ? '1' : '';
    }
    return value;
  };

  const toggleColumn = (column) => {
    if (expandedColumns.includes(column)) {
      // If column is already selected, remove it
      setExpandedColumns(expandedColumns.filter((col) => col !== column));
    } else {
      // If column is not selected, add it
      setExpandedColumns([...expandedColumns, column]);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredRows = rows.filter((row) => columns.some((column) => data[column][row].toLowerCase().includes(searchInput.toLowerCase())));


  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchInputChange}
          className="block resp-search-input-w rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
       />
      </div>
      {filteredRows.length===0 && <NoMatchFound/>}
      {filteredRows.length>0 && <div className='overflow-auto resp-featurestmptables border'>
      <table className="table-auto border-collapse text-sm">
        {/* <thead>
          <tr className='bg-gray-200'>
            <th className="border px-4 py-2 whitespace-nowrap"></th>
            {rows.map((row, index) => (
              <th key={index} className="border px-4 py-2 whitespace-nowrap" onClick={()=>toggleColumn(row)}>
                {index}
              </th>
            ))}
          </tr>
        </thead> */}
        <tbody>
          {columns.map((column, colIndex) => (
            <tr key={colIndex} className='hover:bg-gray-100'>
              <td className={`border px-4 py-2 whitespace-nowrap bg-gray-100 font-bold`} title='Click on the title to expand the rows'  onClick={()=>toggleColumn(column)}>
                {column}
              </td>
              {filteredRows.map((row, rowIndex) => (
                <td key={rowIndex} className={`border px-4 py-2 ${row==='filename'?'hidden':null}`}>
                  {
                    <p className={`${expandedColumns.includes(column) ? 'max-w-full w-72' : 'max-w-xs truncate'}`} title={data[column][row]}>
                      {renderTableCell(data[column][row])}
                    </p>
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>}
    </div>
  );
};

export default IndexwiseJsonTable;