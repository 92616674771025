import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  EyeIcon,
  FunnelIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import Popup from "../../Common/Popup";
import axios from "axios";
import { API_URL } from "../../../imports";
import {
  DebouncedInput,
  IndeterminateCheckbox,
  StatusFilter,
} from "../../Common/Filters";
import ReadModal from "../../Common/ReadModal";
import LogTable from "../../Common/LogTable";
import { handleApiError } from "../../Common/APIUtils";
import FetchingDataLoader from "../../Common/FetchingDataLoader";
import { formattedDate, generateProcessCodeFileNameAlreadyDT } from "../../Common/CommonUtils";
import { Menu, Transition } from "@headlessui/react";
import DropdownModal from "../../Common/DropdownModal";
import JSZip from "jszip";
import IndexBasedRangeSelection from "../../Common/IndexBasedRangeSelection";

export default function GetIdeasFilewiseStatusLogs({startIndex,endIndex,setStartIndex,setEndIndex,rowSelection,setRowSelection}) {
  let [isOpenRead, setIsOpenRead] = useState(false);
  let [rowData, setRowData] = useState([]);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [modalTitle, setModalTitle] = useState(false);
  let [detailsState, setDetailsState] = useState(false);
  let [fileInfoData, setFileInfoData] = useState([]);
  const [Loader, setLoader] = useState(true);
  const [jsonValue, setJsonValue] = useState(null);

  function closePopup() {
    setIsOpenPopup(false);
  }

  const openPopup = useCallback(() => {
    setIsOpenPopup(true);
  }, [setIsOpenPopup]);

  function closeReadModal() {
    setIsOpenRead(false);
  }

  const FileDetailsData = useCallback(
    async (filename) => {
      try {
        const response = await axios.post(
          `${API_URL}/GetIdeas/fileDetailsLog/${sessionStorage.getItem(
            "projectCode"
          )}`,
          { filename: filename },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )}`,
            },
          }
        );
        const keyOrder = [
          "Status",
          "Status Code",
          "Status Message",
          "Params",
          "Processed By",
          "Start Date",
          "Execution Date",
          "Response",
          "id",
        ];

        // Map over the entries and create objects with the desired key order
        const formattedData = response.data.data.map((entry) => {
          const formattedEntry = {};
          keyOrder.forEach((key) => {
            formattedEntry[key] = entry[key];
          });
          return formattedEntry;
        });
        setFileInfoData(formattedData);
      } catch (error) {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      }
    },
    [setFileInfoData, setMsg, setIsSuccess, openPopup]
  );

  const getProcessResponse = useCallback(
    async (id, process_code) => {
      axios
        .post(
          `${API_URL}/entries/getresponse`,
          {
            id: id,
            process_code: process_code,
            proj_code: sessionStorage.getItem("projectCode"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )}`,
            },
          }
        )
        .then((response) => {
          // Decode the base64 string
          const decodedJsonString = atob(response.data.data);
          setJsonValue(JSON.parse(decodedJsonString));
          // setJsonValue(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        });
    },
    [openPopup]
  );

  const openReadModal = useCallback(
    (e, options) => {
      setRowData(e);
      if (options === "response") {
        setModalTitle(`Response of ${e.filename}`);
        setDetailsState(false);
        getProcessResponse(e.id, "GETIDEAS");
      } else {
        setModalTitle(`${e.filename}`);
        setDetailsState(true);
        FileDetailsData(e.filename);
      }
      setIsOpenRead(true);
    },
    [
      setRowData,
      setModalTitle,
      setDetailsState,
      FileDetailsData,
      setIsOpenRead,
      getProcessResponse,
    ]
  );

  const ReloadData = async () => {
    setStartIndex(undefined);
    setEndIndex(undefined);
    setRowSelection({});
    setLoader(true);
    axios
      .get(
        `${API_URL}/GetIdeas/filewiseLog/${sessionStorage.getItem(
          "projectCode"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 100);
      });
  };

  const DownloadCSVAPI = async (id_list) => {
    setRowSelection({});
    axios
      .post(
        `${API_URL}/GetIdeas/downloadcsv/${sessionStorage.getItem(
          "projectCode"
        )}`,
        { filesid_list: id_list },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`,
          },
        }
      )
      .then((response) => {
        // Decode the base64-encoded content to binary
        const binaryContent = atob(response.data.download_content);

        // Create a Uint8Array from the binary content
        const uint8Array = new Uint8Array(binaryContent.length);
        for (let i = 0; i < binaryContent.length; i++) {
          uint8Array[i] = binaryContent.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

        // Create a URL object from the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = `GetIdeas_${sessionStorage.getItem(
          "projectName"
        )}.csv`;

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      });
  };

  const handleDownloadCSV = () => {
    // Filter the Files based on the rowSelection
    const selectedFiles = Object.keys(rowSelection)
      .filter((index) => rowSelection[index])
      .map((index) => data[index]);
    if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
      const fileIds = selectedFiles.map((file) => file.id);
      DownloadCSVAPI(fileIds);
    } else if (selectedFiles.length === data.length) {
      DownloadCSVAPI("All");
    } else {
      DownloadCSVAPI("All");
    }
  };

  const DownloadLogsAPI = async (files_list, latest) => {
    setRowSelection({});
    axios.post(`${API_URL}/GetIdeas/downloadlogs/${sessionStorage.getItem("projectCode")}`,
        { files_list: files_list, latest: latest },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`,
          },
        }
      )
      .then((response) => {
        // Decode the base64-encoded content to binary
        const binaryContent = atob(response.data.download_content);

        // Create a Uint8Array from the binary content
        const uint8Array = new Uint8Array(binaryContent.length);
        for (let i = 0; i < binaryContent.length; i++) {
          uint8Array[i] = binaryContent.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

        // Create a URL object from the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        if(latest===true){link.download = `GetIdeas_${sessionStorage.getItem("projectName")}_latest_logs.csv`;}
        else{link.download = `GetIdeas_${sessionStorage.getItem("projectName")}_logs.csv`;}

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      });
  };
  
  const handleDownloadLogs = (latest) => {
    // Filter the Files based on the rowSelection
    const selectedFiles = Object.keys(rowSelection)
      .filter((index) => rowSelection[index])
      .map((index) => data[index]);
      if(latest===true){
        if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
            const fileIds = selectedFiles.map((file) => file.id);
            DownloadLogsAPI(fileIds,true);
        } else if (selectedFiles.length === data.length) {
            DownloadLogsAPI("All",true);
        } else {
            DownloadLogsAPI("All",true);
        }
    }
    else{
        if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
            const filenames = selectedFiles.map((file) => file.filename);
            DownloadLogsAPI(filenames,false);
        } else if (selectedFiles.length === data.length) {
            DownloadLogsAPI("All",false);
        } else {
            DownloadLogsAPI("All",false);
        }
    }
  };

  const JsonDownloadButton = ({ id, JSONfileName, disabledState }) => {
    const downloadJson = () => {
      axios
        .post(
          `${API_URL}/entries/getresponse`,
          {
            id: id,
            process_code: "GETIDEAS",
            proj_code: sessionStorage.getItem("projectCode"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )}`,
            },
          }
        )
        .then((response) => {
          // Decode the base64 string
          const decodedJsonString = atob(response.data.data);
          const blob = new Blob(
            [JSON.stringify(JSON.parse(decodedJsonString))],
            {
              type: "application/json",
            }
          );
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = JSONfileName + ".json";
          link.click();
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        });
    };

    return (
      <button
        className="p-1 rounded-md border disabled:text-gray-300"
        onClick={downloadJson}
        disabled={disabledState}
      >
        <p className="flex gap-1" title="json file download">
          <ArrowDownCircleIcon className="h-5" />
        </p>
      </button>
    );
  };

  const cols = [{ id: "param_code", name: "Param" }, { id: "status", name: "Status" }, { id: "status_code", name: "Status Code" }, { id: "status_message", name: "Status Message" }, { id: "start_date_time", name: "Start Date" }, { id: "creation_date", name: "Execution Date" }, { id: "creation_by", name: "Processed By" }, { id: "id", name: "Actions" }];

  const columnNames = cols.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});

  const [sorting, setSorting] = React.useState([]);

  // Update rowSelection based on start and end index inputs
  const handleSetSelection = () => {
    if (startIndex !== null && endIndex !== null && startIndex <= endIndex) {
        const newSelection = {};
        for (let i = startIndex-1; i <= endIndex-1; i++) {
        newSelection[i] = true;  // Mark these indexes as selected
        }
        setRowSelection(newSelection);
    }
  };

  const initialcolumnVisibility = { "status_code": false, "creation_by": false }

  const columns = React.useMemo(
    () => [
      {
        id: "select",
        size: 10,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <center className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </center>
        ),
      },
      {
        accessorKey: "index",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: "#",
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: "filename",
        enableColumnFilter: false,
        size: 650,
        header: () => <span>File Name</span>,
        cell: ({ row }) => (
          <div
            title="Click to get File Details"
            onClick={() =>
              openReadModal(row.original, "filedetails")
            }
            disabled={row.original.response ? false : true}
            className="text-blue-600 cursor-default lg:cursor-pointer disabled:text-black"
          >
            {row.original.filename}
          </div>
        ),
      },
      {
        accessorKey: "param_code",
        enableColumnFilter: false,
        size: 100,
        header: () => <span>Param</span>,
      },
      {
        accessorKey: "status",
        enableColumnFilter: true,
        filterFn: "equals",
        size: 50,
        header: () => <span>Status</span>,
        cell: ({ row }) => (
          <div className="flex justify-center">
            <div
              className={`${row.original.status === "success" &&
                "bg-green-500"
                } ${row.original.status === "error" && "bg-red-500"
                } ${row.original.status === "Unprocessed" &&
                "bg-yellow-500"
                }  ${row.original.status !== "success" &&
                row.original.status !== "error" &&
                row.original.status !== "Unprocessed" &&
                "bg-yellow-500"
                } 
                            text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}
            >
              {row.original.status && row.original.status}
              {!row.original.status && "Unprocessed"}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "status_code",
        enableColumnFilter: false,
        size: 50,
        header: () => <span>Status Code</span>,
        cell: ({ row }) => <center>{row.original.status_code}</center>,
      },
      {
        accessorKey: "status_message",
        enableColumnFilter: false,
        size: 100,
        header: () => <span>Status Message</span>,
        cell: ({ row }) => (
          <span title={row.original.status_message}>
            {row.original.status_message !== null
              ? row.original.status_message.slice(0, 20) +
              (row.original.status_message.length > 20
                ? "..."
                : "")
              : null}
          </span>
        ),
      },
      {
        accessorKey: "start_date_time",
        enableColumnFilter: false,
        size: 100,
        header: "Start Date",
        cell: ({ row }) => <div>{formattedDate(row.original.start_date_time)}</div>,
      },
      {
        accessorKey: "creation_date",
        enableColumnFilter: false,
        size: 100,
        header: "Execution Date",
        cell: ({ row }) => <div>{formattedDate(row.original.creation_date)}</div>,
      },
      {
        accessorKey: "creation_by",
        enableColumnFilter: false,
        size: 50,
        header: "Processed By",
        cell: ({ row }) => (
          <span
            className="capitalize"
            title={row.original.creation_by}
          >
            {row.original.creation_by !== null
              ? row.original.creation_by.slice(0, 12) +
              (row.original.creation_by.length > 12
                ? "..."
                : "")
              : null}
          </span>
        ),
      },
      {
        accessorKey: "id",
        header: "Actions",
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className="flex gap-2 items-center justify-center">
            <button
              className="p-1 rounded-md border disabled:text-gray-300 text-green-800"
              disabled={row.original.status_code ? false : true}
              onClick={() =>
                openReadModal(row.original, "response")
              }
            >
              <EyeIcon className="h-4" />
            </button>
            <JsonDownloadButton
              id={row.original.id}
              JSONfileName={
                row.original.status_code === "0" &&
                row.original.creation_date &&
                generateProcessCodeFileNameAlreadyDT(
                  row.original.filename,
                  row.original.creation_date
                )
              }
              disabledState={
                row.original.status_code === "0" ? false : true
              }
            />
          </div>
        ),
      },
    ],
    [openReadModal]
  );

  const getResults = async () => {
    axios.get(
      `${API_URL}/GetIdeas/brandresult/${sessionStorage.getItem("projectCode")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "access_token"
          )}`,
        },
      }
    )
      .then(async (response) => {
        const zip = new JSZip();
        // Decode the base64 string
        const decodedJsonString = atob(response.data.data);
        const content = JSON.stringify(JSON.parse(decodedJsonString), null, 4);
        zip.file(`${sessionStorage.getItem("projectName")} brand results.json`, content);
        const decodedKeyString = atob(response.data.keys_download_content);
        zip.file(`${sessionStorage.getItem("projectName")} brand results keys.txt`, decodedKeyString);
        // Generate the zip file
        const zipContent = await zip.generateAsync({ type: "blob" });
        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(zipContent);
        downloadLink.download = `${sessionStorage.getItem("projectName")} brand results.zip`;
        downloadLink.click();
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      });
  };

  const getAliases = () => {
    axios.get(`${API_URL}/GetIdeas/brandaliases/${sessionStorage.getItem("projectCode")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "access_token"
          )}`,
        },
      }
    )
      .then(async (response) => {
        const zip = new JSZip();
        // Decode the base64 string
        const decodedJsonString = atob(response.data.data);
        const content = JSON.stringify(JSON.parse(decodedJsonString), null, 4);
        zip.file(`${sessionStorage.getItem("projectName")} brand aliases.json`, content);
        const decodedKeyString = atob(response.data.keys_download_content);
        zip.file(`${sessionStorage.getItem("projectName")} brand aliases keys.txt`, decodedKeyString);
        // Generate the zip file
        const zipContent = await zip.generateAsync({ type: "blob" });
        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(zipContent);
        downloadLink.download = `${sessionStorage.getItem("projectName")} brand aliases.zip`;
        downloadLink.click();
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
      });
  };

  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnVisibility: initialcolumnVisibility,
    },
    state: {
      rowSelection,
      sorting,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const renderTableCellIndex = (row) => {
    return (
      table.getFilteredRowModel().rows.indexOf(row) + 1 ||
      table.getSortedRowModel().rows.indexOf(row) + 1 ||
      row.index + 1
    );
  };

  const tableContainerRef = React.useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: data.length,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(
          `${API_URL}/GetIdeas/filewiseLog/${sessionStorage.getItem(
            "projectCode"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "access_token"
              )}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    fetchInitialData();
  }, [openPopup, setData]);

  return (
    <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <ReadModal
        isOpen={isOpenRead}
        closeModal={closeReadModal}
        title={modalTitle}
        modalInputs={
          <>
            {detailsState && (
              <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                <LogTable
                  data={fileInfoData}
                  filename={rowData.filename}
                  process_code="GETIDEAS"
                />
              </div>
            )}
            {!detailsState && (
              <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                {jsonValue && (
                  <pre className="pretxtwrap text-sm">
                    {JSON.stringify(jsonValue, null, 2)}
                  </pre>
                )}
                {!jsonValue && "No Response Found."}
              </div>
            )}
          </>
        }
      />
      <div className="flex justify-between m-3 gap-2">
        <div className="flex flex-wrap md:gap-2">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Search..."
          />
        </div>
        <div className="flex flex-wrap gap-2">
          <IndexBasedRangeSelection startIndex={startIndex} setStartIndex={setStartIndex} endIndex={endIndex} setEndIndex={setEndIndex} handleSetSelection={handleSetSelection} max={data.length}/>
          <button
            className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
            onClick={ReloadData}
          >
            <ArrowPathIcon className="h-5 text-green-700" />
          </button>
          <DropdownModal
            icon={<ViewColumnsIcon className="h-5 w-auto" />}
            body={table.getAllLeafColumns().filter((column) => cols.map((col) => col.id).includes(column.id)).map((column) => {
              return (
                <div key={column.id} className="p-0.5 px-1">
                  <label>
                    <input
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      {...{
                        type: "checkbox",
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />
                    &nbsp;&nbsp;
                    {columnNames[column.id]}
                  </label>
                </div>
              );
            })}
          />
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                <span className="absolute -inset-1.5" />
                <FunnelIcon className="h-5 w-auto" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  {data.length > 0 &&
                    table.getHeaderGroups().map((headerGroup) => (
                      <div key={headerGroup.id} className="space-y-1">
                        {headerGroup.headers.map((header) => (
                          <div key={header.id}>
                            {header.column.getCanFilter() &&
                              header.column.id === "status" ? (
                              <div>
                                <label
                                  htmlFor="push-everything"
                                  className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                >
                                  Status
                                </label>
                                <StatusFilter
                                  column={header.column}
                                  table={table}
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {Loader && <FetchingDataLoader />}
      {/* {!Loader && data.length === 0 && <NoDataFound />} */}
      {/* {!Loader && rows.length === 0 && <NoDataFound />} */}
      {!Loader && (
        <>
          <div
            ref={tableContainerRef}
            className="resp-processtable-h overflow-auto border-y"
          >
            <table className="flex-inline w-full border-collapse">
              <thead className="bg-gray-200">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className="border p-2 whitespace-nowrap"
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width: header.getSize(),
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort() &&
                                  header.column.id !== "status"
                                  ? "cursor-pointer select-none justify-start"
                                  : "justify-center"
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: " 🔼",
                                  desc: " 🔽",
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ?
                    (
                      <tr>
                        <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                          No Data Found
                        </td>
                      </tr>
                    )
                    :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td
                              className="border p-2"
                              style={{
                                height: `${paddingTop}px`,
                              }}
                            />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr
                              key={row.id}
                              className="bg-white transition duration-300 ease-in-out hover:bg-gray-100"
                            >
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td
                                    className="border p-2 whitespace-nowrap"
                                    key={cell.id}
                                  >
                                    {cell.column.id === "index" && <center>{renderTableCellIndex(row)}</center>}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td
                              className="border p-2"
                              style={{
                                height: `${paddingBottom}px`,
                              }}
                            />
                          </tr>
                        )}
                      </>
                    )
                }
              </tbody>
            </table>
          </div>
          {
            rows.length > 0 && (
              <div className="flex justify-end lg:justify-start gap-2 m-3">
                <button
                  type="button"
                  className="inline-flex justify-center whitespace-nowrap rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={getResults}
                >
                  Get Brand Results
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center whitespace-nowrap rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={getAliases}
                >
                  Get Brand Aliases
                </button>
                {Object.keys(rowSelection).length > 0 && (
                  <div className="flex flex-wrap justify-end lg:justify-between gap-2 w-full">
                    <div className="flex flex-wrap gap-2">
                    <button
                      type="button"
                      className="inline-flex justify-center whitespace-nowrap rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                      onClick={handleDownloadCSV}
                    >
                      Download Latest Responses
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center whitespace-nowrap rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={()=>handleDownloadLogs(true)}
                    >
                        Download Latest Logs
                    </button>
                    </div>
                    <button
                        type="button"
                        className="inline-flex justify-center whitespace-nowrap rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={()=>handleDownloadLogs(false)}
                    >
                        Download All Logs
                    </button>
                  </div>
                )}
              </div>
            )
          }
        </>
      )}
    </div>
  );
}
