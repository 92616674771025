import React, { useEffect, useState, useCallback } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { DebouncedInput, IndeterminateCheckbox } from "../../Common/Filters";
import JSZip from "jszip";
import {
    ArrowDownTrayIcon,
    ArrowDownCircleIcon,
    EyeIcon,
    FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import ReadModal from "../../Common/ReadModal";
import {
    generateFileNameDated,
    generateProcessCodeFileName,
} from "../../Common/CommonUtils";

export default function TranscriptionProcess({
    startProcess,
    selectedFilesToProcess,
}) {
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [modalTitle, setModalTitle] = useState(false);

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(selectedFilesToProcess);
    }, [selectedFilesToProcess]);

    function closeReadModal() {
        setIsOpenRead(false);
    }

    function openReadModal(e) {
        setRowData(e);
        setModalTitle(`Response of ${e.name}`);
        setIsOpenRead(true);
    }
    const formattedDate = useCallback((dateStr) => {
        const date = new Date(dateStr);
        const formattedDateString = date
            .toLocaleString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            })
            .replace(/\//g, "/")
            .replace(",", " ");
        const [month, day, yearAndTime] = formattedDateString.split("/");
        return `${day}-${month}-${yearAndTime}`;
    }, []);

    const [sorting, setSorting] = React.useState([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rowSelection, setRowSelection] = React.useState({});

    const downloadFiles = useCallback(async () => {
        const zip = new JSZip();

        // Add each JSON file to the zip
        const selectedProcessedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedProcessedFiles.length > 0) {
            selectedProcessedFiles.forEach((file) => {
                if (file.response && file.response.transcribed_text) {
                    const content = JSON.stringify(file.response);
                    zip.file(
                        `${generateFileNameDated(
                            file.name,
                            file.last_modified
                        )}.json`,
                        content
                    );
                }
            });
        } else {
            selectedFilesToProcess.forEach((file) => {
                if (file.response && file.response.transcribed_text) {
                    const content = JSON.stringify(file.response);
                    zip.file(
                        `${generateFileNameDated(
                            file.name,
                            file.last_modified
                        )}.json`,
                        content
                    );
                }
            });
        }

        // Generate the zip file
        const zipContent = await zip.generateAsync({ type: "blob" });

        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(zipContent);
        downloadLink.download = `${sessionStorage.getItem(
            "processName"
        )}_${sessionStorage.getItem("projectName")}.zip`;
        downloadLink.click();
    }, [selectedFilesToProcess, data, rowSelection]);

    const TextDownloadButton = ({ disabled, content, fileName }) => {
        const downloadTxt = () => {
            const blob = new Blob([JSON.stringify(content)], {
                type: "text/plain",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".txt";
            link.click();
        };

        return (
            <button
                className="p-1 rounded-md border disabled:text-gray-300"
                disabled={disabled}
                onClick={downloadTxt}
            >
                <p className="flex gap-1" title="txt file download">
                    <ArrowDownTrayIcon className="h-5" />
                </p>
            </button>
        );
    };

    const JsonDownloadButton = ({ disabled, content, fileName }) => {
        const downloadJson = () => {
            const blob = new Blob([JSON.stringify(content)], {
                type: "application/json",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".json";
            link.click();
        };

        return (
            <button
                className="p-1 rounded-md border disabled:text-gray-300"
                disabled={disabled}
                onClick={downloadJson}
            >
                <p className="flex gap-1" title="json file download">
                    <ArrowDownCircleIcon className="h-5" />
                </p>
            </button>
        );
    };

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "name",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>File Name</span>,
            },
            {
                accessorKey: "start_date_time",
                enableColumnFilter: false,
                size: 100,
                header: "Start Date",
                cell: ({ row }) => <div>{row.original.start_date_time}</div>,
            },
            {
                accessorKey: "last_modified",
                enableColumnFilter: false,
                size: 100,
                header: "End Date",
                cell: ({ row }) => (
                    <div>{row.original.last_modified !== "" && formattedDate(row.original.last_modified)}</div>
                ),
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            className={`${row.original.status === "success" &&
                                "bg-green-500"
                                } ${row.original.status === "error" && "bg-red-500"
                                } ${row.original.status === "Inprogress" &&
                                "bg-blue-500"
                                }  ${row.original.status !== "success" &&
                                row.original.status !== "error" &&
                                row.original.status !== "Inprogress" &&
                                "bg-yellow-500"
                                } 
                            text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}
                        >
                            {row.original.status === "Inprogress" && (
                                <div className="h-3 w-3 border-t-transparent border-solid animate-spin rounded-full border-white border-2 me-1 mt-0.5"></div>
                            )}
                            {row.original.status && row.original.status}
                            {!row.original.status && "Waiting"}
                            {row.original.status === "Inprogress" && "..."}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "transcription_model",
                enableColumnFilter: false,
                size: 100,
                header: "Model",
                cell: ({ row }) => (
                    <div>{row.original.transcription_model}</div>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <TextDownloadButton
                            disabled={row.original.response ? false : true}
                            content={row.original.response}
                            fileName={generateProcessCodeFileName(
                                row.original.name,
                                row.original.last_modified
                            )}
                        />
                        <JsonDownloadButton
                            disabled={row.original.response ? false : true}
                            content={row.original.response}
                            fileName={generateProcessCodeFileName(
                                row.original.name,
                                row.original.last_modified
                            )}
                        />
                        <button
                            className="p-1 rounded-md border text-green-600 disabled:text-gray-300"
                            disabled={row.original.response ? false : true}
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-5" title="View Response" />
                        </button>
                    </div>
                ),
            },
        ],
        [formattedDate]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const tableContainerRef = React.useRef(null);
    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();

        return (
            <input
                type="text"
                value={columnFilterValue || ""}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                        <pre className="pretxtwrap text-sm">
                            {JSON.stringify(rowData.response, null, 2)}
                        </pre>
                    </div>
                }
            />
            {selectedFilesToProcess.length === 0 && (
                <p className="px-3 py-4">
                    Please select the files for the transcription process.
                </p>
            )}
            {selectedFilesToProcess.length > 0 && (
                <>
                    <div className="flex justify-between m-3 gap-2">
                        <DebouncedInput
                            value={globalFilter ?? ""}
                            onChange={(value) => setGlobalFilter(String(value))}
                            placeholder="Search..."
                        />
                    </div>
                    <div
                        ref={tableContainerRef}
                        className="overflow-auto resp-processtable-h"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="bg-gray-200 border-b">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-3 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-3"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr
                                                            key={row.id}
                                                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                        >
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {flexRender(
                                                                                cell.column
                                                                                    .columnDef
                                                                                    .cell,
                                                                                cell.getContext()
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-3"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {data.length > 0 && !startProcess && (
                <div className="flex gap-2 justify-start m-3">
                    <button
                        type="button"
                        onClick={downloadFiles}
                        className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                    >
                        <FolderArrowDownIcon
                            className="h-4"
                            title="Download zip file"
                        />{" "}
                        &nbsp;Download
                    </button>
                </div>
            )}
        </div>
    );
}
