import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import Footer from './Footer';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Layout({title, component, tabs, resetLoading, subTitleChange, mainTitleChange, mainTitle, additionalMdButtons, additionalSmButtons, homeButton}) {
  const [isLoading, setIsLoading] = useState(true);
  const navigate=useNavigate();
  
  useEffect(() => {
    if (resetLoading) {
      setIsLoading(true); // Reset loading state when resetLoading is true
    }

    // Simulate a loading delay of 3 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [resetLoading]);

  const [subtitle, setSubTitle] = useState(null);

  useEffect(() => {
    if (subTitleChange===true) {
      setSubTitle(sessionStorage.getItem('projectName'));
    }
    else{
      setSubTitle(null);
    }
  }, [subTitleChange]);

  return (
    <div className="min-h-screen fixed w-full font-GoogleSans">
      <Header mainTitleChange={mainTitleChange} mainTitle={mainTitle} />
      {!tabs && (
        <header className="flex border-b justify-between w-full p-4 sub-header">
          <h1 className="text-lg tracking-wider">{title || subtitle}</h1>
          {title !== "Dashboard" && (
            <div className="flex gap-2">
              <div className="hidden md:flex md:gap-2">
                {homeButton && (
                  <button
                    type="button"
                    onClick={() => navigate("/projects")}
                    className="inline-flex justify-center  border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  >
                    <ChevronDoubleLeftIcon className="h-5" />
                    Home
                  </button>
                )}
                {additionalMdButtons}
                {title === "Project" && localStorage.getItem('level') === '2' && <button
                  className="inline-flex justify-center border border-blue-300 bg-blue-100 ps-2 pe-3 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  Back
                </button>}
              </div>
              <div className="flex gap-2 md:gap-0 md:hidden">
                {additionalSmButtons}
                {title === "Project" && localStorage.getItem('level') === '2' && <button
                  className="inline-flex justify-center border border-blue-300 bg-blue-100 ps-2 pe-3 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  Back
                </button>}
              </div>
              {title !== "Project" && (
                <button
                className="inline-flex justify-center border border-blue-300 bg-blue-100 ps-2 pe-3 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className="h-5 w-5" />
                Back
              </button>
              )}
            </div>
          )}
        </header>
      )}
      <main>
        {tabs && (
          <Tab.Group>
            <Tab.List className="flex justify-between w-full border-b pt-4 overflow-auto sub-header">
              <div className="flex gap-2 md:gap-3">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    className={({ selected }) =>
                      classNames(
                        "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                        "ring-white focus:outline-none focus:ring-0",
                        selected
                          ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                          : "text-black pb-3 lg:pb-3.5"
                      )
                    }
                  >
                    <h1 className="text-sm lg:text-base tracking-wider">
                      {tab.title}
                    </h1>
                  </Tab>
                ))}
              </div>
              <button
                className="inline-flex justify-center border border-blue-300 bg-blue-100 ps-2 pe-3 py-1.5 mb-4 mx-2 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className="h-5 w-5" />
                Back
              </button>
            </Tab.List>
            {isLoading ? (
              <Loader />
            ) : (
              <Tab.Panels className="overflow-auto md:bg-zinc-50 resp-h px-3 py-4">
                {tabs.map((tab, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "md:bg-zinc-50",
                      "ring-white focus:outline-none focus:ring-0"
                    )}
                  >
                    {tab.content}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            )}
          </Tab.Group>
        )}
        {!tabs && (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="overflow-auto resp-h px-3 py-4 bgclr">
                {component}
              </div>
            )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;