import React, { Fragment, useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ArrowPathIcon,
    InformationCircleIcon
} from "@heroicons/react/24/outline";
import Popup from "../Common/Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import { handleApiError } from "../Common/APIUtils";
import NoDataFound from "../Common/NoDataFound";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import { Menu, Transition } from "@headlessui/react";

function ActiveServiceUrls() {
    const [Loader, setLoader]=useState(true)
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const ReloadData = async () => {
        setLoader(true);
        axios.get(`${API_URL}/serverUrl/read_serverUrl`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
                params: {
                    state: "activeonly",
                },
            })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(()=>{
                setLoader(false);
            });
    };

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 40,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "keycode",
                enableColumnFilter: false,
                size: 800,
                header: () => <span>Service Name</span>,
                cell: ({ row }) => (
                    <div className="flex flex-wrap items-center space-x-1">
                        <div>
                            {row.original.keycode.charAt(0).toUpperCase() + row.original.keycode.slice(1).toLowerCase()}
                        </div>
                        <Menu as="div" className="relative">
                                <div>
                                    <Menu.Button className="relative border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                    <InformationCircleIcon className="h-5 w-auto" strokeWidth={1.8}/>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="fixed z-30 -mt-9 w-fit px-2 py-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div>
                                                <a href={row.original.server_url} target="_blank" without rel="noreferrer" className="underline text-sm text-blue-600">{row.original.server_url}</a>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                    </div>
                ),
            },
            {
                accessorKey: "server_url_status",
                header: "Status",
                size: 100,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            className={`${
                                row.original.server_url_status === true
                                    ? "bg-green-300  text-green-900"
                                    : "bg-red-300 text-red-900"
                            } text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
                        >
                            {row.original.server_url_status === true
                                ? "Running"
                                : "Stopped"}
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState("");

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const tableContainerRef = React.useRef(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            axios.get(`${API_URL}/serverUrl/read_serverUrl`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                    params: {
                        state: "activeonly",
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(()=>{
                    setLoader(false);
                });
        };
        fetchInitialData();
    }, []);

    return (
        <div className="border bg-white font-GoogleSans tracking-wider rounded-md overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex justify-between items-center my-2 mx-3 gap-2">
                <div>
                    {/* <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    /> */}
                    <p className="font-medium text-lg">Services</p>
                </div>
                <div className="flex gap-2">
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                </div>
            </div>
            {Loader && <FetchingDataLoader/>}
            {!Loader && data.length===0 && <NoDataFound/>}
            {!Loader && data.length>0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2.5 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort()
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td
                                            className="border p-1"
                                            style={{
                                                height: `${paddingTop}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                                {virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr key={row.id}>
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className="border p-[0.4rem] whitespace-nowrap"
                                                            key={cell.id}
                                                        >
                                                            {cell.column.id === "index" && (
                                                                <center>{renderTableCellIndex(row)}</center>
                                                            )}
                                                            {cell.column.id !== "index" &&
                                                            flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td
                                            className="border p-1"
                                            style={{
                                                height: `${paddingBottom}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default ActiveServiceUrls;
