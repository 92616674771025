import passwordCheck from "./assets/images/password-check.svg";
import ixologo from "./assets/images/ixologo.png";
import loadingbar from "./assets/images/loading-bars.svg";
import activity_log from "./assets/images/activity_log.png";

export { passwordCheck, ixologo, loadingbar, activity_log };
export { API_URL } from "./http";

// imageExports.js
const images = {};

function importAll(r) {
    r.keys().forEach((key) => {
        images[key.replace("./", "")] = r(key);
    });
}

importAll(require.context("./assets/images/", false, /\.(png|jpeg|svg)$/));

export default images;
