import React, { useEffect, useState } from 'react';
import NoMatchFound from './NoMatchFound';

const JSONLogListTable = ({ data }) => {

  let [dataItems, setDataItems] = useState([]);
  let [expandedColumns, setExpandedColumns] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(()=>{
    setDataItems(data);
  },[data])

  const toggleColumn = (column) => {
    if (expandedColumns.includes(column)) {
      // If column is already selected, remove it
      setExpandedColumns(expandedColumns.filter((col) => col !== column));
    } else {
      // If column is not selected, add it
      setExpandedColumns([...expandedColumns, column]);
    }
  };


  if (!dataItems || dataItems.length === 0) {
    return <div>No data to display.</div>;
  }

  const columns = Object.keys(dataItems[0]);

  const renderTableCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'boolean') {
      return value ? 'success' : 'error';
    }
    return value;
  };

  const renderHeaderCell = (column, index) => (
    <th key={index} className="border px-4 py-2 bg-gray-100" onClick={() => toggleColumn(column)}>
      <p className={`max-w-xs truncate ${column!=='Status' && 'text-start'} ${column==='Status' && 'text-center'}`}>{column}</p>
    </th>
  );

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = dataItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  return (
  <div>
    <div className="my-3 mx-2">
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchInputChange}
          className="block resp-search-input-w rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
        />
      </div>
      {filteredData.length===0 && <NoMatchFound/>}
      {filteredData.length>0 && <div className='overflow-auto h-[20rem] md:h-[calc(100vh-28rem)] border-t'>
    <table className="table-auto border-collapse font-GoogleSans text-sm w-full">
      <thead>
        <tr>
          <th className="border px-4 py-2 bg-gray-100">
            <p className='max-w-full whitespace-nowrap'>#</p>
          </th>
            {columns.map((column, index) => renderHeaderCell(column, index))}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index} className='hover:bg-gray-100'>
            <td className="border px-4 py-2">
              {index+1}
            </td>
            {columns.map((column, columnIndex) => (
                <td key={columnIndex} className="border px-4 py-2">
                    {column!=='Status' && <p className={`${expandedColumns.includes(column)?'w-auto truncate':'w-auto'}`} title={item[column]}>{renderTableCell(item[column])}</p>}
                    {column==='Status' && 
                    <div className='flex justify-center'>
                        <div className={`${item[column] && "bg-green-500"} ${!item[column] && "bg-red-500"} text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}>
                            {renderTableCell(item[column])}
                        </div>
                    </div>
                    }
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
    </div>}
  </div>
  );
};

export default JSONLogListTable;
