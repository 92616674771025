import React, { useState } from "react";
import { handleApiError } from "../Common/APIUtils";
import axios from "axios";
import { API_URL } from "../../imports";
import QDBPromptFileUploader from "../Common/QDBPromptFileUploader";
import Popup from "../Common/Popup";
import QDBTable from "../Common/QDBTable";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import ProcessingLoader from "../Common/ProcessingLoader";
import { getCurrentDateTimeforFilename } from "../Common/CommonUtils";

function QDBPrompts() {
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [processedfiles1, setProcessedFiles1] = useState([]);
  const [processedfiles2, setProcessedFiles2] = useState([]);
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [downloadcsvcontent, setDownloadcsvcontent] = useState('');
  let [show, setShow] = useState(true);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  const threshold = 42;

  const BreakFilenameText = (text) => {
    const words = text.split(' ');

    if (words.some(word => word.length > threshold)) {
      return <span className="text-indigo-700 break-all lg:break-normal hyphens-auto lg:hyphens-none">{text}</span>;
    } else {
      return <span className="text-indigo-700 break-words lg:break-normal">{text}</span>;
    }
  };

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const emptyFilesState = () => {
    setFiles1([]);
    setFiles2([]);
  };

  const emptyAll = () => {
    setFiles1([]);
    setFiles2([]);
    setData([]);
    setShow(true);
  };

  const responseStateHandler = (responseMessage, responseState) => {
    emptyFilesState();
    setMsg(responseMessage);
    setIsSuccess(responseState);
    openPopup();
  };

  const handleProcessState = async () => {
    setLoader(true);
    if (files1.length > 0 && files2.length > 0) {
      const formData = new FormData();
      formData.append("file1", files1[0]);
      formData.append("file2", files2[0]);
      try {
        await axios
          .post(
            `${API_URL}/qdbprompts/${sessionStorage.getItem("projectCode")}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((response) => {
            setData(response.data.data);
            setDownloadcsvcontent(response.data.csv);
            setProcessedFiles1(files1[0]);
            setProcessedFiles2(files2[0]);
            emptyFilesState();
            setLoader(false);
            setShow(false);
          });
      } catch (error) {
        const errorMessage = handleApiError(error);
        setLoader(false);
        setShow(true);
        responseStateHandler(errorMessage, false);
      }
    } else {
      setLoader(false);
      setShow(true);
      responseStateHandler("No Files are Uploaded", false);
    }
  };

  const handleCsvDownload = () => {
    // Decode the base64-encoded content to binary
    const binaryContent = atob(downloadcsvcontent);

    // Create a Uint8Array from the binary content
    const uint8Array = new Uint8Array(binaryContent.length);
    for (let i = 0; i < binaryContent.length; i++) {
        uint8Array[i] = binaryContent.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

    // Create a URL object from the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `qdb_prompts_${getCurrentDateTimeforFilename()}.csv`;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

  return (
    <div className="border bg-white rounded-md h-[calc(100vh-14rem)] overflow-auto">
      <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
      {show && 
      <div className="ease-in-out transition-transform">
      <div className="flex flex-col gap-4 md:flex-row py-2 px-4">
        <QDBPromptFileUploader qdbno="Example" files={files1} setFiles={setFiles1} />
        <QDBPromptFileUploader qdbno="Sample" files={files2} setFiles={setFiles2} />
      </div>
      <div className="flex flex-col md:flex-row gap-3 py-2 px-4">
        {!loader ? (
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={()=> handleProcessState()}
        >
          Process
        </button>
        ):(
          <ProcessingLoader/>
        )}
        {!loader && data.length===0 && (
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={() => emptyFilesState()}
        >
          Clear Files
        </button>
        )}
        {!loader && data.length>0 && (
        <>
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={() => emptyAll()}
        >
          Clear Files & QDB Prompts
        </button>
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={() => setShow(false)}
        >
          Show processed QDB prompts  <ChevronUpDownIcon className="h-5 ms-1 w-auto"/>
        </button>
        </>
        )}
      </div>
      </div>
      }
      {data && data.length>0 && !show && 
      <div className="gap-2 flex flex-col ease-in-out transition-transform">
        <div className="flex flex-col px-2 pt-3 pb-1 lg:flex-row gap-2 lg:justify-between lg:items-center ms-1">
          <p>Showing QDB Prompts for {BreakFilenameText(processedfiles1.path)} & {BreakFilenameText(processedfiles2.path)}</p>
          <div className="space-x-2">
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={()=> setShow(true)}
        >
          Show Upload Area <ChevronUpDownIcon className="h-5 ms-1 w-auto"/>
        </button>
        <button
          type="button"
          className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
          onClick={()=>handleCsvDownload()}
        >
          Download
        </button>
        </div>
        </div>
        <div className="h-[calc(100vh-21.5rem)] md:h-[calc(100vh-20rem)] lg:h-[calc(100vh-18rem)] border overflow-auto">
          <QDBTable data={data}/>
        </div>
      </div>
      }
    </div>
  );
}

export default QDBPrompts;
