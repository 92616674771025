import React, { useCallback, useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { EyeIcon, ArrowDownTrayIcon, PlayIcon, ArrowDownCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import ReadModal from '../Common/ReadModal';
import { DebouncedInput, IndeterminateCheckbox } from '../Common/Filters';
import Popup from '../Common/Popup';
import { API_URL } from "../../imports";
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';
import JSZip from "jszip";
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { formattedDate, generateFileNameDated, generateParamCodeFileName } from '../Common/CommonUtils';
import PlayerModal from '../Common/PlayerModal';
import VideoPlayer from '../Common/VideoPlayer';
import AudioPlayer from '../Common/AudioPlayer';
import NoDataFound from '../Common/NoDataFound';

function getFileTypeFromFilename(filetype) {
  // const extension = filename.split('.').pop().toLowerCase();

  // Map file extensions to MIME types
  const mimeTypes = {
    wav: 'audio/wav',
    mp4: 'video/mp4',
    m4a: 'audio/mp4',
    mp3: 'audio/mp3',
  };

  // Default to 'application/octet-stream' if the extension is not recognized
  return mimeTypes[filetype] || 'application/octet-stream';
}

function FilesMgmt() {
  const [selectedProcess, setSelectedProcess] = useState('');
  const [currentProcess, setCurrentProcess] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('All');
  let [show,setShow] = useState(false);
  const [data,setData]=useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [entryColumnState, setEntryColumnState] = useState('');
  const [jsonValue,setJsonValue]=useState(null);
  let [audiotype, setAudiotype]=useState(true);


  const handleViewFiles = async() => {
    if (selectedProcess) {
      if (selectedProcess === 'TRANSCRIPT' && selectedBatch) {
        try {
          const response = await axios.get(`${API_URL}/entries`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params: {
                project: `${sessionStorage.getItem("projectCode")}`,
                process: selectedProcess,
                batch: selectedBatch
            },
          });
          setRowSelection({});
          setEntryColumnState(selectedProcess); 
          setCurrentProcess(selectedProcess);
          setData(response.data.data);
          setShow(true);
        } catch (error) {
            setShow(false);
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        } 
      } else if (selectedProcess !== 'TRANSCRIPT') {
          try {
            const response = await axios.get(`${API_URL}/entries`, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
              params: {
                  project: `${sessionStorage.getItem("projectCode")}`,
                  process: selectedProcess,
                  batch: selectedBatch
              },
            });
            setRowSelection({});
            setEntryColumnState(selectedProcess); 
            setCurrentProcess(selectedProcess);
            setData(response.data.data);
            setShow(true);
          } catch (error) {
              setShow(false);
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
          } 
      } else {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  let [isOpen, setIsOpen] = useState(false);
  let [rowData,setRowData]=useState([]);
  let [modalTitle, setModalTitle] = useState('');

  function closeModal() {
    setIsOpen(false)
  }

  let [isOpenPlayer, setIsOpenPlayer] = useState(false);
  let [playermodalTitle, setPlayerModalTitle] = useState('');

  function closePlayerModal() {
    setIsOpenPlayer(false)
  }

  const getProcessResponse = useCallback(async(id,process_code) =>{
    axios.post(
            `${API_URL}/entries/getresponse`,
            { id: id,process_code:process_code,proj_code:sessionStorage.getItem(
                "projectCode"
            ) },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            }
        )
        .then((response) => {
            // Decode the base64 string
            const decodedJsonString = atob(response.data.data);
            setJsonValue(JSON.parse(decodedJsonString));
            // setJsonValue(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        })
},[]);

const openReadModal = useCallback((e)=> {
    setIsOpen(true);
    getProcessResponse(e.id,currentProcess)
    setModalTitle(`Showing ${e.filename}`);
},[currentProcess,getProcessResponse])

const openPlayerModal = useCallback((e)=> {
  setRowData(e);
  if(e.type==='wav' || e.type==='mp3'){
    setAudiotype(true);
  }
  else{
    setAudiotype(false);
  }
  setPlayerModalTitle(`Showing ${e.name}`);
  setIsOpenPlayer(true);
},[])


  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  let [deleteSelected, setDeleteSelected] = useState(false);
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup(e, option) {
      if(option==='single'){
        setRowData(e);
        setDeleteSelected(false);
        setConfirmationMsg("Are you sure that you want to delete this entry?");
      }
      else if(option==='multiple'){
        setDeleteSelected(true);
        setConfirmationMsg("Are you sure that you want to delete these selected entries?");
      }
      setIsOpenConfirmPopup(true);
    }

    const ReloadData = async () => {
      if (currentProcess && selectedBatch) {
        // You can perform any action here related to viewing logs for the selected project
        try {
          const response = await axios.get(`${API_URL}/entries`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params: {
                project: `${sessionStorage.getItem("projectCode")}`,
                process: currentProcess,
                batch: selectedBatch
            },
          });
          setRowSelection({});
          setEntryColumnState(currentProcess); 
          setData(response.data.data);
          setShow(true);
      } catch (error) {
          setShow(false);
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
      } 
        
      } else {
        setShow(false);
      }
  };

  const handleMultipleDeleteState = async () => {
    const selectedCloseFiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);
    const fileIds = selectedCloseFiles.map((file) => file.id);
    setIsOpenConfirmPopup(false);
    try {
        const response = await axios.delete(`${API_URL}/entries/selected`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params: {
                process: currentProcess,
                ids: fileIds.join(','),
            },
        });
        setMsg(response.data.message);
        setIsSuccess(true);
        openPopup();
        ReloadData();
    } catch (error) {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
    }
};
  
      const handleSingleDeleteState = async () => {
          setIsOpenConfirmPopup(false);
          try {
              const response = await axios.delete(`${API_URL}/entries`, {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                          "access_token"
                      )}`,
                  },
                  params: {
                      process: currentProcess,
                      id: rowData.id
                  },
              });
              setMsg(response.data.message);
              setIsSuccess(true);
              openPopup();
              ReloadData();
          } catch (error) {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
          }
      };

const handleS3FileDownload = useCallback(async (file) => {
  try {
    const response = await axios.post(
      `${API_URL}/s3files/downloadfile`,
      { project_code: sessionStorage.getItem("projectCode"), file_name: file.name },
      {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: 'json',
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      },
    );

    // // Decode the base64-encoded content to binary
    // const binaryContent = atob(response.data.download_content);

    // // Create a Uint8Array from the binary content
    // const uint8Array = new Uint8Array(binaryContent.length);
    // for (let i = 0; i < binaryContent.length; i++) {
    //   uint8Array[i] = binaryContent.charCodeAt(i);
    // }

    // // Create a Blob from the Uint8Array
    // const blob = new Blob([uint8Array], { type: getFileTypeFromFilename(file.type) }); // Adjust type based on your file type

    // // Create a URL object from the Blob
    // const url = window.URL.createObjectURL(blob);
    // const url = window.URL.createObjectURL(response.data);

    // Extract the presigned URL from the response
    const url = response.data.download_content;

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.target = '_self';
    link.rel = 'noopener noreferrer';
    // link.download = file.name;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    // window.URL.revokeObjectURL(url);
  } catch (error) {
    const errorMessage = handleApiError(error);
    setMsg(errorMessage);
    setIsSuccess(false);
    openPopup();
  }
},[]);

// const handleMultipleS3FileDownload = useCallback(async () => {
//   const selectedfiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);
//   const S3formData = new FormData();
//   // Loop through files and append them to the FormData
//   selectedfiles.forEach((file) => {
//     S3formData.append('file', file.name);
//   });

//   try {
//     const response = await axios.post(`${API_URL}/s3files/downloadmultiplefiles/${sessionStorage.getItem("projectCode")}`, S3formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         Accept: 'application/json',
//         Authorization: `Bearer ${localStorage.getItem("access_token")}`
//       },
//     });

//     for (const fileContent of response.data.download_contents) {
//       // Extract the presigned URL from the response
//       let url = fileContent.download_content;

//       // Open the URL in a new tab/window
//       window.open(url, '_blank', 'noopener,noreferrer');
//     }


//   } catch (error) {
//     const errorMessage = handleApiError(error);
//     setMsg(errorMessage);
//     setIsSuccess(false);
//     openPopup();
//   }
// },[data, rowSelection]);


  const s3Filecolumns = React.useMemo(
    () => [
      {
        id: 'select',
        size:10,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <center className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </center>
        ),
      },
      {
        accessorKey: "index",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: "#",
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'name',
        enableColumnFilter:false,
        size: 1000,
        header: 'File Name',
        cell: ({ row }) => (
          <div>
              {row.original.name.replace(".wav","").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}
          </div>
        ),
      },
      {
        accessorKey: 'type',
        enableColumnFilter:false,
        size: 50,
        header: () => <span>Type</span>
      },
      {
        accessorKey: 'last_modified',
        enableColumnFilter:false,
        size: 50,
        header:'Modified Date',
        cell: ({ row }) => (
          <div>
              {formattedDate(row.original.last_modified)}
          </div>
        ),
      },
      {
        accessorKey: 'size',
        enableColumnFilter:false,
        size: 100,
        header: () => <span>Size</span>
      },
      {
        accessorKey:'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter:false,
        enableSorting:false,
        cell:({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
          <button className="p-1 rounded-md border text-green-800" onClick={()=>openPlayerModal(row.original)}>
            <PlayIcon className='h-4'/>
          </button>
          <button className="p-1 rounded-md border" onClick={()=>handleS3FileDownload(row.original)}>
            <ArrowDownTrayIcon className='h-4 text-blue-600'/>
          </button>
          </div>
         )
        },
    ],
    [openPlayerModal, handleS3FileDownload]
  );

  const JsonDownloadButton = ({ id, processcode, JSONfileName, disabledState }) => {
    const downloadJson = () => {
      axios
      .post(
          `${API_URL}/entries/getresponse`,
          { id: id, process_code: processcode, proj_code:sessionStorage.getItem(
              "projectCode"
          ) },
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                      "access_token"
                  )}`,
              },
          }
      )
      .then((response) => {
          // Decode the base64 string
          const decodedJsonString = atob(response.data.data);
          const blob = new Blob([JSON.stringify(JSON.parse(decodedJsonString))], {
              type: "application/json",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = JSONfileName + ".json";
          link.click();
      })
      .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
      })
    };

    return (
        <button
            className="p-1 rounded-md border disabled:text-gray-300"
            onClick={downloadJson}
            disabled={disabledState}
        >
            <p className="flex gap-1" title="json file download">
                <ArrowDownCircleIcon className="h-5" />
            </p>
        </button>
    );
};


const downloadFiles = useCallback(async () => {
  const zip = new JSZip();
  const selectedProcessedFiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);

  const axiosRequests = selectedProcessedFiles.map((file) => {
    if (file.status_code === '0') {
      return axios.post(
        `${API_URL}/entries/getresponse`,
        { id: file.id, process_code: currentProcess, proj_code: sessionStorage.getItem("projectCode") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ).then((response) => {
        const decodedJsonString = atob(response.data.data);
        zip.file(`${generateFileNameDated(file.filename, file.creation_date)}.json`, JSON.stringify(JSON.parse(decodedJsonString)));
      });
    }
    return Promise.resolve(); // Resolve for non-matching conditions
  });

  // Wait for all requests to complete
  await Promise.all(axiosRequests);

  // Generate the zip file
  const zipContent = await zip.generateAsync({ type: "blob" });

  // Create a download link and trigger the download
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(zipContent);
  downloadLink.download = `${currentProcess}_${sessionStorage.getItem("projectName")}.zip`;
  downloadLink.click();
}, [data, rowSelection, currentProcess]);


const TranscriptionEntrycolumns = React.useMemo(
  () => [
    {
      id: 'select',
      size:10,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <center className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </center>
      ),
    },
    {
      accessorKey: "index",
      enableColumnFilter: false,
      enableSorting: false,
      size: 50,
      header: "#",
      cell: ({ row }) => <center>{row.index + 1}</center>,
    },
    {
      accessorKey: 'filename',
      enableColumnFilter:false,
      size: 1000,
      header: () => <span>File Name</span>
    },
    {
      accessorKey: 'status',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Status</span> 
    },
    {
      accessorKey: 'confidence_score',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Confidence Score</span>,
                cell: ({ row }) => (
                    <div>
                        {row.original.confidence_score === null
                            ? null
                            : row.original.confidence_score.toFixed(2)}
                    </div>
                ), 
    },
    {
      accessorKey: 'merge',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Merged</span>,
      cell: ({ row }) => (
          <div>{row.original.merge === '1' ? 1 : null}</div>
      ),
    },
    {
      accessorKey: 'closing',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Finalized</span>,
      cell: ({ row }) => (
          <div>{row.original.closing === true ? 1 : null}</div>
      ),
    },
    {
      accessorKey: 'creation_date',
      enableColumnFilter:false,
      size: 50,
      header:'Execution Date',
      cell: ({ row }) => (
        <div>
            {formattedDate(row.original.creation_date)}
        </div>
      ),
    },
    {
      accessorKey:'id',
      header: 'Actions',
      size: 50,
      enableColumnFilter:false,
      enableSorting:false,
      cell:({ row }) => (
        <div className='flex gap-2 items-center justify-center'>
        <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
          <EyeIcon className='h-4 text-green-800'/>
        </button>
        <JsonDownloadButton id={row.original.id} processcode='TRANSCRIPT' JSONfileName={generateParamCodeFileName(row.original.filename,'TR',row.original.creation_date)} disabledState={row.original.status_code==='0' ? false : true}/>
        <button  className="p-1 rounded-md border text-red-600 disabled:text-red-200" onClick={()=>openConfirmPopup(row.original,'single')}>
          <TrashIcon className="h-4" />
        </button>
        </div>
       )
      },
  ],
  [openReadModal]
);

const RawRefinedEntrycolumns = React.useMemo(
  () => [
    {
      id: 'select',
      size:10,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <center className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </center>
      ),
    },
    {
      accessorKey: "index",
      enableColumnFilter: false,
      enableSorting: false,
      size: 50,
      header: "#",
      cell: ({ row }) => <center>{row.index + 1}</center>,
    },
    {
      accessorKey: 'filename',
      enableColumnFilter:false,
      size: 1000,
      header: () => <span>File Name</span>
    },
    {
      accessorKey: 'status',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Status</span> 
    },
    {
      accessorKey: 'closing',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Finalized</span>,
      cell: ({ row }) => (
          <div>{row.original.closing === true ? 1 : null}</div>
      ),
    },
    {
      accessorKey: 'creation_date',
      enableColumnFilter:false,
      size: 50,
      header:'Execution Date',
      cell: ({ row }) => (
        <div>
            {formattedDate(row.original.creation_date)}
        </div>
      ),
    },
    {
      accessorKey:'id',
      header: 'Actions',
      size: 50,
      enableColumnFilter:false,
      enableSorting:false,
      cell:({ row }) => (
        <div className='flex gap-2 items-center justify-center'>
        <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
          <EyeIcon className='h-4 text-green-800'/>
        </button>
        <JsonDownloadButton id={row.original.id} processcode={currentProcess==='RAW'?'RAW':'REFINED'} JSONfileName={generateParamCodeFileName(row.original.filename,currentProcess==='RAW'?'RW':'RF',row.original.creation_date)} disabledState={row.original.status_code==='0' ? false : true}/>
        <button  className="p-1 rounded-md border text-red-600 disabled:text-red-200" onClick={()=>openConfirmPopup(row.original,'single')}>
          <TrashIcon className="h-4" />
        </button>
        </div>
       )
      },
  ],
  [currentProcess, openReadModal]
);

  const GetIdeasEntrycolumns = React.useMemo(
    () => [
      {
        id: 'select',
        size:10,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <center className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </center>
        ),
      },
      {
        accessorKey: "index",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: "#",
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'filename',
        enableColumnFilter:false,
        size: 1000,
        header: () => <span>File Name</span>
      },
      {
        accessorKey: 'status',
        enableColumnFilter:false,
        size: 50,
        header: () => <span>Status</span> 
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter:false,
        size: 50,
        header:'Execution Date',
        cell: ({ row }) => (
          <div>
              {formattedDate(row.original.creation_date)}
          </div>
        ),
      },
      {
        accessorKey:'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter:false,
        enableSorting:false,
        cell:({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
          <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
            <EyeIcon className='h-4 text-green-800'/>
          </button>
          <JsonDownloadButton id={row.original.id} processcode='GETIDEAS' JSONfileName={`${generateParamCodeFileName(row.original.filename,'ID',row.original.creation_date)}`} disabledState={row.original.status_code==='0' ? false : true}/>
          <button  className="p-1 rounded-md border text-red-600 disabled:text-red-200" onClick={()=>openConfirmPopup(row.original,'single')}>
            <TrashIcon className="h-4" />
          </button>
          </div>
         )
        },
    ],
    [openReadModal]
  );

  const [processes,setProcesses]=useState([]);
  const [batches, setBatches] = useState([]);

useEffect(() => {
  axios.get(`${API_URL}/processes`,{
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    }
  }).then(response => {
      setProcesses(response.data.data);
    })
    .catch(error => {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      openPopup();
    });
    const GetNumberOfBatches = async () => {
      axios.get(
              `${API_URL}/Batching/getNumberOfBatches/${sessionStorage.getItem(
                  "projectCode"
              )}`,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                          "access_token"
                      )}`,
                  },
              }
          )
          .then((response) => {
              setBatches(response.data.data);
          })
          .catch((error) => {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
          });
  };
  GetNumberOfBatches();
}, []);
  

  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [globalFilter, setGlobalFilter] = React.useState("");


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns: entryColumnState === 'PRETRANS'
    ? s3Filecolumns
    : entryColumnState === 'TRANSCRIPT'
    ? TranscriptionEntrycolumns
    : entryColumnState === 'GETIDEAS'
    ? GetIdeasEntrycolumns
    : RawRefinedEntrycolumns,
    state: {
      rowSelection,
      sorting,
      pagination,
      globalFilter
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const renderTableCellIndex = (row) => {
    return table.getFilteredRowModel().rows.indexOf(row)+1 || table.getSortedRowModel().rows.indexOf(row)+1 || row.index+1;
  };

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
      pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
      const newPageSize = Number(e.target.value);
      setPageSize(newPageSize);
      setPageIndex(0)
    };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div className="border border-gray-200 rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={deleteSelected?handleMultipleDeleteState:handleSingleDeleteState}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <ReadModal isOpen={isOpen} closeModal={closeModal} title={modalTitle}
        modalInputs={
          <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
          {jsonValue && (
              <pre className="pretxtwrap text-sm">
                  {JSON.stringify(
                      jsonValue,
                      null,
                      2
                  )}
              </pre>
          )}
          {!jsonValue && "No Response Found."}
          </div>
        } 
      />
      <PlayerModal isOpen={isOpenPlayer} closeModal={closePlayerModal} title={playermodalTitle}
        modalInputs={
          <div className="overflow-auto">
          {!audiotype && <VideoPlayer filename={rowData.name} filetype={getFileTypeFromFilename(rowData.type)}/>}
          {audiotype && <AudioPlayer filename={rowData.name} filetype={getFileTypeFromFilename(rowData.type)}/>}
          </div>
        } 
      />
      <div className='flex flex-col md:flex-row gap-3 p-3 border-b'>
                <select
                  id="process"
                  name="process"
                  className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={(e) => setSelectedProcess(e.target.value)}
                >
                  <option value="">Select Process</option>
                  {processes.map((process,index) => (
                    <option key={index} value={process.process_code}>
                      {process.process_name}
                    </option>
                  ))}
                </select>
                {selectedProcess==='TRANSCRIPT' && <select
        id="batch"
        name="batch"
        className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        value={selectedBatch}
        onChange={(e) => setSelectedBatch(e.target.value)}
      >
        <option value="">Select Batch</option>
        <option value="All">All Batches</option>
        {batches.map((batch, index) => (
          <option key={index} value={batch}>
              {batch}
          </option>
        ))}
      </select>}
      <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={handleViewFiles}>
        View Files
      </button>
      {show && Object.keys(rowSelection).length > 0 && currentProcess!=='PRETRANS' &&
      <>
        <button className="rounded-md md:hidden cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={downloadFiles}>
          Download
        </button>
        <button className="rounded-md md:hidden cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={()=>openConfirmPopup(null,'multiple')}>
          Delete
        </button>
      </>
      }
      </div>
      {!show && <div className='flex h-[calc(100vh-18rem)] items-center justify-center'><p className='text-sm md:text-2xl p-4 lg:p-0'>Please select the process {selectedProcess==='TRANSCRIPT' && 'and batch'} to view files</p></div>}
      {show && data.length === 0 && <NoDataFound />}
      {show && data.length > 0 && (
      <>
      <div className="flex justify-between m-3 gap-2">
        <DebouncedInput value={globalFilter ?? ""}  onChange={(value) => setGlobalFilter(String(value))}  placeholder="Search..."/>
      </div>
      <div ref={tableContainerRef} className='overflow-auto resp-view-files border-y'>
        <table className='flex-inline w-full border-collapse'>
          <thead className='bg-gray-100'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className='border p-2 whitespace-nowrap'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                        <div
                          className={`${
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none justify-start'
                              : 'justify-center'
                          } flex`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] || null}
                        </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className='border p-2 whitespace-nowrap' key={cell.id}>
                        {cell.column.id === "index" && <center>{renderTableCellIndex(row)}</center>}
                        {cell.column.id!=='index' && flexRender(cell.column.columnDef.cell,cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flex gap-1 justify-between flex-row-reverse m-3'>
        <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
            <button title='First Page' onClick={() => setPageIndex(0)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            <button title='Previous Page'
            onClick={() => setPageIndex(pageIndex-1)}
            disabled={pageIndex===0}
              aria-current="page"
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((pageNumber, index) => {
            if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
              return (
                <button
                  className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  key={index}
                  onClick={()=>setPageIndex(pageNumber)}
                >
                  {pageNumber + 1}
                </button>
              );
            } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
              return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
            }
            return null;
          })}
            <button title='Next Page' onClick={() => setPageIndex(pageIndex+1)}  disabled={pageIndex===(table.getPageCount() - 1)}
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
           </button>
            <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div>
          <div className="sm:col-span-3 flex gap-3">
              <div>
                <select
                  id="records"
                  name="records"
                  value={pageSize}
                  onChange={handlePageChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {[10,20,50,100,500,1000].map((page)=>(
                  <option className='p-1' key={page} value={page}>Show {page}</option>
                  ))}
                </select>
              </div>
              {show && Object.keys(rowSelection).length > 0 && currentProcess!=='PRETRANS' &&
                <>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={downloadFiles}>
                    Download
                  </button>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={()=>openConfirmPopup(null,'multiple')}>
                    Delete
                  </button>
                </>
              }
            </div>
          </div>
        </div>
        </>
        )}
    </div>
  );
}

function ViewFiles() {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false);
    //setRowId('');
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div>
    <button type="button" onClick={openModal} className="inline-flex justify-center border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">View Files</button>
    <ReadModal isOpen={isOpen} closeModal={closeModal} title='View Files'
        modalInputs={<FilesMgmt/>}
    />
    </div>
  )
}

export default ViewFiles