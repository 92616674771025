import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon, PencilIcon, TrashIcon, PlusCircleIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { initialpageSize } from '../Common/Contants';
import InputModal from '../Common/InputModal';
import Popup from '../Common/Popup';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import axios from 'axios';
import { API_URL } from '../../imports'
import { DebouncedInput } from '../Common/Filters';
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import PageSizeDropdown from '../Common/PageSizeDropdown';
import { formattedDate } from '../Common/CommonUtils';
import { Menu, Transition } from '@headlessui/react';
import { Portal } from "react-portal";

export default function ProjectTag() {
  let [isOpen, setIsOpen] = useState(false);
  let [rowData, setRowData] = useState([]);
  let [edit, setEdit] = useState(false);
  const [Loader, setLoader] = useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
  const [activeDropdown, setActiveDropdown] = useState(null);
  const buttonRefs = useRef({});
  const dropdownRef = useRef(null);

  const calculatePosition = useCallback((id) => {
    const buttonRef = buttonRefs.current[id];
    if (buttonRef) {
        const rect = buttonRef.getBoundingClientRect();
        setDropdownPosition({
            top: rect.bottom + window.scrollY,
        });
        setActiveDropdown((prev) => (prev === id ? null : id));
    }
}, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    const handleKeyDown = (event) => {
      const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
      if (keysToCloseDropdown.includes(event.key)) {
        setActiveDropdown(null);
      }
    };
    const handleScroll = () => {
      setActiveDropdown(null);
    };
  
    if (activeDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [activeDropdown]);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRowData([]);
  }

  function openModal(e, option) {
    setRowData(e);
    if (option === 'edit') {
      setEdit(true);
    }
    else if (option === 'create') {
      setEdit(false);
    }
    setIsOpen(true);
  }

  const ReloadData = async (loadprops) => {
    setLoader(loadprops);
    axios
      .get(`${API_URL}/projecttag`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 100);
      });
  };

  const handleCreateState = async () => {
    setIsOpen(false);
    let create_form = { proj_tag_name: rowData.proj_tag_name };
    try {
      await axios
        .post(`${API_URL}/projecttag`, create_form, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleEditState = async () => {
    let statu = { proj_tag_name: rowData.proj_tag_name };
    try {
      await axios
        .patch(`${API_URL}/projecttag/${rowData.proj_tag_code}`, statu, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpen(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpen(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle(`Update Project Tag Status - ${e.proj_tag_code}`)
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async () => {
    let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
    try {
      await axios
        .patch(`${API_URL}/projecttag/status/${rowData.proj_tag_code}`, statusData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenStatusModal(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenStatusModal(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(e) {
    setRowData(e);
    setConfirmationMsg(`Are you sure that you want to delete project tag - ${e.proj_tag_code} ?`)
    setIsOpenConfirmPopup(true);
  }

  const handleDeleteState = async () => {
    try {
      await axios
        .delete(`${API_URL}/projecttag/${rowData.proj_tag_code}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'proj_tag_name',
        enableColumnFilter: false,
        size: 250,
        header: () => <span>Tag Name</span>
      },
      {
        accessorKey: 'proj_tag_code',
        enableColumnFilter: false,
        size: 250,
        header: () => <span>Tag Code</span>,
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter: false,
        size: 300,
        header: 'Created Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.creation_date)}</div>
        )
      },
      {
        accessorKey: 'creation_by',
        enableColumnFilter: false,
        size: 50,
        header: 'Created By',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex justify-center'><div onClick={() => openStatusModal(row.original)} className={`${row.original.status === true ? 'bg-green-300  text-green-900' : 'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}>{row.original.status === true ? "Active" : "Inactive"}</div></div>
        )
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
            <Menu as="div">
              <div ref={(el) => (buttonRefs.current[row.original.id] = el)}>
                <Menu.Button className="relative border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    calculatePosition(row.original.id)
                  }
                  }
                >
                  <EllipsisHorizontalIcon className="h-4 text-black" />
                </Menu.Button>
              </div>
              <Portal>
                <Transition
                  as={Fragment}
                  show={activeDropdown === row.original.id}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="fixed z-30 right-8 md:right-12 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                      top: `${dropdownPosition.top}px`,
                    }}
                    ref={dropdownRef}
                  >
                    <button
                      className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-blue-800 w-full text-sm"
                      onClick={() => {
                        openModal(row.original, "edit")
                        setActiveDropdown(null)
                      }}
                    >
                      <PencilIcon className="h-4 text-blue-800 mr-2" />
                      <span>Edit</span>
                    </button>
                    <button className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-red-800 w-full text-sm"
                      onClick={() => {
                        openConfirmPopup(row.original)
                        setActiveDropdown(null)
                      }}>
                      <TrashIcon className='h-4 text-red-800 mr-2' />
                      <span>Delete</span>
                    </button>
                  </Menu.Items>
                </Transition>
              </Portal>
            </Menu>
          </div>
        )
      },
    ],
    [activeDropdown, calculatePosition, dropdownPosition.top]
  );

  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(initialpageSize);


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
  const pageCount = Math.ceil(data.length / pageSize);
  const pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setPageIndex(0)
  };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (
      table.getFilteredRowModel().rows.indexOf(row) + 1 ||
      table.getSortedRowModel().rows.indexOf(row) + 1 ||
      row.index + 1
    );
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(`${API_URL}/projecttag`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    fetchInitialData();
  }, []);

  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={handleDeleteState}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <InputModal isOpen={isOpen} closeModal={closeModal} title={edit === false ? 'Create project tag' : 'Edit project tag'} savefn={edit === false ? handleCreateState : handleEditState}
        modalInputs={
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Tag Name
            </label>
            <input type="text" className='block w-full rounded-md border py-1.5 mt-1 ring-0 sm:text-sm sm:leading-6' defaultValue={rowData.proj_tag_name} onChange={(e) => setRowData({ ...rowData, proj_tag_name: e.target.value })} />
          </div>
        }
      />
      <InputModal isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="true"
                checked={rowData.status === true || rowData.status === "true"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                Active
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="false"
                checked={rowData.status === false || rowData.status === "false"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                Inactive
              </label>
            </div>
          </div>
        }
      />
      <div className='flex justify-between m-3 gap-2'>
        <div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder="Search..."
          />
        </div>
        <div className='flex gap-2'>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
            <ArrowPathIcon className='h-5 text-green-700' />
          </button>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => openModal('e', 'create')}>
            <PlusCircleIcon className='h-5' />
          </button>
        </div>
      </div>
      {Loader && <FetchingDataLoader />}
      {!Loader &&
        <>
          <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto'>
            <table className='flex-inline w-full border-collapse'>
              <thead className='bg-gray-100'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='border p-2 whitespace-nowrap'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort()
                                  ? 'cursor-pointer select-none justify-start'
                                  : 'justify-center'
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ? (
                    <tr>
                      <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                        No Data Found
                      </td>
                    </tr>
                  ) :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingTop}px` }} />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                    {cell.column.id === "index" && (
                                      <center>{renderTableCellIndex(row)}</center>
                                    )}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column
                                          .columnDef
                                          .cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                          </tr>
                        )}
                      </>
                    )
                }
              </tbody>
            </table>
          </div>
          {
            rows.length > 0 && (
              <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
                <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
                  <button title='First Page' onClick={() => setPageIndex(0)}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button title='Previous Page'
                    onClick={() => setPageIndex(pageIndex - 1)}
                    disabled={pageIndex === 0}
                    aria-current="page"
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {pageNumbers.length === 1 &&
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={1}
                      onClick={() => setPageIndex(0)}
                    >
                      {1}
                    </button>
                  }
                  {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                    if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                      if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber - 1)}
                          >
                            {pageNumber}
                          </button>
                        );
                      }
                      else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber)}
                          >
                            {pageNumber + 1}
                          </button>
                        );
                      }
                      else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(0)}
                          >
                            {1}
                          </button>
                        );
                      }
                    } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                      return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                    }
                    return null;
                  })}
                  <button title='Next Page' onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === (table.getPageCount() - 1)}
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
                <PageSizeDropdown
                  dataLength={table.getPrePaginationRowModel().rows.length}
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  totalRows={table.getPrePaginationRowModel().rows.length}
                />
              </div>
            )
          }
        </>
      }
    </div>
  );
}