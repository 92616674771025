import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

const PieProjectCount = ({data}) => {
    // Filter out data with 0 values
    const filteredData = data.filter(entry => entry.value !== 0);
    // Generate colors based on the filtered data
    const COLORS = data.map((entry, index) => {
    // Skip color for the column if the value is 0
    return entry.value !== 0 ? ['#3B82F6', '#FF3131'][index] : null;
    }).filter(color => color !== null);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 2.4;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = -10 + cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        return (
          <text x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor="middle" dominantBaseline="central">
            <tspan className='text-sm font-bold'>{filteredData[index].name}</tspan>
            <tspan x={x} dy="1rem" fill={COLORS[index % COLORS.length]} className='text-xs font-bold'>{`${(percent * 100).toFixed(1)}%`}</tspan>
          </text>
        );
    };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={filteredData}
          cx="50%"
          cy="50%"
          outerRadius={80}
          innerRadius={50}
          paddingAngle={1}
          fill="#8884d8"
          stroke="none"
          dataKey="value"
          fontSize={12}
          label={renderCustomizedLabel}
        >
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieProjectCount;
