import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../imports";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import PieReportChart from "./PieReportChart";
import PieProjectCount from "./PieProjectCount";

const processData = (inputData, targetProcessName) => {
    const filteredData = inputData?.filter(item => item.process_name === targetProcessName);

    const result = filteredData?.map(item => ([
      {
        name: 'Finalized',
        value: item.finalized < 0 ? Math.abs(item.finalized) : item.finalized,
      },
      {
        name: 'Success',
        value: item.success < 0 ? Math.abs(item.success) : item.success,
      },
      {
        name: 'Pending',
        value: item.pending < 0 ? Math.abs(item.pending) : item.pending,
      },
      {
        name: 'Error',
        value: item.error < 0 ? Math.abs(item.error) : item.error,
      }
    ]));
  
    return result ? result.flat() : [];
};

const projectCountData = (inputData) => {
    const result = [
      {
        name: 'Active',
        value: inputData?.filter(item=>item.project_status===1).length || 0,
      },
      {
        name: 'Inactive',
        value: inputData?.filter(item=>item.project_status===0).length || 0,
      }
    ];
  
    return result ? result.flat() : [];
};

export default function Settings() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [file_count, setFile_count] = useState(false);
    const [projects, setProjects] = useState([]);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    const [selectedProject, setSelectedProject] = useState("");

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const handleSelection = async (projectCodeprop) => {
        setSelectedProject(projectCodeprop);
        axios
            .get(`${API_URL}/entries/projectreport/${projectCodeprop}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setData(response.data.data.tbody);
                setFile_count(response.data.data.file_count);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const fetchProcess = async (projectCode) => {
            axios
                .get(`${API_URL}/entries/projectreport/${projectCode}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    setData(response.data.data.tbody);
                    setFile_count(response.data.data.file_count);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        const fetchProjects = async () => {
            axios
                .get(`${API_URL}/projectusers/projects`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    setProjects(response.data.data);
                    const firstProjectCode =
                        sessionStorage.getItem("projectCode") ||
                        response.data.data[0].project_code;
                    setSelectedProject(firstProjectCode);
                    fetchProcess(firstProjectCode);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                });
        };
        fetchProjects();
    }, []);

    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-auto h-[calc(100vh-14rem)] listboxscroll">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={false}
            />
            <>
            <div className="flex flex-col-reverse lg:flex-row gap-3 mx-2 my-3">
                <>
            <div className="border w-full lg:w-1/3 border-gray-300 rounded-md">
                <p className="p-2 bg-gray-100 rounded-t-md border-b border-gray-300">List of Active Projects ({projects?.filter(item=>item.project_status===1).length})</p>
                <ul className="h-[19rem] overflow-auto listboxscroll">
            {projects?.filter(item=>item.project_status===1).map((item, index) => (
                                <li key={index} className={`text-sm border-b border-gray-300 p-2.5 cursor-default lg:cursor-pointer ${projects?.filter(item=>item.project_status===1).length>7 && projects?.filter(item=>item.project_status===1).length===index+1 && 'border-none'}`} value={item.project_code} onClick={() => handleSelection(item.project_code)}>
                                    <span className={item.project_code===selectedProject && 'bg-indigo-900 text-white font-bold rounded-xl px-2 py-1'}>{item.project_name}</span>
                                </li>
                            ))}
            </ul>
            </div>
            <div className="border w-full lg:w-1/3 border-gray-300 rounded-md">
                <p className="p-2 bg-gray-100 rounded-t-md border-b border-gray-300">List of Inactive Projects ({projects?.filter(item=>item.project_status===0).length})</p>
                <ul className="h-[19rem] overflow-auto listboxscroll">
            {projects?.filter(item=>item.project_status===0).map((item, index) => (
                                <li key={index} className={`text-sm border-b border-gray-300 p-2.5 cursor-default lg:cursor-pointer ${projects?.filter(item=>item.project_status===0).length>7 && projects?.filter(item=>item.project_status===0).length===index+1 && 'border-none'}`} value={item.project_code} onClick={() => handleSelection(item.project_code)}>
                                    <span className={item.project_code===selectedProject && 'bg-indigo-900 text-white font-bold rounded-xl px-2 py-1'}>{item.project_name}</span>
                                </li>
                            ))}
                            </ul>
            </div>
            </>
            <div className="border border-gray-300 rounded-md w-full lg:w-1/3"><p className="border-b border-gray-300 p-2 bg-gray-100 rounded-t-md">Distribution of Active & Inactive Projects</p><PieProjectCount data={projectCountData(projects)}/></div>    
            </div>               
                <div>
                    <div className="border border-gray-300 border-collapse rounded-md mb-2 mx-2">
                <div className="p-1 ps-2 border-b border-gray-300 bg-gray-100 rounded-t-md">
                    <div className="flex flex-wrap justify-between items-center overflow-auto">
                            {projects.filter(item=>item.project_code===selectedProject).map((item) => (
                                <p key={item.project_code}>
                                    Showing the Process status of {item.project_name} Files
                                </p>
                            ))}
                        <p className="text-white text-xs w-fit px-2 py-2 my-1 md:m-1 font-bold rounded bg-yellow-500">
                            Total:{file_count}
                        </p>
                    </div>
                </div>
                    <div className="w-full overflow-auto">
                    <table className=" border-hidden w-full">
                        <thead>
                            <tr className="font-bold uppercase text-sm text-gray-600 lg:whitespace-nowrap">
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Process
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Status
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Success
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Error
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Unprocessed
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Unique
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Split
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Merged
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Finalized
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Pending
                                </th>
                                <th className="p-2 border border-gray-300 bg-gray-200">
                                    Current Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                                    <td
                                        className="w-full lg:w-auto p-3 text-gray-800  border border-b block lg:table-cell relative lg:static"
                                        colSpan={9}
                                    >
                                        <div className="flex items-center space-x-2">
                                            <div
                                                aria-label="Loading..."
                                                role="status"
                                            >
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="animate-spin w-4 h-4 stroke-slate-500"
                                                >
                                                    <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
                                                </svg>
                                            </div>
                                            <span className="text-xs font-medium text-slate-500">
                                                Loading...
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {data.map((itm, index) => (
                                        <tr
                                            key={itm.process_name}
                                            className={`bg-white lg:hover:bg-gray-100 lg:whitespace-nowrap mb-10 lg:mb-0 ${data.length===index+1 && 'rounded-bl-md'}`}
                                        >
                                            <td className={`w-full lg:w-auto p-2 text-gray-800 text-left border ${data.length===index+1 && 'rounded-bl-md'}`}>
                                                {itm.process_name}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {itm.status}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {itm.success}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {/* {itm.error} */}
                                                {itm.error < 0
                                                    ? Math.abs(itm.error)
                                                    : itm.error}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {itm.unprocessed < 0
                                                    ? Math.abs(itm.unprocessed)
                                                    : itm.unprocessed}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {/* {itm.unique ? itm.unique : "0"} */}
                                                {itm.unique < 0
                                                    ? Math.abs(itm.unique)
                                                    : itm.unique}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {/* {itm.split ? itm.split : "0"} */}
                                                {itm.split < 0
                                                    ? Math.abs(itm.split)
                                                    : itm.split}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {/* {itm.merged ? itm.merged : "0"} */}
                                                {itm.merged < 0
                                                    ? Math.abs(itm.merged)
                                                    : itm.merged}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {itm.finalized}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {/* {itm.pending} */}
                                                {itm.pending < 0
                                                    ? Math.abs(itm.pending)
                                                    : itm.pending}
                                            </td>
                                            <td className="w-full lg:w-auto p-2 text-gray-800 text-center border">
                                                {itm.total_status}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    {data.length>0 && <div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-2 pt-1 gap-3 select-none">
                        <div className="border border-gray-300 rounded-md"><p className="border-b border-gray-300 p-2 bg-gray-100 rounded-t-md">Transcription Metrics</p><PieReportChart data={processData(data, "Transcription")}/></div>
                        <div className="border border-gray-300 rounded-md"><p className="border-b border-gray-300 p-2 bg-gray-100 rounded-t-md">Get Ideas Metrics</p><PieReportChart data={processData(data, "Get Ideas")}/></div>
                        <div className="border border-gray-300 rounded-md"><p className="border-b border-gray-300 p-2 bg-gray-100 rounded-t-md">Raw Answers Metrics</p><PieReportChart data={processData(data, "Raw Answers")}/></div>
                        <div className="border border-gray-300 rounded-md"><p className="border-b border-gray-300 p-2 bg-gray-100 rounded-t-md">Refined Answers Metrics</p><PieReportChart data={processData(data, "Refined Answers")}/></div>
                    </div>
                    }
                </div>
            </>
        </div>
    );
}
