import React, { useCallback, useEffect, useState } from "react";
import ProcessLayout from "../Common/ProcessLayout";
import { API_URL } from "../../imports";
import axios from "axios";
import FilesToProcess from "../Common/FilesToProcess";
import Process from "../Common/Process";
import CurrentProcessLogs from "../Common/CurrentProcessLogs";
import Popup from "../Common/Popup";
import { handleApiError } from "../Common/APIUtils";
import NewFilewiseStatusLogs from "../Common/NewFilewiseStatusLogs";

function Newprocess() {
    const [startProcess, setStartProcess] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [fetchedFilesData, setFetchedFilesData] = useState([]);
    const [logsData, setLogsData] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [unprocessedFiles, setUnProcessedFiles] = useState([]);
    const [serverStatus, setServerStatus] = useState(false);
    const [serverLoadState, setServerLoadState] = useState(true);
    const [Loader, setLoader] = useState(true);

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
        setIsOpenPopup(false);
    }
  
    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    const handleStartProcess = async (data) => {
        setStartProcess(true);

        const initialFiles = data.map((file) => {
            return { ...file, status: null };
        });

        setFilesData(initialFiles);
        setLogsData([]);

        const currentLogs = [];
        const updatedProcessedFiles = [];
        const updatedUnprocessedFiles = [];

        for (let i = 0; i < data.length; i++) {
            let file = data[i];
            file.status = "Inprogress";
            setFilesData((prevData) => {
                const newData = [...prevData];
                newData[i] = file;
                return newData;
            });
            try {
                const response = await axios.post(
                    `${API_URL}/${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/process/${sessionStorage.getItem("projectCode")}`,
                    {
                        filename: file.filename,
                        response: file.transcribed_response.transcribed_text,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                if (response.data.data.status === "success") {
                    // Update the status of the current file to "success"
                    file.status = "success";
                    file.response = response.data.data.response;
                    const date = new Date();
                    file.creation_date = date;
                    updatedProcessedFiles.push(file); // Add to processedFiles
                    currentLogs.push({
                        filename: file.filename,
                        creation_date: date,
                        api_version: response.data.data.response['api_version'],
                        ...response.data.data,
                    });
                } else {
                    // Update the status of the current file to "error"
                    file.status = "error";
                    file.response = null;
                    const errordate = new Date();
                    file.creation_date = errordate;
                    updatedUnprocessedFiles.push(file); // Add to unprocessedFiles
                    currentLogs.push({
                        filename: file.filename,
                        creation_date: errordate,
                        ...response.data.data,
                    });
                }
            } catch (error) {
                // Update the status of the current file to "error"
                file.status = "error";
                file.response = null;
                const errordate = new Date();
                file.creation_date = errordate;
                updatedUnprocessedFiles.push(file); // Add to unprocessedFiles
            }

            setFilesData((prevData) => {
                const newData = [...prevData];
                newData[i] = file;
                return newData;
            });
        }

        // Update the state with the updatedFiles array
        setLogsData(currentLogs);
        setProcessedFiles(updatedProcessedFiles); // Update processedFiles state
        setUnProcessedFiles(updatedUnprocessedFiles); // Update unprocessedFiles state
        setStartProcess(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/closedfiles/${sessionStorage.getItem("projectCode")}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                });
                setFetchedFilesData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            }
            finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        }
        const fetchStatus = async () => {
            try {
                const response = await axios.get(`${API_URL}/instancemonitor`,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`
                    },
                    params: {
                        server_instance: sessionStorage.getItem("serverCode")
                    }
                });
                if (response){
                    setServerStatus(response.data.data[0].serverStatus);
                }
            } catch (error) {
                const errorMessage = handleApiError(error);
                if(errorMessage){
                    setServerStatus(false);
                }
            }
            finally{
                setServerLoadState(false);
            }
        };
        fetchData();
        if(sessionStorage.getItem("serverCode")){
            fetchStatus();
        }
    }, [setFetchedFilesData, setServerStatus, openPopup]);

    const ReloadData = useCallback(() => {
        setLoader(true);
        const reloadData = async () => {
            try {
                const response = await axios.get(`${API_URL}/${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/closedfiles/${sessionStorage.getItem("projectCode")}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                });
                setFetchedFilesData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            }
            finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        }
        reloadData();
    }, [setFetchedFilesData, openPopup]);

    const tabs = [
        {
            id: 1,
            title: `${JSON.parse(sessionStorage.getItem('additional_config')).tab1} Files`,
            content: (
                <FilesToProcess
                    serverStatus={serverStatus}
                    ReloadData={ReloadData}
                    items={fetchedFilesData}
                    startProcess={startProcess}
                    setFilesData={handleStartProcess}
                    Loader={Loader}
                />
            ),
        },
        {
            id: 2,
            title: `${JSON.parse(sessionStorage.getItem('additional_config')).tab2} Process`,
            content: (
                <Process
                    startProcess={startProcess}
                    selectedFilesToProcess={filesData}
                />
            ),
        },
        {
            id: 3,
            title: "Logs",
            content: (
                <CurrentProcessLogs
                    logs={logsData}
                    processedfiles={processedFiles}
                    unprocessedfiles={unprocessedFiles}
                />
            ),
        },
        {
            id: 4,
            title: "All Files Status",
            content: (
                <NewFilewiseStatusLogs
                    fetchFilewiseAPIURL={`${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/filewisestatusrpt/${sessionStorage.getItem("projectCode")}`}
                    fileDetailsAPIURL={`${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/fileDetailsLog/${sessionStorage.getItem("projectCode")}`}
                    CloseAPIURL={`${JSON.parse(sessionStorage.getItem('additional_config')).processapiNamespace}/closefiles/${sessionStorage.getItem("projectCode")}`}
                    process_code={sessionStorage.getItem("processCode")}
                />
            ),
        },
    ];

    return (
        <>
            <Popup isOpen={isOpenPopup} msg={msg} closeModal={closePopup} isSuccess={isSuccess}/>
            <ProcessLayout startProcess={startProcess} tabs={tabs} server={(JSON.parse(sessionStorage.getItem('additional_config')).serverCode)? `${JSON.parse(sessionStorage.getItem('additional_config')).serverCode} server`: null} serverStatus={serverStatus} serverLoadState={serverLoadState} batching={JSON.parse(sessionStorage.getItem('additional_config')).batching}/>
        </>
    );
}

export default Newprocess;