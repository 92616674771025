import React, { useState, useCallback, useEffect, Fragment } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    DebouncedInput,
    IndeterminateCheckbox,
    StatusFilter,
} from "../../Common/Filters";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import ProcessingLoader from "../../Common/ProcessingLoader";
import FetchingDataLoader from "../../Common/FetchingDataLoader";
import Popup from "../../Common/Popup";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import { Menu, Transition } from "@headlessui/react";
import { formattedDate } from "../../Common/CommonUtils";
import IndexBasedRangeSelection from "../../Common/IndexBasedRangeSelection";

export default function AudioFilesToProcess({
    items,
    setFilesData,
    startProcess,
    ReloadData,
    serverStatus,
    Loader,
    setProcessOption,
    backgroundProcess,
    setServerOFF,
    startIndex,
    endIndex,
    setStartIndex,
    setEndIndex,
    rowSelection,
    setRowSelection
}) {
    let projectState = sessionStorage.getItem("projectStatus");
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkVisible, setCheckVisible] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    function openConfirmPopup(confirmationmsgprop) {
        setConfirmationMsg(confirmationmsgprop);
        setIsOpenConfirmPopup(true);
    }

    const handleSelectedState = () => {
        setIsOpenConfirmPopup(false);
        setServerOFF(checked);
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);
        setFilesData(selectedFiles);
    };

    // Update rowSelection based on start and end index inputs
    const handleSetSelection = () => {
        if (startIndex !== null && endIndex !== null && startIndex <= endIndex) {
            const newSelection = {};
            for (let i = startIndex-1; i <= endIndex-1; i++) {
            newSelection[i] = true;  // Mark these indexes as selected
            }
            setRowSelection(newSelection);
        }
        };

    const [sorting, setSorting] = React.useState([]);
    const [globalFilter, setGlobalFilter] = React.useState("");

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "name",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>File Name</span>,
            },
            {
                accessorKey: "status",
                enableColumnFilter: true,
                filterFn: "equals",
                size: 50,
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            className={`${row.original.status === "success" &&
                                "bg-green-500"
                                } ${row.original.status === "error" && "bg-red-500"
                                } ${row.original.status === "Unprocessed" &&
                                "bg-yellow-500"
                                }  ${row.original.status !== "success" &&
                                row.original.status !== "error" &&
                                row.original.status !== "Unprocessed" &&
                                "bg-yellow-500"
                                } 
                            text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}
                        >
                            {row.original.status && row.original.status}
                            {!row.original.status && "Unprocessed"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "last_modified",
                enableColumnFilter: false,
                size: 100,
                header: "Modified Date",
                cell: ({ row }) => (
                    <center>{row.original.last_modified !== "" && formattedDate(row.original.last_modified)}</center>
                ),
            },
            {
                accessorKey: "size",
                enableColumnFilter: false,
                size: 50,
                header: () => <span>Size</span>,
            },
        ],
        []
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(items);
    }, [items]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    const tableContainerRef = React.useRef(null);

    const handleSelectedProcess = (option) => {
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);
        if(option==='background' && selectedFiles.length > 0){
            setCheckVisible(true);
            openConfirmPopup(`Are you sure that you want to process ${selectedFiles.length} files via background?`);
            setProcessOption("background");
        }
        else if (selectedFiles.length > 0 && selectedFiles.length <= 5) {
            setCheckVisible(false);
            setFilesData(selectedFiles);
            setProcessOption("normal");
        } else if (selectedFiles.length > 5 && selectedFiles.length !== data.length) {
            setCheckVisible(false);
            openConfirmPopup("Are you sure that you want to process more than 5 files?");
            setProcessOption("normal");
        } else if (selectedFiles.length === data.length) {
            setCheckVisible(false);
            openConfirmPopup("Are you sure that you want to process all the files?");
            setProcessOption("normal");
        } else {
            setMsg("Please select files to process");
            setIsSuccess(false);
            openPopup();
        }
    };

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={handleSelectedState}
                checkVisible={checkVisible}
                checkState={checked}
                onCheckChange={(e)=>setChecked(e.target.checked)}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex flex-wrap justify-between m-3 gap-2">
                <div className="flex md:gap-2">
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className="flex flex-wrap gap-2">
                    <IndexBasedRangeSelection startIndex={startIndex} setStartIndex={setStartIndex} endIndex={endIndex} setEndIndex={setEndIndex} handleSetSelection={handleSetSelection} max={items.length}/>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={ReloadData}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    <Menu as="div" className="relative">
                        <div>
                            <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                <span className="absolute -inset-1.5" />
                                <FunnelIcon className="h-5 w-auto" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div>
                                    {data.length > 0 && table.getHeaderGroups().map((headerGroup) => (
                                        <div key={headerGroup.id} className="space-y-1">
                                            {headerGroup.headers.map((header) => (
                                                <div key={header.id}>
                                                    {header.column.getCanFilter() && header.column.id === "status" ? (
                                                        <div>
                                                            <label htmlFor="push-everything" className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1">
                                                                Status
                                                            </label>
                                                            <StatusFilter column={header.column} table={table} />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            {Loader && <FetchingDataLoader />}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="overflow-auto resp-processtable-h border-y"
                    >
                        <table
                            className={`flex-inline w-full border-collapse ${startProcess === true && "animate-pulse"
                                }`}
                        >
                            <thead className="bg-gray-200 border-b">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort() &&
                                                                    header
                                                                        .column
                                                                        .id !==
                                                                    "status" &&
                                                                    header
                                                                        .column
                                                                        .id !==
                                                                    "last_modified"
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr
                                                            key={row.id}
                                                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                        >
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {cell.column.id === "index" && <center>{renderTableCellIndex(row)}</center>}
                                                                            {cell.column.id !==
                                                                                "index" &&
                                                                                flexRender(
                                                                                    cell.column
                                                                                        .columnDef
                                                                                        .cell,
                                                                                    cell.getContext()
                                                                                )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    {serverStatus && projectState === "true" && Object.keys(rowSelection).length > 0 && (
                        <div className="flex justify-end lg:justify-start gap-1 m-3">
                            {!startProcess && (
                                <button
                                    type="button"
                                    disabled={(startProcess || backgroundProcess)}
                                    onClick={() => handleSelectedProcess('normal')}
                                    className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                >
                                    Start Process
                                </button>
                            )}
                            {!backgroundProcess && (
                                <button
                                    type="button"
                                    disabled={(startProcess || backgroundProcess)}
                                    onClick={() => handleSelectedProcess('background')}
                                    className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                >
                                    Start Background Process
                                </button>
                            )}
                            {(startProcess || backgroundProcess) && <ProcessingLoader />}
                        </div>
                    )}
                </>
            )
            }
        </div >
    );
}
