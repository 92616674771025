import React from "react";

function NoDataFound() {
  return (
    <div className="p-2.5 border-y flex">
      No Data Found...
    </div>
  );
}

export default NoDataFound;
