import { Tab } from "@headlessui/react";
import { CSVLink } from "react-csv";
import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ListFiles({ files }) {
    return (
        <div>
            {files.length > 0 &&
                files.map((file, index) => (
                    <p
                        key={index}
                        className="text-sm p-2 tracking-wider border"
                    >
                        {file.filename}
                    </p>
                ))}
            {files.length === 0 && (
                <p className="text-start p-2 text-sm">No Files Present</p>
            )}
        </div>
    );
}

function LogsTab({ processedfiles, unprocessedfiles }) {
    return (
        <div className="border mt-4">
            <Tab.Group as="div">
                <Tab.List className="flex justify-start shadow-md bg-gray-50 border-b overflow-auto">
                    <div className="flex gap-2 md:gap-3">
                        <Tab
                            key={1}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <p className="text-sm pt-4 tracking-wider">
                                Processed Files{" "}
                                <span className="rounded-full p-1 bg-red-100">
                                    {processedfiles && processedfiles.length}
                                    {!processedfiles && "0"}
                                </span>
                            </p>
                        </Tab>
                        <Tab
                            key={2}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <p className="text-sm pt-4 tracking-wider">
                                Unprocessed Files{" "}
                                <span className="rounded-full p-1 bg-red-100">
                                    {unprocessedfiles &&
                                        unprocessedfiles.length}
                                    {!unprocessedfiles && "0"}
                                </span>
                            </p>
                        </Tab>
                    </div>
                </Tab.List>
                <Tab.Panels className="overflow-auto">
                    <Tab.Panel
                        key={1}
                        className={classNames(
                            "bg-white",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        <ListFiles files={processedfiles} />
                    </Tab.Panel>
                    <Tab.Panel
                        key={2}
                        className={classNames(
                            "bg-white",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        <ListFiles files={unprocessedfiles} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

const CurrentProcessLogs = ({ logs, processedfiles, unprocessedfiles }) => {
    const formattedDate = (dateStr) => {
        const date = new Date(dateStr);
        const formattedDateString = date
            .toLocaleString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            })
            .replace(/\//g, "/")
            .replace(",", " ");
        const [month, day, yearAndTime] = formattedDateString.split("/");
        return `${day}-${month}-${yearAndTime}`;
    };
    const csvData = logs.map((record, index) => ({
        "#": index + 1,
        "File Name": record.filename,
        Status: record.status,
        "Status Code": record.status_code,
        "Status Message": record.status_message,
        "API Version": record.api_version,
        // ...(record.qdb_code != null ? { "QDB Code": record.qdb_code } : {}),
        ...(record.error_code != null
            ? { "Error Code": record.error_code }
            : {}),
        ...(record.error_code != null && record.error_message != null
            ? { "Error Message": record.error_message }
            : {}),
        "Execution Date": record.creation_date,
    }));
    return (
        <div>
            {logs.length === 0 && (
                <p className="p-3 pb-0">Please Start Process to get logs</p>
            )}
            {logs.length > 0 && (
                <>
                    <div className="flex justify-start p-4">
                        <CSVLink
                            className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                            data={csvData}
                            filename="current_logs.csv"
                        >
                            Download Logs
                        </CSVLink>
                    </div>
                    <table className="table-auto overflow-auto border-collapse whitespace-nowrap w-full">
                        <thead>
                            <tr className="shadow bg-gray-100 text-left">
                                <th className="p-2 border">#</th>
                                <th className="p-2 border">File Name</th>
                                <th className="p-2 border">Status</th>
                                <th className="p-2 border">Status Code</th>
                                <th className="p-2 border">Status Message</th>
                                {/* {logs[0].qdb_code &&
                                <th className="px-4 py-2 border">
                                    QDB Code
                                </th>
                                } */}
                                <th className="p-2 border">API Version</th>
                                <th className="p-2 border">Start Date</th>
                                <th className="p-2 border">Execution Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map((log, index) => (
                                <tr key={index} className="text-left">
                                    <td className="border p-2">{index + 1}</td>
                                    <td className="border p-2">
                                        {log.filename}
                                    </td>
                                    <td className="border p-2">{log.status}</td>
                                    <td className="border p-2">
                                        {log.status_code}{" "}
                                        {log.error_code && log.error_code}
                                    </td>
                                    <td
                                        className="border p-2"
                                        title={
                                            log.status_message ||
                                            log.error_message
                                        }
                                    >
                                        {log.status_message !== null &&
                                        log.status_message !== undefined
                                            ? log.status_message.slice(0, 20) +
                                              (log.status_message.length > 20
                                                  ? "..."
                                                  : "")
                                            : null}{" "}
                                        {log.error_message &&
                                        log.error_message !== null
                                            ? log.error_message.slice(0, 20) +
                                              (log.error_message.length > 20
                                                  ? "..."
                                                  : "")
                                            : null}
                                    </td>
                                    {/* {log.qdb_code &&
                                        <td className="border px-4 py-2">
                                            {log.qdb_code}
                                        </td>
                                    }       */}
                                    <td className="border p-2">
                                        {log.api_version}
                                    </td>
                                    <td className="border p-2">
                                        {log.start_date_time}
                                    </td>
                                    <td className="border p-2">
                                        {formattedDate(log.creation_date)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            <LogsTab
                processedfiles={processedfiles}
                unprocessedfiles={unprocessedfiles}
            />
        </div>
    );
};

export default CurrentProcessLogs;
