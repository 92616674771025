import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'

function ReadModal({isOpen, closeModal, modalInputs, title}) {
  return (
    <Transition className="font-GoogleSans tracking-wider" appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex min-h-full lg:align-middle overflow-y-auto p-4 justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[calc(100vw-1rem)]  lg:max-w-[calc(100vw-6rem)] transform overflow-hidden rounded-lg bg-white py-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between leading-6 px-4 pb-2 text-gray-900 border-b"
                  >
                    <div>{sessionStorage.getItem('processName') || sessionStorage.getItem('projectName') ? (<span className='font-bold'>{sessionStorage.getItem('processName') || sessionStorage.getItem('projectName')}: </span>) : null}<span className={sessionStorage.getItem('processName')? 'italic':'font-bold'}>{title}</span></div><button onClick={closeModal} className='cursor-default lg:cursor-pointer'><XCircleIcon className='h-6 w-auto'/></button>
                  </Dialog.Title>
                  <div className="mt-4 px-5 overflow-auto">
                    {modalInputs}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default ReadModal