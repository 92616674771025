import React from 'react'

function Footer() {
  return (
    <footer className='bottom-0 bg-white border-t absolute w-full'>
   <p className=" p-5 tracking-widest text-start">
   {new Date().getFullYear()} <span className='font-sans'>&copy;</span> Copyrights - Inxite Out
   </p>
   </footer>
  )
}

export default Footer