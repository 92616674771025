import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../imports'
import { handleApiError } from '../Common/APIUtils';

function PasswordUpdate() {
  const [passwordError, setPasswordError] = useState({
    currentpassword: '',
    newpassword: '',
    confirmpassword: ''
  });
  const [responseError, setResponseError]=useState(false);
  const [successMsg, setSuccessMsg]=useState('');
  const [formData, setFormData] = useState({
    currentpassword: '',
    newpassword: '',
  });
  const [confirmpassword, setConfirmpassword]=useState('');

  const handleInputChange = (event) => {  
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validatePassword = (value) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()?_+])[\w!@#$%^&*?()_+]{8,}$/;
    const pwdError = passwordPattern.test(value);
    return pwdError;
  };

  const navigate = useNavigate();

  const clearError = () => {
    setTimeout(() => {
      setPasswordError({
        currentpassword: '',
        newpassword: '',
        confirmpassword: ''
      });
      setResponseError(false);
    }, 2000);
  };

  const Logout = () => {
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    }, 3000);
  };

const updatePasswordSubmit = (event) => {
    event.preventDefault();

// Input validation
setPasswordError({
  currentpassword: '',
  newpassword: '',
  confirmpassword: ''
});

if (!formData.currentpassword) {
  setPasswordError(prevState => ({...prevState, 'currentpassword': 'Please enter your current password.'}));
} else if (!validatePassword(formData.currentpassword)) {
  setPasswordError(prevState => ({...prevState, 'currentpassword': 'Please enter a valid current password.'}));
}

if (!formData.newpassword) {
  setPasswordError(prevState => ({...prevState, 'newpassword': 'Please enter your new password.'}));
} else if (!validatePassword(formData.newpassword)) {
  setPasswordError(prevState => ({...prevState, 'newpassword': 'Password should contain at least 8 characters, one uppercase letter, one lowercase letter, one number, one special character and no spaces.'}));
}
else if(formData.currentpassword===formData.newpassword){
  setPasswordError(prevState => ({...prevState, 'newpassword': "The New Password that you entered should not be same as your current password."}));
}

if (!confirmpassword) {
  setPasswordError(prevState => ({...prevState, 'confirmpassword': 'Please enter your new password again.'}));
}
else if (confirmpassword!==formData.newpassword) {
  setPasswordError(prevState => ({...prevState, 'confirmpassword': "The Password that you entered doesn't match the new password."}));
}

if (formData.currentpassword && formData.newpassword && confirmpassword && confirmpassword===formData.newpassword && formData.currentpassword!==formData.newpassword && validatePassword(formData.currentpassword) && validatePassword(formData.newpassword) && validatePassword(confirmpassword)) {
    axios.put(`${API_URL}/auth/updatepassword`, formData, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              }
          }).then((response) => {
                  setSuccessMsg(true);
                  window.document.getElementById("sucsp").innerHTML=response.data.message;
                  Logout();
            })
            .catch((error) => {
              setResponseError(true);
              const errorMessage = handleApiError(error);
              window.document.getElementById("errdsp").innerHTML=errorMessage;
              clearError();
            });
}};

  return (
    <div className='border bg-white shadow font-GoogleSans tracking-wider overflow-hidden p-4 pb-6 max-w-md space-y-5'>
        <p className='text-sm'>Please enter your current password, along with your new password below. Once submitted, the new passwords will replace your current password.</p>
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${responseError ? 'block' : 'hidden'}`}></p>
        <p id="sucsp" className={`text-blue-800 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${successMsg ? 'block' : 'hidden'}`}></p>
        <form className='space-y-5' onSubmit={updatePasswordSubmit}>
        <div className="sm:col-span-3">
              <label htmlFor="currentpassword" className="block text-sm font-medium leading-6 text-gray-900">
                Current Password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="currentpassword"
                  id="currentpassword"
                  autoComplete="password"
                  value={formData.currentpassword} 
                  onChange={handleInputChange}
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${passwordError.currentpassword ? 'ring-red-300' : ''}`}
                />
                {passwordError.currentpassword && <p className="text-xs mt-2 text-red-500">{passwordError.currentpassword}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="newpassword" className="block text-sm font-medium leading-6 text-gray-900">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="newpassword"
                  name="newpassword"
                  type="password"
                  value={formData.newpassword} 
                  onChange={handleInputChange}
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${passwordError.newpassword ? 'ring-red-300' : ''}`}
                />
                {passwordError.newpassword && <p className="text-xs mt-2 text-red-500">{passwordError.newpassword}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="confirmpassword" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  value={confirmpassword}
                  onChange={(event) =>
                      setConfirmpassword(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${passwordError.confirmpassword ? 'ring-red-300' : ''}`}
                />
                {passwordError.confirmpassword && <p className="text-xs mt-2 text-red-500">{passwordError.confirmpassword}</p>}
              </div>  
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex justify-center rounded-lg border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
               >
                Update Password
              </button>
            </div>
            </form>
    </div>
  )
}

export default PasswordUpdate