import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

function ServiceStartConfirmationPopup({ 
    isOpenConfirmPopup, 
    confirmationMsg, 
    closeConfirmPopup, 
    onStateChange, 
    checkVisible, 
    checkState, 
    onCheckChange, 
    actionType, 
    isStarting, 
    callback 
}) {
  return (
    <Transition appear show={isOpenConfirmPopup} as={Fragment}>
      <Dialog
        as="div"
        className="relative align-middle justify-center flex z-10"
        onClose={closeConfirmPopup}
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex align-middle justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform overflow-hidden font-GoogleSans tracking-wider rounded-lg bg-white p-3 text-start justify-center align-middle shadow border transition-all">
                <div className="mt-2 text-break">
                  {!isStarting && (
                    <p className='flex text-sm'>
                      <ExclamationTriangleIcon 
                        className="me-1 text-red-600" 
                        style={{ height: "22px", width: "22px" }} 
                      />
                      {confirmationMsg}
                    </p>
                  )}
                  {isStarting && (
                    <div className="flex items-center justify-center mt-2">
                      <svg
                        className="animate-spin mr-2 h-5 w-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx={12}
                          cy={12}
                          r={10}
                          stroke="currentColor"
                          strokeWidth={4}
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      {actionType === 'restart' 
                        ? 'Service is restarting...' 
                        : actionType === 'stop' 
                        ? 'Service stopping...' 
                        : 'Service starting...'}
                    </div>
                  )}
                </div>

                {checkVisible && !isStarting && (
                  <div className='flex items-center justify-end gap-x-2 mt-2'>
                    <input 
                      type="checkbox" 
                      checked={checkState} 
                      onChange={onCheckChange} 
                      className='rounded' 
                    />
                    <p className='text-sm'>
                      OFF the Server, once processing is done
                    </p>
                  </div>
                )}

                {!isStarting && (
                  <div className="flex gap-2 w-100 justify-end mt-4">
                    {actionType === 'info' ? (
                      <button
                        className="inline-flex justify-center rounded-lg border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                        onClick={closeConfirmPopup}
                      >
                        OK
                      </button>
                    ) : actionType === 'cancel' ? (
                      <>
                        <button
                          className="inline-flex justify-center rounded-lg border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                          onClick={() => callback(actionType)}
                        >
                          Yes
                        </button>
                        <button
                          className="inline-flex justify-center rounded-lg border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                          onClick={closeConfirmPopup}
                        >
                          No
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="inline-flex justify-center rounded-lg border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                          onClick={() => callback(actionType)}
                        >
                           {actionType === 'restart' 
                             ? 'Restart' 
                             : actionType === 'stop' 
                             ? 'Stop' 
                             : 'Start'}
                        </button>
                        <button
                          className="inline-flex justify-center rounded-lg border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                          onClick={closeConfirmPopup}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ServiceStartConfirmationPopup;
