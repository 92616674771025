import React, { useEffect, useState } from 'react'
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports'
import { handleApiError } from '../Common/APIUtils';

function ProfileUpdate() {
    let [data, setData]=useState({});  
    const [inputError, setInputError] = useState({
        name: '',
        email: ''
    });
    const [responseError, setResponseError]=useState(false);
    const [successMsg, setSuccessMsg]=useState('');
  
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
  
    function closePopup() {
      setIsOpenPopup(false);
    }
  
    function openPopup() {
      setIsOpenPopup(true);
    }

    const clearError = () => {
        setTimeout(() => {
          setSuccessMsg(false);
          setResponseError(false);
        }, 2000);
    };
    
    const validateUsername = (value) => {
        // Regular expression for no spaces in between and not starting with a space
        const namePattern = /^(?!\s)([a-zA-Z]+\s)*[a-zA-Z]+$/;
        const errormsgname= namePattern.test(value);
        return errormsgname;
    };

    const validateEmail = (value) => {
        // Regular expression for no spaces in between and not starting with a space
        const emailPattern = /^[^\s]+$/;
        const errormsgemail= emailPattern.test(value);
        return errormsgemail;
    };

    const updateProfileInfo = (event) => {
        event.preventDefault();
    
    // Input validation
    setResponseError('');
    setInputError({
        name: '',
        email: ''
    })
    
    if (!data.name) {
        setInputError(prevState => ({...prevState, 'name': 'Please enter your name'}));
    } else if (!validateUsername(data.name)) {
        setInputError(prevState => ({...prevState, 'name': 'Please enter a valid name'}));
    }

    if (!data.email) {
        setInputError(prevState => ({...prevState, 'email': 'Please enter your current email'}));
    } else if (!validateEmail(data.email)) {
        setInputError(prevState => ({...prevState, 'email': 'Please enter a valid email'}));
    }
    
    if (data.name && data.email && validateUsername(data.name) && validateEmail(data.email)) {
        axios
                .put(`${API_URL}/auth/profileinfo`, data , {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  }
              }).then((response) => {
                      setData(response.data.data);
                      setSuccessMsg(true);
                      window.document.getElementById("sucsp").innerHTML=response.data.message;
                      clearError();
                })
                .catch((error) => {
                  setResponseError(true);
                  const errorMessage = handleApiError(error);
                  window.document.getElementById("errdsp").innerHTML=errorMessage;
                  clearError();
                });
    }};

    useEffect(() => {
        const fetchInitialData = async () => {
               axios
                   .get(`${API_URL}/auth/profileinfo`, {
                       headers: {
                           Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                       },
                   })
                   .then((response) => {
                      setData(response.data.data);
                      setIsSuccess(true);
                   })
                   .catch((error) => {
                      const errorMessage = handleApiError(error);
                      setMsg(errorMessage);
                      setIsSuccess(false);
                      openPopup();
                   });
         };
         fetchInitialData();
    }, []);

  return (
    <div className='border bg-white shadow font-GoogleSans tracking-wider overflow-hidden p-4 pb-6 max-w-md'>
        <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
        /> 
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 my-4 ${responseError ? 'block' : 'hidden'}`}></p>
        <p id="sucsp" className={`text-blue-800 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 my-4 ${successMsg ? 'block' : 'hidden'}`}></p>
        <form className='space-y-5' onSubmit={updateProfileInfo}>
        <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={data.name}
                  onChange={(e) =>setData({...data, name: e.target.value})}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {inputError.name && <p className="text-xs mt-2 text-red-500">{inputError.name}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  disabled={localStorage.getItem("role")==='ADMIN'? false : true}
                  defaultValue={data.email}
                  onChange={(e) =>setData({...data, email: e.target.value})}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                />
                {inputError.email && <p className="text-xs mt-2 text-red-500">{inputError.email}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Gender
              </label>
              <select
                  id="gender"
                  name="gender"
                  className="block mt-1 w-full md:tracking-wider rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                  value={data.gender}
                  onChange={(e) =>setData({...data, gender: e.target.value})}
                >
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>  
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex mt-1 justify-center rounded-lg border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
               >
                Update Profile
              </button>
            </div>
            </form>

            
    </div>
  )
}

export default ProfileUpdate