import React, { useEffect, useState } from 'react'
import ReadModal from '../Common/ReadModal';
import { useDropzone } from 'react-dropzone';
import Popup from '../Common/Popup';
import { API_URL } from "../../imports";
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';
import { Tab } from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function AddFilesTabs({ tabs, handleSelectedState }) {
    return (
        <Tab.Group as="div" className="-mx-5">
            <Tab.List className="flex justify-start border-b overflow-auto">
                <div className="flex gap-2 md:gap-3">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            title={tab.tooltip}
                            onClick={()=>handleSelectedState(tab.id)}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <h1 className="text-sm lg:text-base tracking-wider">
                                {tab.title}
                            </h1>
                        </Tab>
                    ))}
                </div>
            </Tab.List>
            <Tab.Panels className="overflow-auto bg-white h-[calc(100vh-9.5rem)] md:h-[calc(100vh-9.5rem)] px-3">
                {tabs.map((tab, idx) => (
                    <Tab.Panel
                        key={idx}
                        className={classNames(
                            "bg-white",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        {tab.content}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
}

function AddFiles() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    //setRowId('');
  }

  function openModal() {
    setIsOpen(true);
  }

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
    setIsOpen(true);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const [selectedState, setSelectedState] = useState(1);

  const handleSelectedState = (id) =>{
    setSelectedState(id);
  }

  const [qdbs,setQdbs]=useState([]);
  const [selectedQDB,setSelectedQDB]=useState('');
  const [postrawFiles, setPostRawFiles] = useState([]);
  const [posttranscriptedFiles, setPostTranscriptedFiles] = useState([]);
  const [pretranscriptedFiles, setPreTranscriptedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ispostLoading, setIspostLoading] = useState(false);
  const [ispostRawLoading, setIspostRawLoading] = useState(false);
  const [progress, setProgress] = useState(0);


  const acceptedpreTranscriptedFileTypes = {
      'audio/wav': ['.wav'],
      'audio/mpeg': ['.mp3'],
      'video/mp4': ['.mp4'],
  }

  const acceptedpostFileTypes = {
    "application/json": [".json"],
    "text/plain": [".txt"],
}

  function getFileExtension(filename) {
    return '.' + filename.split('.').pop().toLowerCase();
  }

  function handlePreTranscriptedFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter(file => {
      const fileExtension = getFileExtension(file.name);

      if (!acceptedpreTranscriptedFileTypes[file.type]?.includes(fileExtension)) {
        setMsg('Invalid file type. Only  mp3 and wav files are allowed.');
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });
    setPreTranscriptedFiles(validatedFiles);
  }

  function handlePostTranscriptedFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter(file => {
      const fileExtension = getFileExtension(file.name);

      if (!acceptedpostFileTypes[file.type]?.includes(fileExtension)) {
        setMsg('Only txt and json files are allowed');
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });
    setPostTranscriptedFiles(validatedFiles);
  }

  function handlePostRawAnswersFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter(file => {
      const fileExtension = getFileExtension(file.name);

      if (!acceptedpostFileTypes[file.type]?.includes(fileExtension)) {
        setMsg('Only txt and json files are allowed');
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });
    setPostRawFiles(validatedFiles);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: selectedState===1?acceptedpreTranscriptedFileTypes:acceptedpostFileTypes,
    onDrop: selectedState === 1 
    ? handlePreTranscriptedFileChange 
    : selectedState === 3 
        ? handlePostRawAnswersFileChange 
        : handlePostTranscriptedFileChange,
    multiple: true,
  });
  
  function convertBytesToMB(bytes) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  }

  const simulateUpload = (response,option) => {
    const interval = setInterval(() => {
      for (let i = progress; i <= 110; i += 10) {
        setTimeout(() => {
          const updatedProgress = i > 100 ? 100 : i;
          setProgress(updatedProgress);
          if (i === 110) {
            if(option===1){
            setIsLoading(false);
            setPreTranscriptedFiles([]);
            }
            else if(option===2){
            setIspostLoading(false);
            setPostTranscriptedFiles([]);
            }
            else if(option===3){
            setIspostRawLoading(false);
            setPostRawFiles([]);
            }
            setProgress(0);
            setMsg(response.data.message);
            setIsSuccess(true);
            openPopup();
          }
        }, 100 * (i - progress));
      }
      clearInterval(interval);
      // Handle the response from the upload if needed
    }, 100);
  }; 
  
  
  
  const handlePreTranscriptedFileUpload = () => {
    if (pretranscriptedFiles.length > 0) {
      setIsLoading(true);
      setProgress(0);
      const formData = new FormData();

      // Loop through pretranscriptedFiles and append them to the FormData
      pretranscriptedFiles.forEach((file, index) => {
        formData.append('file', file);
      });

      axios.post(`${API_URL}/s3files/${sessionStorage.getItem("projectCode")}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
      })      
      .then((response) => {
        // Pass the response to simulateUpload
        simulateUpload(response,1);
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openModal();
      });
    } else {
      setMsg('No Files/Options are Selected');
      setIsSuccess(false);
      openPopup();
    }
  };


  const handlePostTranscriptedFileUpload = () => {
    if (posttranscriptedFiles.length > 0) {
      setIspostLoading(true);
      setProgress(0);
      const formData = new FormData();

      // Loop through pretranscriptedFiles and append them to the FormData
      posttranscriptedFiles.forEach((file, index) => {
        formData.append('file', file);
      });

      axios.post(`${API_URL}/Transcription/upload/${sessionStorage.getItem("projectCode")}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
      })      
      .then((response) => {
        // Pass the response to simulateUpload
        simulateUpload(response,2);
      })
      .catch((error) => {
        setIspostLoading(false);
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openModal();
      });
    } else {
      setMsg('No Files/Options are Selected');
      setIsSuccess(false);
      openPopup();
    }
  };

  const handlePostRawAnswersFileUpload = () => {
    if (postrawFiles.length > 0) {
      setIspostRawLoading(true);
      setProgress(0);
      const formData = new FormData();

      // Loop through pretranscriptedFiles and append them to the FormData
      postrawFiles.forEach((file, index) => {
        formData.append('file', file);
      });

      formData.append('qdb_code',selectedQDB)

      if(selectedQDB==='') {
        setIspostRawLoading(false);
        setMsg('Please Select a Question DB');
        setIsSuccess(false);
        openPopup();
      }
      else {
        axios.post(`${API_URL}/rawanswers/upload/${sessionStorage.getItem("projectCode")}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          },
        })      
        .then((response) => {
          // Pass the response to simulateUpload
          simulateUpload(response,3);
        })
        .catch((error) => {
          setIspostRawLoading(false);
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openModal();
        });
      }

    } else {
      setMsg('No Files/Options are Selected');
      setIsSuccess(false);
      openPopup();
    }
  };

  useEffect(() => {
      const fetchInitialData = async () => {
          try {
              const response = await axios.get(`${API_URL}/qdb/active/${sessionStorage.getItem("projectCode")}`,
                  {
                      headers: {
                          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                      },
                  }
              );
              setQdbs(response.data.data);
          } catch (error) {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
          }
      };
      if(selectedState===3){fetchInitialData()}
  }, [selectedState]);


  const tabs = [
    {
        id: 1,
        title: "Pre-Transcripted Files",
        tooltip: "Audio/Video Files",
        content: <div  className='space-y-4 px-2 pt-5'>
          <div className={`flex flex-col w-full h-[calc(100vh-14rem)] overflow-auto rounded-md bg-white border-2 border-dashed border-blue-900 ${(pretranscriptedFiles.length<1)?'justify-center items-center p-0':'justify-start items-start p-4 space-y-2'}`}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {pretranscriptedFiles.length<1 && (
            <>
            <p className='text-sm md:text-2xl cursor-default lg:cursor-pointer'>Click Here to Select Files to Upload</p>
            <p className="text-xs text-center leading-5 text-indigo-700 font-bold">
                Only Audio and Video Files are Allowed
            </p>
            </>
            )
              }{pretranscriptedFiles.length>0 && !isLoading && <p className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer'>Click Here to Reselect files to upload</p>}
            </div>
            <aside>
            {pretranscriptedFiles.map(file => (
              <p className={`border py-3 px-2 mb-2 rounded-md text-sm ${isLoading?'animate-pulse':'animate-none'}`} key={file.path}>
                {file.path} - {convertBytesToMB(file.size)} MB
              </p>
            ))}
            </aside>
          </div>
            {!isLoading && <button type="button" onClick={handlePreTranscriptedFileUpload} className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">Upload</button>}
            {isLoading &&
              <button type="button" disabled className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 focus:outline-none'>
                <svg className="animate-spin mr-2 h-5 w-4 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} /><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /></svg>
                Uploading... {progress>0 && progress+'%'}
              </button>
            }
          </div>,
    },
    {
        id: 2,
        title: "Post-Transcripted Files",
        tooltip: "Transcripted TXT/JSON Files",
        content: <div  className='space-y-4 px-2 pt-5'>
        <div className={`flex flex-col w-full h-[calc(100vh-14rem)] overflow-auto rounded-md bg-white border-2 border-dashed border-blue-900 ${(posttranscriptedFiles.length<1)?'justify-center items-center p-0':'justify-start items-start p-4 space-y-2'}`}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {posttranscriptedFiles.length<1 && (
            <>
            <p className='text-sm md:text-2xl cursor-default lg:cursor-pointer'>Click Here to Select Files to Upload</p>
            <p className="text-xs text-center leading-5 text-indigo-700 font-bold">
                Only TXT and JSON Files are Allowed
            </p>
            </>
            )}
            {posttranscriptedFiles.length>0 && !ispostLoading && <p className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer'>Click Here to Reselect files to upload</p>}
          </div>
          <aside>
          {posttranscriptedFiles.map(file => (
            <p className={`border py-3 px-2 mb-2 rounded-md text-sm ${ispostLoading?'animate-pulse':'animate-none'}`} key={file.path}>
              {file.path} - {convertBytesToMB(file.size)} MB
            </p>
          ))}
          </aside>
        </div>
          {!ispostLoading && <button type="button" onClick={handlePostTranscriptedFileUpload} className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">Upload</button>}
          {ispostLoading &&
            <button type="button" disabled className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 focus:outline-none'>
              <svg className="animate-spin mr-2 h-5 w-4 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} /><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /></svg>
              Uploading... {progress>0 && progress+'%'}
            </button>
          }
        </div>,
    },
    {
      id: 3,
      title: "Post-Raw Answers Files",
      tooltip: "Raw Answers TXT/JSON Files",
      content: <div  className='space-y-4 px-2 pt-5'>
       <div>
          <select
              id="qdb"
              name="qdb"
              className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              onChange={(e) => setSelectedQDB(e.target.value)}
          >
              <option value="">Select Question DB</option>
              {qdbs.map((qdb, index) => (
                  <option
                      key={index}
                      value={qdb.qdb_code}
                  >
                      {qdb.qdb_code} - {qdb.qdb_type==='M'?'Multiple':'Single'}
                  </option>
              ))}
          </select>
      </div>
      <div className={`flex flex-col w-full h-[calc(100vh-17.5rem)] overflow-auto rounded-md bg-white border-2 border-dashed border-blue-900 ${(postrawFiles.length<1)?'justify-center items-center p-0':'justify-start items-start p-4 space-y-2'}`}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {postrawFiles.length<1 && (
          <>
          <p className='text-sm md:text-2xl cursor-default lg:cursor-pointer'>Click Here to Select Files to Upload</p>
          <p className="text-xs text-center leading-5 text-indigo-700 font-bold">
              Only TXT and JSON Files are Allowed
          </p>
          </>
          )}
          {postrawFiles.length>0 && !ispostRawLoading && <p className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer'>Click Here to Reselect files to upload</p>}
        </div>
        <aside>
        {postrawFiles.map(file => (
          <p className={`border py-3 px-2 mb-2 rounded-md text-sm ${ispostRawLoading?'animate-pulse':'animate-none'}`} key={file.path}>
            {file.path} - {convertBytesToMB(file.size)} MB
          </p>
        ))}
        </aside>
      </div>
        {!ispostRawLoading && <button type="button" onClick={handlePostRawAnswersFileUpload} className="inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">Upload</button>}
        {ispostRawLoading &&
          <button type="button" disabled className='inline-flex justify-center tracking-wider rounded-lg border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 focus:outline-none'>
            <svg className="animate-spin mr-2 h-5 w-4 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} /><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /></svg>
            Uploading... {progress>0 && progress+'%'}
          </button>
        }
      </div>,
    }
  ];

  return (
    <div>
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <button type="button" onClick={openModal} className="inline-flex justify-center  border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">Add Files</button>
      <ReadModal isOpen={isOpen} closeModal={closeModal} title='Add Files'
        modalInputs={
          <AddFilesTabs tabs={tabs} handleSelectedState={handleSelectedState}/>
        } 
      />   
    </div>
  )
}

export default AddFiles