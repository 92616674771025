import React, { useCallback, useEffect, useState } from 'react';
import ReadModal from './ReadModal';
import { ArrowDownCircleIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { handleApiError } from './APIUtils';
import axios from 'axios';
import { API_URL } from "../../imports";
import ConfirmationPopup from './ConfirmationPopup';
import Popup from './Popup';
import QDBTable from './QDBTable';
import { generateProcessCodeFileNameAlreadyDTLog } from './CommonUtils';

const LogTable = ({ data, filename, process_code }) => {

  let [dataItems, setDataItems] = useState([]);

  useEffect(()=>{
    setDataItems(data);
  },[data])

  let [expandedColumns, setExpandedColumns] = useState([]);

  const toggleColumn = (column) => {
    if (expandedColumns.includes(column)) {
      // If column is already selected, remove it
      setExpandedColumns(expandedColumns.filter((col) => col !== column));
    } else {
      // If column is not selected, add it
      setExpandedColumns([...expandedColumns, column]);
    }
  };

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [QDBResponseState, setQDBResponseState] = useState(false);

  const [selectedRows, setSelectedRows] = useState({});
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [id]: !prevSelectedRows[id],
    }));
  };

  useEffect(()=>{
    const allRows = Object.values(dataItems).every((isSelected) => isSelected);
    const updateSelectedRowsState = {};
    dataItems.forEach((item) => {
      updateSelectedRowsState[item.id] = !allRows;
    });
    setSelectedRows(updateSelectedRowsState);
  },[dataItems])

  const handleSelectAll = () => {
    const allSelected = Object.values(selectedRows).every((isSelected) => isSelected);
    const newSelectedRows = {};
    dataItems.forEach((item) => {
      newSelectedRows[item.id] = !allSelected;
    });
    setSelectedRows(newSelectedRows);
  };

  function closePopup() {
      setIsOpenPopup(false);
  }

  const openPopup = useCallback(() => {
      setIsOpenPopup(true);
  }, [setIsOpenPopup]);

  let [isOpenRead, setIsOpenRead] = useState(false);
  let [modalTitle, setModalTitle] = useState('');
  let [jsonData, setJsonData] = useState(null);
  let [commentState, setCommentState] = useState(false);
  let [showComment, setShowComment] = useState(false);
  let [comment, setComment] = useState('');

  function closeReadModal() {
    setIsOpenRead(false);
  }

  const SaveComment = async () => {
    axios.put(
            `${API_URL}/Transcription/comment/${sessionStorage.getItem("projectCode")}`,
            {id: rId, remark: comment},
            {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}
        )
        .then((response) => {
          setShowComment(false);
          // Updates the table row with the comment added to that particular id
          setDataItems((prevData) => {
            // Use map to update the specific item where rId matches id
            const newData = prevData.map((item) => {
              if (item['id'] === rId) {
                // Update the Comment with the comment variable
                return { ...item, Comment: comment };
              }
              return item;
            });
          
            return newData;
          });
        })
        .catch((error) => {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        });
  };

  

  const handleCommentSaveEnterKey = (event) => {
    if (event.key === 'Enter') {
      SaveComment();
    }
  };

  // const ClearComment = async () => {
  //   axios.put(
  //           `${API_URL}/Transcription/comment/${sessionStorage.getItem("projectCode")}`,
  //           {id: rId, remark: null},
  //           {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}
  //       )
  //       .then((response) => {
  //         setComment('');
  //         setShowComment(false);
  //         // Updates the table row with the comment deleted to that particular id
  //         setDataItems((prevData) => {
  //           // Use map to update the specific item where rId matches id
  //           const newData = prevData.map((item) => {
  //             if (item['id'] === rId) {
  //               // Update the Comment with the comment variable
  //               return { ...item, Comment: null };
  //             }
  //             return item;
  //           });
          
  //           return newData;
  //         });
  //       })
  //       .catch((error) => {
  //           const errorMessage = handleApiError(error);
  //           setMsg(errorMessage);
  //           setIsSuccess(false);
  //           openPopup();
  //       });
  // };

  const ParamsData = useCallback(async (param_code) => {
    axios.post(
            `${API_URL}/params`,
            {project_code: `${sessionStorage.getItem("projectCode")}`, param_code: param_code},
            {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}
        )
        .then((response) => {
            setJsonData(response.data.data.params);
        })
        .catch((error) => {
            const errorMessage = handleApiError(error);
            if(errorMessage){
              setJsonData(null);
            }
        });
  }, []);

  const QuestionDBData = useCallback(async (qdb_code) => {
    axios.post(
            `${API_URL}/qdb`,
            {project_code: `${sessionStorage.getItem("projectCode")}`, qdb_code: qdb_code},
            {headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}}
        )
        .then((response) => {
            setJsonData(response.data.data.qdb);
        })
        .catch((error) => {
            const errorMessage = handleApiError(error);
            if(errorMessage){
              setJsonData(null);
            }
        });
  }, []);

  const JsonDownloadButton = ({ content, JSONfileName, disabledState }) => {
    const downloadJson = () => {
        const blob = new Blob([JSON.stringify(content)], {
            type: "application/json",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = JSONfileName + ".json";
        link.click();
    };

    return (
        <button
            className="p-1 rounded-md border disabled:text-gray-300"
            onClick={downloadJson}
            disabled={disabledState}
        >
            <p className="flex gap-1" title="json file download">
                <ArrowDownCircleIcon className="h-5" />
            </p>
        </button>
    );
};

  function openReadModal(e, option, id) {
    setJsonData(null);
    setCommentState(false);
    if(option==='response'){
      setQDBResponseState(false);
      setJsonData(e);
      if(process_code==='TRANSCRIPT'){
        setShowComment(false);
        // Find the item with the specified id
        const selectedItemId = dataItems.find((item) => item.id === id);
        // Check if the item is found
        if (selectedItemId) {
          const idcomment = selectedItemId.Comment;
          setComment(idcomment || '');
        }
        setrID(id);
        setCommentState(true);
      }
      setModalTitle('Response View');
    }
    else if(option==='params'){
      setQDBResponseState(false);
      setModalTitle(`${e} Param`);
      ParamsData(e);
    }
    else if(option==='questiondb'){
      setQDBResponseState(true);
      setModalTitle(`${e}`);
      QuestionDBData(e);
    }
    setIsOpenRead(true);
  }

    
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [rId, setrID] = useState('');
  let [deleteSelected, setDeleteSelected] = useState(false);

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(id_prop, option) {
    if(option==='single'){
      setDeleteSelected(false);
      setrID(id_prop);
      setConfirmationMsg("Are you sure that you want to delete this entry?");
    } else if(option==='multiple'){
      setDeleteSelected(true);
      setConfirmationMsg("Are you sure that you want to delete these selected entries?");
    }
    setIsOpenConfirmPopup(true);
  }

  const handleMultipleDeleteState = async () => {
    // Get the ids where the value is true
    const selectedIds = Object.keys(selectedRows).filter((id) => selectedRows[id]);
    setIsOpenConfirmPopup(false);
    try {
        const response = await axios.delete(`${API_URL}/entries/selected`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params: {
                process: process_code,
                ids: selectedIds.join(','),
            },
        });
        setMsg(response.data.message);
        setDataItems((prevData) => {
          // Filter out the selected IDs with Finalized value as true
          const selectedFinalizedIds = prevData.filter((item) => selectedIds.includes(item.id.toString()) && item.Finalized).map((item) => item.id.toString());
          // Get the IDs of selected unfinalized items
          const selectedUnfinalizedIds = selectedIds.filter((id) => !selectedFinalizedIds.includes(id));
          // Filter out items with Finalized value as true
          const newData = prevData.filter((item) => !selectedUnfinalizedIds.includes(item.id.toString()));
          return newData;
        });
        setIsSuccess(true);
        openPopup();
    } catch (error) {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
    }
  };

    const handleDeleteState = async () => {
        setIsOpenConfirmPopup(false);
        try {
            const response = await axios.delete(`${API_URL}/entries`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
                params: {
                    process: process_code,
                    id: rId
                },
            });
            setDataItems((prevData) => {
              const newData = prevData.filter(
                  (item) => item['id'] !== rId
              );
              return newData;
            });
            setMsg(response.data.message);
            setIsSuccess(true);
            openPopup();
        } catch (error) {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        }
    };


  if (!dataItems || dataItems.length === 0) {
    return <div>No data to display.</div>;
  }

  const columns = Object.keys(dataItems[0]);

  const renderTableCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'boolean') {
      return value ? '1' : '';
    }
    return value;
  };

  const renderResponseCell = (value, filenameprop, creation_date, id) => {
      let jsonFileName = `${generateProcessCodeFileNameAlreadyDTLog(filenameprop, creation_date)}`;
      return (
        <div className="flex gap-2 items-center justify-center">
          <button  className="p-1 rounded-md border disabled:text-gray-200 text-green-800"  onClick={() =>openReadModal(value, 'response', id)}>
              <EyeIcon className="h-4" />
          </button>
          <JsonDownloadButton content={value} JSONfileName={jsonFileName} disabledState={value ? false : true}/>
          <button  className="p-1 rounded-md border text-red-600"  onClick={()=>openConfirmPopup(id, 'single')}>
              <TrashIcon className="h-4" />
          </button>
        </div>
      )
  };



  return (
  <div>
             <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={deleteSelected?handleMultipleDeleteState:handleDeleteState}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
    <ReadModal
          isOpen={isOpenRead}
          closeModal={closeReadModal}
          title={modalTitle}
          modalInputs={
            <>
            <div className={`overflow-auto ${commentState?'-mt-2 h-[calc(100vh-10rem)] md:h-[calc(100vh-10rem)]':'h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]'}`}>
              {jsonData && (QDBResponseState ? (<QDBTable data={jsonData} />) : (<pre className="pretxtwrap text-sm">{JSON.stringify(jsonData, null, 2)}</pre>))}
              {!jsonData && 'No Response Found.'}
            </div>
            {jsonData && commentState && 
              <div className='absolute bottom-0 w-full left-0 rounded-b-lg bg-gray-100 p-2.5 border-t flex gap-2'>
                {!showComment && <button type="button" className="inline-flex tracking-wider justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer" onClick={()=>setShowComment(true)}>Comment</button>}
                {showComment && <><input type="text" autoFocus className='block w-full rounded-md border border-gray-400 py-1.5 ring-0 sm:text-sm sm:leading-6' value={comment} onChange={(e) =>setComment(e.target.value)} onKeyDown={handleCommentSaveEnterKey}/><button type="button" className="inline-flex tracking-wider justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer" onClick={()=>SaveComment()}>Save</button> <button type="button" className="inline-flex tracking-wider justify-center rounded-lg border border-gray-400 bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer" onClick={()=>setComment('')}>Clear</button></>}
              </div>
            }
            </>
          }
    />
    <table className="table-auto border-collapse">
      <thead>
        <tr className='bg-gray-200'>
        <th className="border px-4 py-2">
              <input
                type="checkbox"
                checked={Object.values(selectedRows).every((isSelected) => isSelected)}
                onChange={handleSelectAll}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </th>
          <th className="border px-4 py-2">
            #
          </th>
          {columns.map((column, index) => (
            <th key={index} className={`border px-4 py-2 whitespace-nowrap cursor-default lg:cursor-pointer ${column==='id' && 'hidden'}`} title="Click on the title to expand the column" onClick={() => toggleColumn(column)}>
              {column!=='Response' && column!=='id' && column} 
              {column==='Response' && <p className='text-center'>Actions</p>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataItems.map((item, index) => (
          <tr key={index} className='hover:bg-gray-100'>
            <td className="border px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedRows[item.id] || false}
                  onChange={() => handleCheckboxChange(item.id)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </td>
            <td className="border px-4 py-2">
                {index+1}
            </td>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex} className={`border px-4 py-2 ${column==='id' && 'hidden'}`}>
                { column==='Status Message' && item['Status Message']!== null? (<div title={item[column]} className={`${expandedColumns.includes(column)?'w-auto':'max-w-xs truncate'}`}>{item[column]}</div>) : null}
                { column==='Comment' && item['Comment']!== null? (<div title={item[column]}  className={`${expandedColumns.includes(column)?'w-auto':'max-w-xs truncate'}`}>{item[column]}</div>) : null}
                { column!=='Status Message' && column!=='Comment' && column!=='Params' && column!=='QuestionDB' && column!=='id' && <div className={column==='Processed By'?'capitalize':null}>{typeof item[column]!== 'object' && <div className={`${expandedColumns.includes(column)?'w-auto':'max-w-xs truncate'}`}>{renderTableCell(item[column])}</div>}{column==='Response' && renderResponseCell(item[column], filename?.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", ""),  item['Execution Date'], item['id'])}</div> }
                { column==='Params' && <div onClick={()=>openReadModal(item[column],'params',item['id'])} title='Click to get Params' className={`text-blue-600 cursor-default lg:cursor-pointer ${expandedColumns.includes(column)?'w-auto':'max-w-xs truncate'}`}>{renderTableCell(item[column])}</div>}
                { column==='QuestionDB' && <div onClick={()=>openReadModal(item[column],'questiondb',item['id'])} title='Click to get QuestionDB' className={`text-blue-600 cursor-default lg:cursor-pointer ${expandedColumns.includes(column)?'w-auto':'max-w-xs truncate'}`}>{renderTableCell(item[column])}</div>}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {Object.values(selectedRows).some((isSelected) => isSelected) && (
      <tfoot>
        <tr className='border'>
          <td colSpan={5}>
      <button className="rounded-md cursor-default lg:cursor-pointer m-3 p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={()=>openConfirmPopup(null,'multiple')}>
        Delete selected
      </button>
    </td>
    </tr>
      </tfoot>
    )}
    </table>
  </div>
  );
};

export default LogTable;
