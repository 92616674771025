import React from 'react'
import Login from './components/Auth/Login';

function App() {
  return (
    <div className='font-GoogleSans  tracking-wider select-none'>
      <div className='w-full flex justify-center items-center h-screen p-10'><Login/></div>
    </div>
  );
}

export default App;
