import React from "react";

function NoMatchFound() {
  return (
    <div className="px-2">
      No Matching Record Found.
    </div>
  );
}

export default NoMatchFound;
