import React, { useEffect, useState, Fragment, useRef, useCallback } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {
    ArrowPathIcon,
    PencilIcon,
    TrashIcon,
    PlusCircleIcon,
    EyeIcon,
    ArrowDownTrayIcon,
    EllipsisHorizontalIcon,
    DocumentDuplicateIcon
} from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import { initialpageSize } from "../Common/Contants";
import InputModal from "../Common/InputModal";
import Popup from "../Common/Popup";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput } from "../Common/Filters";
import ReadModal from "../Common/ReadModal";
import { handleApiError } from "../Common/APIUtils";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import { formattedDate } from "../Common/CommonUtils";
import { Menu, Transition } from "@headlessui/react";
import { Portal } from "react-portal";

export default function Params() {
    let [isOpen, setIsOpen] = useState(false);
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [edit, setEdit] = useState(false);
    let [show, setShow] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
    const [activeDropdown, setActiveDropdown] = useState(null);
    const buttonRefs = useRef({});
    const dropdownRef = useRef(null);

    const calculatePosition = useCallback((id) => {
        const buttonRef = buttonRefs.current[id];
        if (buttonRef) {
            const rect = buttonRef.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
            });
            setActiveDropdown((prev) => (prev === id ? null : id));
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setActiveDropdown(null);
          }
        };
        const handleKeyDown = (event) => {
          const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
          if (keysToCloseDropdown.includes(event.key)) {
            setActiveDropdown(null);
          }
        };
        const handleScroll = () => {
          setActiveDropdown(null);
        };
        if (activeDropdown !== null) {
          document.addEventListener("mousedown", handleClickOutside);
          document.addEventListener("keydown", handleKeyDown);
          window.addEventListener("scroll", handleScroll, true);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("keydown", handleKeyDown);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, [activeDropdown]);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    function closeModal() {
        setIsOpen(false);
        setRowData([]);
        setFiles([]);
    }

    function openModal(e, option) {
        setRowData(e);
        if (option === "edit") {
            setEdit(true);
        } else if (option === "create") {
            setEdit(false);
        }
        setIsOpen(true);
    }

    function closeReadModal() {
        setIsOpenRead(false);
    }

    function openReadModal(e) {
        setRowData(e);
        setModalTitle(`Parameters Response of ${e.param_code}`);
        setIsOpenRead(true);
    }

    const ReloadData = async () => {
        axios
            .get(`${API_URL}/params/${sessionStorage.getItem("projectCode")}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const [files, setFiles] = useState([]);

    const acceptedFileTypes = {
        "application/json": [".json"],
    };

    const maxFileSize = {
        ".json": 30 * 1024 * 1024, // 30MB
    };

    function getFileExtension(filename) {
        return "." + filename.split(".").pop().toLowerCase();
    }

    function handleFileChange(acceptedFiles) {
        const validatedFiles = acceptedFiles.filter((file) => {
            const fileExtension = getFileExtension(file.name);
            const fileSize = file.size;

            if (!acceptedFileTypes[file.type]?.includes(fileExtension)) {
                setMsg("Invalid file type. Only json files are allowed.");
                setIsSuccess(false);
                openPopup();
                return false;
            }

            if (fileSize > maxFileSize[fileExtension]) {
                setMsg(
                    `File size exceeds the limit for ${fileExtension} files.`
                );
                setIsSuccess(false);
                openPopup();
                return false;
            }

            return true;
        });

        setFiles(validatedFiles);
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFileTypes,
        maxSize: Math.max(...Object.values(maxFileSize)),
        onDrop: handleFileChange,
        multiple: false,
    });

    const emptyFilesState = () => {
        setFiles([]);
    };

    const responseStateHandler = (responseMessage, responseState) => {
        emptyFilesState();
        setMsg(responseMessage);
        setIsSuccess(responseState);
        openPopup();
    };

    function convertBytesToMB(bytes) {
        if (bytes === 0) {
            return "0 KB";
        } else if (bytes < 1024) {
            return `${bytes} Bytes`;
        } else if (bytes >= 1024 && bytes < 1024 * 1024) {
            const kilobytes = bytes / 1024;
            return kilobytes.toFixed(2) + " KB";
        } else {
            const megabytes = bytes / (1024 * 1024);
            return megabytes.toFixed(2) + " MB";
        }
    }
    const [processes, setProcesses] = useState([]);
    const [selectedProcess, setSelectedProcess] = useState("");
    useEffect(() => {
        axios
            .get(`${API_URL}/processes`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setProcesses(response.data.data);
                if (response.data.data.length === 0) {
                    setShow(false);
                }
                else {
                    setShow(true);
                }
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setShow(false);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    }, []);

    const handleCreateState = async () => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("file", files[0]);
            formData.append("process", selectedProcess);
            setIsOpen(false);
            try {
                await axios
                    .post(
                        `${API_URL}/params/${sessionStorage.getItem(
                            "projectCode"
                        )}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Accept: "application/json",
                                Authorization: `Bearer ${localStorage.getItem(
                                    "access_token"
                                )}`,
                            },
                        }
                    )
                    .then((response) => {
                        responseStateHandler(response.data.message, true);
                        ReloadData();
                    });
            } catch (error) {
                const errorMessage = handleApiError(error);
                responseStateHandler(errorMessage, false);
            }
        } else {
            responseStateHandler("No Files are Uploaded", false);
        }
    };

    const handleEditState = async () => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("file", files[0]);
            setIsOpen(false);
            try {
                await axios
                    .patch(`${API_URL}/params/${sessionStorage.getItem("projectCode")}/${rowData.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    })
                    .then((response) => {
                        responseStateHandler(response.data.message, true);
                        ReloadData();
                    });
            } catch (error) {
                const errorMessage = handleApiError(error);
                responseStateHandler(errorMessage, false);
            }
        } else {
            responseStateHandler("No Files are Uploaded", false);
        }
    };

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState("");

    function closeStatusModal() {
        setIsOpenStatusModal(false);
    }

    function openStatusModal(e) {
        setRowData(e);
        setModalTitle(`Update Project Status - ${e.project_code}`);
        setIsOpenStatusModal(true);
    }

    const handleStatusState = async () => {
        let statusData = {
            status:
                rowData.status === false || rowData.status === "false"
                    ? false
                    : true,
        };
        setIsOpenStatusModal(false);
        try {
            await axios
                .patch(`${API_URL}/params/status/${sessionStorage.getItem("projectCode")}/${rowData.id}`, statusData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    function openConfirmPopup(e) {
        setRowData(e);
        setConfirmationMsg("Are you sure that you want to delete parameters?");
        setIsOpenConfirmPopup(true);
    }

    const handleDeleteState = async () => {
        setIsOpenConfirmPopup(false);
        try {
            await axios
                .delete(`${API_URL}/params/${sessionStorage.getItem("projectCode")}/${rowData.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const JsonDownloadButton = ({ disabled, content, fileName }) => {
        const downloadJson = () => {
            const blob = new Blob([JSON.stringify(content)], { type: 'application/json' });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".json";
            link.click();
        };

        return (
            <button
                className="disabled:text-gray-300 items-center hover:bg-gray-200 hover:text-blue-600 flex w-full text-sm"
                disabled={disabled}
                onClick={downloadJson}
            >
                <p className="flex items-center px-4 py-2 w-full text-sm">
                    <ArrowDownTrayIcon className="h-4 text-blue-600 mr-2" />
                    <span>Download</span>
                </p>
            </button>
        );
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setMsg('Text copied to clipboard');
                setIsSuccess(true);
                setIsOpenPopup(true);
            },
            (err) => {
                setMsg('Failed to copy text', err);
                setIsSuccess(false);
                setIsOpenPopup(true);
            }
        );
    };

    const [sorting, setSorting] = React.useState([]);


    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 40,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "process_code",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>Process Code</span>,
            },
            {
                accessorKey: "param_code",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>Param Code</span>,
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 300,
                header: "Created Date",
                cell: ({ row }) => (
                    <div>{formattedDate(row.original.creation_date)}</div>
                ),
            },
            {
                accessorKey: "creation_by",
                enableColumnFilter: false,
                size: 50,
                header: "Created By",
                cell: ({ row }) => (
                    <span className="capitalize" title={row.original.creation_by}>
                        {row.original.creation_by !== null
                            ? row.original.creation_by.slice(0, 12) +
                            (row.original.creation_by.length > 12
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            onClick={() => openStatusModal(row.original)}
                            className={`${row.original.status === true
                                ? "bg-green-300  text-green-900"
                                : "bg-red-300 text-red-900"
                                } text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
                        >
                            {row.original.status === true
                                ? "Active"
                                : "Inactive"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <Menu as="div">
                            <div ref={(el) => (buttonRefs.current[row.original.id] = el)}>
                                <Menu.Button className="relative border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                calculatePosition(row.original.id)
                                            }
                                            }
                                >
                                    <EllipsisHorizontalIcon className="h-4 text-black" />
                                </Menu.Button>
                            </div>
                            <Portal>
                            <Transition
                                as={Fragment}
                                show={activeDropdown === row.original.id}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-50 right-8 md:right-24 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            style={{
                                                top: `${dropdownPosition.top}px`,
                                            }}
                                            ref={dropdownRef}
                                >
                                    <button
                                        className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-green-800 w-full text-sm"
                                        onClick={() => {
                                            openReadModal(row.original);
                                            setActiveDropdown(null);
                                        }}
                                        
                                    >
                                        <EyeIcon className="h-4 text-green-800 mr-2" />
                                        <span>Show</span>
                                    </button>
                                    <button
                                        className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-green-800 w-full text-sm"
                                        onClick={() => {
                                            copyToClipboard(JSON.stringify(row.original.params));
                                            setActiveDropdown(null);
                                        }}
                                    >
                                        <DocumentDuplicateIcon className="h-4 text-green-800 mr-2" />
                                        <span>Copy</span>
                                    </button>
                                    <button
                                        className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-blue-800  w-full text-sm"
                                        onClick={() => {
                                            openModal(row.original, "edit");
                                            setActiveDropdown(null);
                                        }}
                                    >
                                        <PencilIcon className="h-4 text-blue-800 mr-2" />
                                        <span>Edit</span>
                                    </button>
                                    <JsonDownloadButton
                                        disabled={row.original.params ? false : true}
                                        content={row.original.params}
                                        fileName={row.original.param_code}
                                        onClick={() => {
                                            setActiveDropdown(null);
                                        }}
                                    />
                                    <button
                                        className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-red-800  w-full text-sm"
                                        onClick={() => {
                                            openConfirmPopup(row.original);
                                            setActiveDropdown(null);
                                        }}
                                    >
                                        <TrashIcon className="h-4 text-red-600 mr-2" />
                                        <span>Delete</span>
                                    </button>
                                </Menu.Items>
                            </Transition>
                            </Portal>
                        </Menu>
                    </div>
                ),
            },
        ],
        [activeDropdown, calculatePosition, dropdownPosition.top]
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();
        return (
            <input
                type="text"
                value={columnFilterValue}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(
                    `${API_URL}/params/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    setData(response.data.data);
                    if (response.data.data.length === 0) {
                        setShow(false);
                    }
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setShow(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setSelectedProcess('');
        }
    }, [isOpen]);

    const fileMap = {
        'TRANSCRIPT': '/downloads/transcript.json',
        'GETIDEAS': '/downloads/get_ideas.json',
        'REFINED': '/downloads/refined_answers.json',
        'RAW': '/downloads/raw_answers.json',
    };

    const downloadFile = (processCode) => {
        // Get the URL for the selected process
        const fileUrl = fileMap[processCode];

        // Check if the file URL exists for the selected process
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileUrl.split('/').pop();
            link.click();
        } else {
            console.error('No file mapped for this process.');
        }
    };

    return (
        <div className="border bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={handleDeleteState}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                        <pre className="pretxtwrap text-sm">
                            {JSON.stringify(rowData.params, null, 2)}
                        </pre>
                    </div>
                }
            />
            <InputModal
                isOpen={isOpen}
                closeModal={closeModal}
                title={
                    edit === false ? "Add Parameters" : "Overwrite Parameters"
                }
                savefn={edit === false ? handleCreateState : handleEditState}
                modalInputs={
                    <div className="space-y-5">
                        {!edit && (
                            <>
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Process
                                    </label>
                                    <select
                                        id="process"
                                        name="process"
                                        className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        onChange={(e) =>
                                            setSelectedProcess(e.target.value)
                                        }
                                    >
                                        <option value="">Select Process</option>
                                        {processes.slice(1).map((process, index) => (
                                            <option
                                                key={index}
                                                value={process.process_code}
                                            >
                                                {process.process_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        <div>
                            <label
                                htmlFor="cover-photo"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Parameters File
                            </label>
                            <div className="flex justify-center rounded-md border border-dashed border-gray-900 px-2 py-10">
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                >
                                    <input {...getInputProps()} />
                                    <div className="text-center">
                                        <div className="flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-0 focus-within:border-white focus-within:ring-whitefocus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>
                                                    {files.length < 1
                                                        ? "Upload"
                                                        : "Re-Upload"}{" "}
                                                    a file
                                                </span>
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">
                                            JSON up to 30MB
                                        </p>
                                        <aside>
                                            {files.map((file) => (
                                                <p
                                                    className="border py-3 px-2 mt-2 rounded-md text-sm"
                                                    key={file.path}
                                                >
                                                    {file.path} -{" "}
                                                    {convertBytesToMB(
                                                        file.size
                                                    )}
                                                </p>
                                            ))}
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedProcess ? (
                            <div className="text-sm">
                                Click here to download sample file:
                                <div
                                    className="text-blue-600 cursor-pointer text-sm"
                                    onClick={() => downloadFile(selectedProcess)}
                                >
                                    {processes.find(p => p.process_code === selectedProcess)?.process_name}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                }
            />
            <InputModal
                isOpen={isOpenStatusModal}
                closeModal={closeStatusModal}
                title={modalTitle}
                savefn={handleStatusState}
                modalInputs={
                    <div className="flex gap-5">
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="true"
                                checked={
                                    rowData.status === true ||
                                    rowData.status === "true"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-everything"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Active
                            </label>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="false"
                                checked={
                                    rowData.status === false ||
                                    rowData.status === "false"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Inactive
                            </label>
                        </div>
                    </div>
                }
            />
            <div className="flex justify-between m-3 gap-2">
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className="flex gap-2">
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => openModal("e", "create")}
                    >
                        <PlusCircleIcon className="h-5" />
                    </button>
                </div>
            </div>
            {show && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{ width: header.getSize() }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header.column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={table}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{ height: `${paddingTop}px` }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr key={row.id}>
                                                            {row.getVisibleCells().map((cell) => {
                                                                return (
                                                                    <td
                                                                        className="border p-2 whitespace-nowrap"
                                                                        key={cell.id}
                                                                    >
                                                                        {flexRender(
                                                                            cell.column.columnDef.cell,
                                                                            cell.getContext()
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{ height: `${paddingBottom}px` }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        rows.length > 0 &&
                        (
                            <div className="flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3">
                                <nav
                                    className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900"
                                    aria-label="Pagination"
                                >
                                    <button
                                        title="First Page"
                                        onClick={() => setPageIndex(0)}
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button
                                        title="Previous Page"
                                        onClick={() => setPageIndex(pageIndex - 1)}
                                        disabled={pageIndex === 0}
                                        aria-current="page"
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {pageNumbers.length === 1 &&
                                        <button
                                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                            key={1}
                                            onClick={() => setPageIndex(0)}
                                        >
                                            {1}
                                        </button>
                                    }
                                    {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                                        if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                            if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber - 1)}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(0)}
                                                    >
                                                        {1}
                                                    </button>
                                                );
                                            }
                                        } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                            return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                        }
                                        return null;
                                    })}
                                    <button
                                        title="Next Page"
                                        onClick={() => setPageIndex(pageIndex + 1)}
                                        disabled={pageIndex === table.getPageCount() - 1}
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button
                                        title="Last Page"
                                        onClick={() => setPageIndex(table.getPageCount() - 1)}
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </nav>
                                <PageSizeDropdown
                                    dataLength={table.getPrePaginationRowModel().rows.length}
                                    pageSize={pageSize}
                                    handlePageChange={handlePageChange}
                                    totalRows={table.getPrePaginationRowModel().rows.length}
                                />
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
}
