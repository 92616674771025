import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useMemo, useState } from "react";

export const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                ref={resolvedRef}
                {...rest}
            />
        );
    }
);

export function SelectTextColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows?.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            className="form-select"
            style={{
                fontSize: "13px",
                boxShadow: "0px 0px",
            }}
            value={filterValue !== undefined ? filterValue : ""}
            onChange={(e) => {
                setFilter(e.target.value !== "" ? e.target.value : undefined);
            }}
        >
            <option value="">All</option>
            {options?.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// A debounced input react component
export function SelectTextOptions({
    value: initialValue,
    onChange,
    debounce = 5000000,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    options,
    ...props
}) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = React.useCallback(
        (e) => {
            const inputValue = e.target.value;
            if (new RegExp(pattern).test(inputValue)) {
                setValue(inputValue);
                onChange(inputValue);
            }
        },
        [pattern, onChange]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <select
            {...props}
            className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={value}
            onChange={handleInputChange}
        >
            {options.map((value) => (
                <option
                    value={value === null ? "" : value}
                    key={value === null ? 0 : value}
                >
                    {value === null ? "All" : value}
                </option>
            ))}
        </select>
    );
}

export function SelectTextFilter({ column }) {
    const [ls, setls] = useState(null);

    useEffect(() => {
        setls(column.getFilterValue());
    }, [column]);

    return (
        <SelectTextOptions
            value={ls ?? ""}
            onChange={(value) => column.setFilterValue(value)}
            options={Array.from(column.getFacetedUniqueValues().keys())
                .sort()
                .reverse()}
        />
    );
}

export function SelectTextOptionswithall({
    value: initialValue,
    onChange,
    debounce = 5000000,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    options,
    newValue,
    ...props
  }) {
    const [value, setValue] = React.useState(initialValue);
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    React.useEffect(()=>{
      setValue('');
    },[newValue])
  
    const handleInputChange = React.useCallback(
      (e) => {
        const inputValue = e.target.value;
        if (new RegExp(pattern).test(inputValue)) {
          setValue(inputValue);
          onChange(inputValue);
        }
      },
      [pattern, onChange]
    );
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);
  
      return () => clearTimeout(timeout);
    }, [value, debounce, onChange, initialValue]);
  
    return (
    <select {...props} className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm" value={value} onChange={handleInputChange}>
        <option value='' key='0'>All</option>
        {options?.map((value, index) => value && (
            <option value={value} key={index}>{value}</option>
        ))}
    </select>
    )
}

export function SearchAndSelectTextOptions({
    value: initialValue,
    onChange,
    debounce = 500,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    options,
    newValue,
    noDataFoundMessage = "Nothing found",
    ...props
  }) {
    const [value, setValue] = React.useState(initialValue);
    const [query, setQuery] = React.useState('');
  
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    React.useEffect(()=>{
        setValue('');
    },[newValue])
  
    const handleInputChange = React.useCallback(
      (e) => {
        const inputValue = e;
        if (new RegExp(pattern).test(inputValue)) {
          setValue(inputValue);
          onChange(inputValue);
        }
      },
      [pattern, onChange]
    );
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);
  
      return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);
  
    const filteredItems = query === ''
      ? options
      : options.filter((item) =>
            item && item.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );
  
    return (
      <div className="w-full">
        <Combobox {...props} value={value} onChange={handleInputChange}>
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left border border-gray-300 p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-sky-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-1 pl-2.5 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(item) => item || 'All'}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="absolute z-20 mt-1 max-h-48 md:max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-0 border border-gray-300 shadow-lg focus:outline-none focus:ring-0 sm:text-sm">
                {filteredItems.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-1 text-gray-700">
                    {noDataFoundMessage}
                  </div>
                ) : (
                  <>
                  <Combobox.Option
                      className={({ active }) =>
                        `relative cursor-default select-none py-1 pl-10 pr-4 ${
                          active ? 'bg-sky-700 text-white' : 'text-gray-900'
                        }`
                      }
                      value={''}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block whitespace-pre-wrap text-xs md:text-sm ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            All
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-sky-700'
                              }`}
                            >
                              <CheckIcon
                                className="h-4 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  {filteredItems.filter(item => item && item.trim() !== '').map((item, index) => (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-1 pl-10 pr-4 ${
                          active ? 'bg-sky-700 text-white' : 'text-gray-900'
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block whitespace-pre-wrap break-words text-xs md:text-sm ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {item}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-sky-700'
                              }`}
                            >
                              <CheckIcon
                                className="h-4 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                  </>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    );
}

export function SelectTextFilterWithAllOption({ column }) {
    const [ls, setls] = useState(null);

    useEffect(() => {
        setls(column.getFilterValue());
    }, [column]);

    const uniqueValues = Array.from(column.getFacetedUniqueValues().keys()).sort().reverse();

    return (
        <SearchAndSelectTextOptions
            value={ls ?? ""}
            onChange={(value) => column.setFilterValue(value)}
            options={uniqueValues}
        />
    );
}

export function SelectSelectedClientBasedTextFilter({ column, data, clname, setclname }) {

    const [ls, setls]=useState(null);
    const [cls, setcls]=useState(clname);
    const [newV, setnewV]=useState(false);

    useEffect(()=>{
        setls(column.getFilterValue());
    },[column])

    // Reset the filter for project_name and catg when clname changes
    useEffect(() => {
        if ( cls !== clname && (column.id === 'project_name' || column.id === 'proj_grp_code')) {
        if (ls !== '') {
            setls(''); // Reset local state
        }
        setnewV(!newV);
        column.setFilterValue(''); 
        setcls(clname); // Update the last client name 
        }
    }, [clname, cls, column, ls, newV]);

    // Dynamically generate options
    const options = useMemo(() => {
        if (column.id === 'project_name' || column.id === 'proj_grp_code') {
        if (clname!=='') {
            return [...new Set(data.filter(row => row?.client_name === clname).map(row => column.id === 'project_name' ? row.project_name : row.proj_grp_code))].sort().reverse();
        }
        return Array.from(column.getFacetedUniqueValues().keys()).sort().reverse();
        }
        return Array.from(column.getFacetedUniqueValues().keys()).sort().reverse();
    }, [clname, data, column]);


    return (
        <SearchAndSelectTextOptions
            value={ls ?? ''}
            onChange={(value) => {column.setFilterValue(value);if(column.id==='client_name'){setclname(value)}}}
            options={options}
            newValue={newV}
        />
    );
}

export function SelectStatusInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }) {
    const [value, setValue] = useState(initialValue);
  
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    const handleInputChange = (e) => {
      let inputVal;
      if(e.target.value===''){
        inputVal='';
      }
      else{
        inputVal = e.target.value === "true";
      }
      setValue(inputVal);
      onChange(inputVal);
    };
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);
  
      return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);
  
    return (
      <select
        {...props}
        className="block w-36 md:w-full font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
        value={value===undefined || value===''?'':value.toString()}
        onChange={handleInputChange}
      >
        <option value="">All</option>
        <option value="true">Active</option>
        <option value="false">Inactive</option>
      </select>
    );
}
  
export function StatusAdminFilter({ column }) {
const [value, setValue] = useState(column.getFilterValue());

useEffect(() => {
    setValue(column.getFilterValue());
}, [column]);

return <SelectStatusInput value={value} onChange={(value) => column.setFilterValue(value)} />;
}

// A debounced input react component
export function SelectDebouncedInput({
    value: initialValue,
    onChange,
    debounce = 5000000,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    ...props
}) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = React.useCallback(
        (e) => {
            const inputValue = e.target.value;
            if (new RegExp(pattern).test(inputValue)) {
                setValue(inputValue);
                onChange(inputValue);
            }
        },
        [pattern, onChange]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <select
            {...props}
            className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={value}
            onChange={handleInputChange}
        >
            <option value="" key="0">
                All
            </option>
            <option value="Unprocessed" key="1">
                Unprocessed
            </option>
            <option value="success" key="2">
                Success
            </option>
            <option value="error" key="3">
                Error
            </option>
        </select>
    );
}

export function StatusFilter({ column }) {
    const [ls, setls] = useState(null);

    useEffect(() => {
        setls(column.getFilterValue());
    }, [column]);

    return (
        <SelectDebouncedInput
            value={ls ?? ""}
            onChange={(value) => column.setFilterValue(value)}
        />
    );
}

export function SelectDebouncedLogInput({
    value: initialValue,
    onChange,
    debounce = 5000000,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    ...props
}) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = React.useCallback(
        (e) => {
            const inputValue = e.target.value;
            if (new RegExp(pattern).test(inputValue)) {
                setValue(inputValue);
                onChange(inputValue);
            }
        },
        [pattern, onChange]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <select
            {...props}
            className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={value}
            onChange={handleInputChange}
        >
            <option value="" key="0">
                All
            </option>
            <option value="success" key="1">
                Success
            </option>
            <option value="error" key="2">
                Error
            </option>
        </select>
    );
}

export function LogStatusFilter({ column }) {
    const [ls, setls] = useState(null);

    useEffect(() => {
        setls(column.getFilterValue());
    }, [column]);

    return (
        <SelectDebouncedLogInput
            value={ls ?? ""}
            onChange={(value) => column.setFilterValue(value)}
        />
    );
}

export function SelectDebouncedProjectStatusInput({
    value: initialValue,
    onChange,
    debounce = 5000000,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    ...props
}) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = React.useCallback(
        (e) => {
            const inputValue = e.target.value;
            if (new RegExp(pattern).test(inputValue)) {
                setValue(inputValue);
                onChange(inputValue);
            }
        },
        [pattern, onChange]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <select
            {...props}
            className="block w-36 md:w-full font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={value}
            onChange={handleInputChange}
        >
            <option value="" key="0">
                All
            </option>
            <option value={1} key="1">
                Open
            </option>
            <option value={0} key="2">
                Hold
            </option>
            <option value={2} key="3">
                Close
            </option>
        </select>
    );
}

export function ProjectStatusFilter({ column }) {
    const [ls, setls] = useState(null);

    useEffect(() => {
        setls(column.getFilterValue());
    }, [column]);

    return (
        <SelectDebouncedProjectStatusInput
            value={ls ?? ""}
            onChange={(value) => column.setFilterValue(value)}
        />
    );
}

export function SelectFinalizedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = (e) => {
        let inputVal;
        if (e.target.value === "") {
            inputVal = "";
        } else {
            inputVal = e.target.value === "true";
        }
        setValue(inputVal);
        onChange(inputVal);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <select
            {...props}
            className="block w-36 font-GoogleSans rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={value === undefined || value === "" ? "" : value.toString()}
            onChange={handleInputChange}
        >
            <option value="">All</option>
            <option value="true">Finalized</option>
            <option value="false">Unfinalized</option>
        </select>
    );
}

export function FinalizedFilter({ column }) {
    const [value, setValue] = useState(column.getFilterValue());

    useEffect(() => {
        setValue(column.getFilterValue());
    }, [column]);

    return (
        <SelectFinalizedInput
            value={value}
            onChange={(value) => column.setFilterValue(value)}
        />
    );
}

// A debounced input react component
export function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    pattern = "^[a-zA-Z0-9 !@#$^&*()-_]*$",
    ...props
}) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleInputChange = React.useCallback(
        (e) => {
            const inputValue = e.target.value;
            if (new RegExp(pattern).test(inputValue)) {
                setValue(inputValue);
                onChange(inputValue);
            }
        },
        [pattern, onChange]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value, debounce, onChange]);

    return (
        <input
            {...props}
            className="block font-GoogleSans resp-search-input-w rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            type="search"
            value={value}
            onChange={handleInputChange}
        />
    );
}
