// reducer.js

const initialState = {
    proj_grp_code: '',
    project_code: '',
    project_status: 1,
  };
  
  const projectFilterOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PROJECT_FILTER_OPTIONS':
        return {
          ...state,
          ...action.payload,
        };
      case 'RESET_PROJECT_FILTER_OPTIONS':
        return initialState;
      default:
        return state;
    }
  };
  
  export default projectFilterOptionsReducer;
  