import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddFiles from "./AddFiles";
import ViewFiles from "./ViewFiles";
import ProjectConfiguration from "./ProjectConfiguration";
import MergeTranscriptsCSV from "../Common/MergeTranscriptsCSV";
import images from "../../imports";
import { handleApiError } from "../Common/APIUtils";
import axios from "axios";
import { API_URL } from '../../imports'
import Popup from "../Common/Popup";

export default function ProcessTiles() {
    const routeparams = useParams();
    const [showProcessesTill, setShowProcessesTill] = useState(1);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [msg, setMsg] = useState('');
    const items = useMemo(()=>[
        {
            imgSrc: images["transcripticon.png"],
            alt: "Transcription",
            text: "Transcription",
            to: "transcription",
            code: "TR",
        },
        {
            imgSrc: images["getideas.png"],
            alt: "conference-background-selected",
            text: "Get Ideas",
            to: "getideas",
            code: "ID",
        },
        {
            imgSrc: images["rawans.png"],
            alt: "time-machine",
            text: "Raw Answers",
            to: "raw",
            code: "RW",
        },
        {
            imgSrc: images["refinedans.png"],
            alt: "time-machine",
            text: "Refined Answers",
            to: "refined",
            code: "RF",
        },
        {
            imgSrc: images["mapflattrans.png"],
            alt: "time-machine",
            text: "Transpose,Flattening & Mapping",
            to: "TransposeFlatteningMapping",
        },
    ],[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/entries/showprocesses/${sessionStorage.getItem('projectCode')}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                // Find the index of the item that matches showProcessesTill
                setShowProcessesTill(items.findIndex(item => item.text === response.data.data)+1);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsOpenPopup(true);
            }
        };

        fetchData();
      }, [items]);

    const storeProcess = (process) => {
        if (process !== "Transpose,Flattening & Mapping") {
            sessionStorage.setItem("processName", process.text);
            sessionStorage.setItem("processCode", process.code);
        }
        if (process.additional_config) {
            sessionStorage.setItem(
                "additional_config",
                JSON.stringify(process.additional_config)
            );
        }
    };

    let projectState = sessionStorage.getItem("projectStatus");

    useEffect(() => {
        sessionStorage.removeItem("processName");
        sessionStorage.removeItem("processCode");
    }, []);

    return (
        <div>
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={() => setIsOpenPopup(false)}
                isSuccess={false}
            />
            <div className="flex gap-2 md:hidden justify-end">
                <AddFiles />
                <ViewFiles />
                <ProjectConfiguration />
                <MergeTranscriptsCSV />
            </div>
            {projectState === "false" && (
                <div className="bg-white border border-red-600 p-2 mt-4 mb-0 md:mt-0 md:mb-4 inline-flex rounded-md text-red-600">
                    *You are granted with Read Only Access for this project
                </div>
            )}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 py-4 md:py-0">
                {items.slice(0, showProcessesTill).map((item, index) => (
                    <Link
                        to={`/projects/${routeparams.projectname}/${item.to}`}
                        onClick={() => storeProcess(item)} // Storing the process name in sessionStorage when the link is clicked
                        key={index}
                        className="bg-indigo-900 flex flex-col tracking-wider space-y-4 items-center rounded-md py-20 px-3"
                    >
                        <img
                            width="40"
                            height="40"
                            src={item.imgSrc}
                            alt={item.alt}
                        />
                        <p className="text-white text-center break-words">
                            {item.text}
                        </p>
                    </Link>
                ))}
            </div>
        </div>
    );
}
