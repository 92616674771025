import { Fragment, useState, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function SearchableDropdown({
    items,
    selected,
    setSelected,
    filterBy,
    enableEmptySelectOption,
    emptyOptionName,
    placeholder,
}) {
    // const [selected, setSelected] = useState([]);
    const [query, setQuery] = useState("");

    // useEffect(() => {
    //     setSelectedProp(selected);
    // }, [selected, setSelectedProp]);
    useEffect(() => {
        if (!selected && placeholder) {
            setSelected({}); // Set selected to an empty object if not provided
        }
    }, [selected, setSelected, placeholder]);

    const filteredItems =
        query === ""
            ? items
            : items.filter((item) =>
                  item[filterBy]
                      .toLowerCase()
                      .replace(/\s+/g, "")
                      .includes(query.toLowerCase().replace(/\s+/g, ""))
              );

    return (
        <div className="w-72">
            <Combobox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={(item) =>
                                item[filterBy] ||
                                item?.project_name ||
                                placeholder
                            }
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}
                    >
                        <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-300 shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {filteredItems.length === 0 && query !== "" ? (
                                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                <>
                                    {enableEmptySelectOption && (
                                        <Combobox.Option
                                            className={({ active }) =>
                                                `flex cursor-default lg:cursor-pointer select-none py-2 px-2 ${
                                                    active
                                                        ? "bg-indigo-900 text-white"
                                                        : "text-gray-900"
                                                }`
                                            }
                                            value={[]}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${
                                                            selected
                                                                ? "font-medium"
                                                                : "font-normal"
                                                        }`}
                                                    >
                                                        {emptyOptionName}
                                                    </span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                    )}
                                    {filteredItems.map((item, index) => (
                                        <Combobox.Option
                                            key={index}
                                            className={({ active }) =>
                                                `flex cursor-default lg:cursor-pointer select-none py-2 px-2 ${
                                                    active
                                                        ? "bg-indigo-900 text-white"
                                                        : "text-gray-900"
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${
                                                            selected
                                                                ? "font-medium"
                                                                : "font-normal"
                                                        }`}
                                                    >
                                                        {item[filterBy] ||
                                                            item.project_name}
                                                    </span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </>
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}
