import React, { useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
    getFacetedUniqueValues,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/24/outline";
import { initialpageSize } from "../Common/Contants";
import Popup from "../Common/Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import NoDataFound from "../Common/NoDataFound";
import { handleApiError } from "../Common/APIUtils";
import SearchableDropdown from "../Common/SearchableDropdown";
import ReadModal from "../Common/ReadModal";
import PageSizeDropdown from "../Common/PageSizeDropdown";

export default function NonAdminActivityLog() {
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    const [Loader, setLoader] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [sourceProject, setSourceProject] = useState({});
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [activityList, setActivityList] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState({});
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const handleFetchActivityLogsDatewise = () => {
        const params = {
            start_date: startDate,
            end_date: endDate,
        };

        if (sourceProject.project_code) {
            params.project_code = sourceProject.project_code;
        }

        axios
            .get(`${API_URL}/activity/timeline_based_nonadmin_activity`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                params,
            })
            .then((response) => {
                const dataWithProjectCodes = response.data.data.map((item) => ({
                    ...item,
                    project_code: item.project_code || 'NA',
                }));
                setData(dataWithProjectCodes);
                setFetchedData(dataWithProjectCodes);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    };




    let [modalTitle, setModalTitle] = useState("");

    function closeReadModal() {
        setIsOpenRead(false);
    }
    function openReadModal(e) {
        setRowData(e);
        setModalTitle(`Result of ${e.activity}`);
        setIsOpenRead(true);
    }
    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "project_code",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Project Code</span>,
                cell: ({ row }) => (
                    <p>
                        {row.original.project_code !== 'NA'
                            ? row.original.project_code
                            : "NA"}
                    </p>
                ),
            },
            {
                accessorKey: "activity",
                enableColumnFilter: true,
                size: 100,
                header: () => <span>Activity</span>,
            },
            {
                accessorKey: "entity",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Entity</span>,
            },
            {
                accessorKey: "operation",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Operation</span>,
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 150,
                header: "Created Date",
                cell: ({ row }) => <div>{row.original.creation_date}</div>,
            },
            {
                accessorKey: "id",
                header: "View",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-4 text-green-800" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await axios.get(`${API_URL}/activity/read_nonadmin_activity_log?useractivityonly=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                const dataWithProjectCodes = response.data.data.map((item) => ({
                    ...item,
                    project_code: item.project_code || 'NA',
                }));
                setData(dataWithProjectCodes);
                setFetchedData(dataWithProjectCodes);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };

        const fetchAllProjects = async () => {
            try {
                const response = await axios.get(`${API_URL}/activity/allprojects_in_db`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                const updatedProjects = response.data.data.map(project => ({
                    ...project,
                    displayName: `${project.project_code} - ${project.project_name}`
                }));
                setProjectList(updatedProjects);
                setSourceProject({});
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            }
        };

        const fetchAllUsers = async () => {
            try {
                const response = await axios.get(`${API_URL}/activity/allusers_in_db`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setUserList(response.data.data);
                setSelectedUser({});
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            }
        };

        fetchInitialData();
        fetchAllProjects();
        fetchAllUsers();
    }, []);


    useEffect(() => {
        if (selectedUser.user_id || sourceProject.project_code) {
            if (selectedUser.user_id && sourceProject.project_code) {
                setData(
                    fetchedData.filter(
                        (item) =>
                            item.project_code === sourceProject?.project_code &&
                            item.user_id === selectedUser?.user_id
                    )
                );
            } else {
                setData(
                    fetchedData.filter(
                        (item) =>
                            item.project_code === sourceProject?.project_code ||
                            item.user_id === selectedUser?.user_id
                    )
                );
            }
        } else {
            setData(fetchedData);
        }
    }, [fetchedData]);


    useEffect(() => {
        const currentDate = new Date();
        const oneWeekAgo = new Date(
            currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        );
        const formattedStartDate = oneWeekAgo.toISOString().split("T")[0];
        const formattedEndDate = currentDate.toISOString().split("T")[0];
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }, []);

    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                        <pre className="pretxtwrap text-sm">
                            {JSON.stringify(rowData.result, null, 2)}
                        </pre>
                    </div>
                }
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex flex-col lg:flex-row m-1 justify-start lg:justify-between lg:items-end space-x-4 p-2">
                <div className="flex flex-col lg:flex-row lg:items-end gap-4">
                    <div>
                        <label htmlFor="sourceProject" className="text-sm">
                            Project
                        </label>

                        <SearchableDropdown
                            items={projectList}
                            selected={sourceProject}
                            setSelected={setSourceProject}
                            filterBy="displayName"
                            enableEmptySelectOption={true}
                            emptyOptionName="All"
                            placeholder="All"
                        />
                    </div>
                    <div>
                        <label className="text-sm">Start Date</label>
                        <input
                            className="w-full border-gray-300 border rounded-md text-xs p-2.5 mt-1"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="text-sm">End Date</label>
                        <input
                            className="w-full border-gray-300 border rounded-md text-xs p-2.5 mt-1"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                            onClick={handleFetchActivityLogsDatewise}
                        >
                            Search
                        </button>
                    </div>
                </div>

            </div>

            {Loader && <FetchingDataLoader />}
            {!Loader && data.length === 0 && (
                <div className="resp-table-h-activity">
                    <NoDataFound />
                </div>
            )}
            {!Loader && data.length > 0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h-activity-log border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2 whitespace-nowrap"
                                            style={{
                                                height: `${paddingTop}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                                {virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr key={row.id}>
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className="border p-2 whitespace-nowrap"
                                                            key={cell.id}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingBottom}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex gap-1 justify-between flex-row-reverse m-3">
                        <nav
                            className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900"
                            aria-label="Pagination"
                        >
                            <button
                                title="First Page"
                                onClick={() => setPageIndex(0)}
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronDoubleLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                            <button
                                title="Previous Page"
                                onClick={() => setPageIndex(pageIndex - 1)}
                                disabled={pageIndex === 0}
                                aria-current="page"
                                className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            {pageNumbers.length===1 &&
                                <button
                                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                key={1}
                                onClick={()=>setPageIndex(0)}
                                >
                                {1}
                                </button>
                            }
                            {pageNumbers.length>1 && pageNumbers.map((pageNumber, index) => {
                                if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                if(table.getPageCount()!==0 && table.getPageCount()===1 && table.getPageCount()===pageNumber) {
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(pageNumber-1)}
                                    >
                                    {pageNumber}
                                    </button>
                                    );
                                }
                                else if(table.getPageCount()!==1 && table.getPageCount()>pageNumber) {
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(pageNumber)}
                                    >
                                    {pageNumber + 1}
                                    </button>
                                    );
                                }
                                else if(table.getPageCount()===0  && table.getPageCount()===pageNumber){
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(0)}
                                    >
                                    {1}
                                    </button>
                                    );
                                }
                                } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                }
                                return null;
                            })}
                            <button
                                title="Next Page"
                                onClick={() => setPageIndex(pageIndex + 1)}
                                disabled={
                                    pageIndex === table.getPageCount() - 1
                                }
                                className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                title="Last Page"
                                onClick={() =>
                                    setPageIndex(table.getPageCount() - 1)
                                }
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </nav>
                        <PageSizeDropdown
                            dataLength={table.getPrePaginationRowModel().rows.length}
                            pageSize={pageSize}
                            handlePageChange={handlePageChange}
                            totalRows={table.getPrePaginationRowModel().rows.length}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
