import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { convertBytesToMB } from "./CommonUtils";
import axios from "axios";
import { API_URL } from "../../imports";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import SearchableDropdown from "./SearchableDropdown";
import JSONLogListTable from "./JSONLogListTable";
import { CSVLink } from "react-csv";

function CopyTranscriptsFileUploader({projectList, sourceProject, SetSourceProject}) {
  const [files, setFiles] = useState([]);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState([]);
  let [show, setShow] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const acceptedFileTypes = {
    "text/csv": [".csv"],
  };

  const maxFileSize = {
    ".csv": 30 * 1024 * 1024, // 30MB
  };

  function getFileExtension(filename) {
    return "." + filename.split(".").pop().toLowerCase();
  }

  function handleFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter((file) => {
      const fileExtension = getFileExtension(file.name);
      const fileSize = file.size;

      if (!acceptedFileTypes[file.type]?.includes(fileExtension)) {
        setMsg("Invalid file type. Only csv files are allowed.");
        setIsSuccess(false);
        openPopup();
        return false;
      }

      if (fileSize > maxFileSize[fileExtension]) {
        setMsg(`File size exceeds the limit for ${fileExtension} files.`);
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });

    setFiles(validatedFiles);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: Math.max(...Object.values(maxFileSize)),
    onDrop: handleFileChange,
    multiple: false,
  });

  const responseStateHandler = (responseMessage, responseState) => {
    setMsg(responseMessage);
    setIsSuccess(responseState);
    openPopup();
  };

  const handleCopyTranscripts = async () => {
    // setLoader(true);
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("source_project_code", sourceProject.project_code);
      formData.append("file", files[0]);
      try {
        await axios
          .post(
            `${API_URL}/entries/copyTranscriptsCSV`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((response) => {
            setData(response.data.data);
            setShow(true);
            //setDownloadcsvcontent(response.data.csv);
            setFiles([]);
            // setLoader(false);
            // setShow(false);
          });
      } catch (error) {
        const errorMessage = handleApiError(error);
        // setLoader(false);
        setShow(false);
        responseStateHandler(errorMessage, false);
      }
    } else {
    //   setLoader(false);
      setShow(false);
      responseStateHandler("No Files are Uploaded", false);
    }
  };

  const csvData = data?.map((record, index) => ({
    "File Name": record['File name'],
    "Project code": record['Project code'],
    "Status": record.Status===true?'success':'error',
    "Message": record.Message,
}));

const sampleInputData = [
  {"filename": "A1.wav", "project_code": "SUPRO01"},
  {"filename": "A2.wav", "project_code": "SUPRO01"},
  {"filename": "B1.wav", "project_code": "SUPRO02"},
  {"filename": "B2.wav", "project_code": "SUPRO02"}
]


const sampleInputCSV = sampleInputData.map((record, index) => ({
  "filename": record['filename'],
  "project_code": record['project_code']
}));

  return (
    <div className="w-full p-4">
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Source Project
      </label>
      <SearchableDropdown items={projectList} selected={sourceProject} setSelected={SetSourceProject} filterBy="project_name"/>
      {!show && 
      <div className="flex justify-center mt-3 rounded-md border border-dashed border-gray-400 items-center h-[20rem] xl:h-[calc(100vh-24rem)]">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="text-center">
            <div className="flex justify-center text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-0 focus-within:border-white focus-within:ring-whitefocus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>{files.length < 1 ? "Upload" : "Re-Upload"} a file</span>
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">CSV up to 30MB</p>
            <aside>
              {files.map((file) => (
                <p
                  className="border py-3 px-2 mt-2 rounded-md text-sm"
                  key={file.path}
                >
                  {file.path} - {convertBytesToMB(file.size)}
                </p>
              ))}
            </aside>
          </div>
        </div>
      </div>
      }
      {show &&
        <div className="h-[20rem)] xl:h-[calc(100vh-24rem)] border rounded mt-2 overflow-auto">
          <JSONLogListTable data={data}/>
        </div>
      }
      <div className="flex justify-end lg:justify-start gap-2 mt-3">
        {show && <CSVLink data={csvData} filename="copyTranscriptsLog.csv" className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
            Download Logs
        </CSVLink>}
        {!show && <button type="button" onClick={handleCopyTranscripts} className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
            Copy Transcripts
        </button>}
        {data && data.length>0 && <button type="button" onClick={()=>setShow(!show)} className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
            {!show && 'Show Logs'}{show && 'Back to Upload'}
        </button>}
        <CSVLink data={sampleInputCSV} filename="SampleInputCopyTranscripts.csv" className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
            Get Sample Input FIle
        </CSVLink>
       </div>
    </div>
  );
}

export default CopyTranscriptsFileUploader;