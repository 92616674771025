import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Popup from '../Common/Popup';
import { API_URL } from '../../imports';
import { handleApiError } from '../Common/APIUtils';

const ApiDocument = () => {
    const [docs, setDocs] = useState([]);
    const [noData, setNoData] = useState(false);
    const [msg, setMsg] = useState('');
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    useEffect(() => {
        axios.get(`${API_URL}/api_docs`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => {
                const { data } = response.data;
                console.log('API response:', data);

                if (Array.isArray(data)) {
                    setDocs(data);
                    setNoData(data.length === 0);
                } else {
                    console.error('Expected an array but received:', data);
                    setMsg('Unexpected response format');
                    setIsOpenPopup(true);
                    setNoData(true);
                }
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsOpenPopup(true);
                setNoData(true);
            });
    }, []);

    const formattedDate = useCallback((dateStr) => {
        const date = new Date(dateStr);
        const formattedDateString = date.toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/\//g, '/').replace(',', ' ');
        const [month, day, yearAndTime] = formattedDateString.split('/');
        return `${day}-${month}-${yearAndTime}`;
    }, []);


    return (
        <>
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={() => setIsOpenPopup(false)}
            />
            <div className="overflow-auto">
                {noData ? (
                    <div className="text-center mt-4">
                        No API documentation found.
                    </div>
                ) : (
                    <div className="overflow-x-auto overflow-y-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 w-1/4 bg-gray-300">Title</th>
                                    <th className="px-4 py-2 w-1/2 bg-gray-300">URL</th>
                                    <th className="px-4 py-2 w-1/8 bg-gray-300">Version</th>
                                    <th className="px-4 py-2 w-1/4 bg-gray-300">Creation Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {docs.map((doc) => (
                                    <tr key={doc.id}>
                                        <td className="border px-4 py-2 w-1/4">{doc.title}</td>
                                        <td className="border px-4 py-2 w-1/2">
                                            <a
                                                href={doc.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600"
                                            >
                                                {doc.url}
                                            </a>
                                        </td>
                                        <td className="border px-4 py-2 w-1/8">{doc.version}</td>
                                        <td className="border px-4 py-2 w-1/4">{formattedDate(doc.creation_date)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    );
};

export default ApiDocument;
