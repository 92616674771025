import React, { useEffect, useState } from 'react';

const QDBTable = ({ data }) => {

  let [dataItems, setDataItems] = useState([]);
  let [expandedColumns, setExpandedColumns] = useState([]);

  useEffect(()=>{
    setDataItems(data);
  },[data])

  const toggleColumn = (column) => {
    if (expandedColumns.includes(column)) {
      // If column is already selected, remove it
      setExpandedColumns(expandedColumns.filter((col) => col !== column));
    } else {
      // If column is not selected, add it
      setExpandedColumns([...expandedColumns, column]);
    }
  };


  if (!dataItems || dataItems.length === 0) {
    return <div>No data to display.</div>;
  }

  const columns = Object.keys(dataItems[0]);

  const renderTableCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'boolean') {
      return value ? '1' : '0';
    }
    return value;
  };

  const renderHeaderCell = (column, index) => (
    <th key={index} className="border px-4 py-2 bg-gray-100" onClick={() => toggleColumn(column)}>
      <p className='max-w-xs truncate'>{column}</p>
    </th>
  );




  return (
  <div>
    <table className="table-auto border-collapse font-GoogleSans text-sm">
      <thead>
        <tr>
          {renderHeaderCell('Question_No.', columns.length)}
            {renderHeaderCell('Questions', columns.length + 1)}
            {columns.map((column, index) => column!=='Question_No.' && column!=='Questions' && renderHeaderCell(column, index))}
        </tr>
      </thead>
      <tbody>
        {dataItems.map((item, index) => (
          <tr key={index} className='hover:bg-gray-100'>
            <td className="border px-4 py-2"><p className='max-w-xs truncate'>{renderTableCell(item['Question_No.'])}</p></td>
            <td className="border px-4 py-2"><p className={`${expandedColumns.includes('Questions')?'max-w-full whitespace-nowrap':'max-w-xs truncate'}`} title={renderTableCell(item['Questions'])}>{renderTableCell(item['Questions'])}</p></td>
              {columns.map((column, columnIndex) => (
                <>
                {column!=='Question_No.' && column!=='Questions' && <td key={columnIndex} className="border px-4 py-2">
                  <p className={`${expandedColumns.includes(column)?'max-w-full whitespace-nowrap':'max-w-xs truncate'}`} title={item[column]}>{renderTableCell(item[column])}</p>
                </td>
              }
              </>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default QDBTable;
