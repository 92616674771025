function getFormattedDateTimeforFilename(dateStr) {
    const filedate = new Date(dateStr);
    const year = filedate.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (filedate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so add 1
    const day = filedate.getDate().toString().padStart(2, '0');
    const hours = filedate.getHours().toString().padStart(2, '0');
    const minutes = filedate.getMinutes().toString().padStart(2, '0');
    const seconds = filedate.getSeconds().toString().padStart(2, '0');
  
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

function getAlreadyFormattedDateTimeforFilename(dateStr) {
    const dateParts = dateStr.split(/[- :]/);
    const year = dateParts[2].slice(-2);
    const month = dateParts[1];
    const day = dateParts[0];
    const hours = dateParts[3];
    const minutes = dateParts[4];
    const seconds = dateParts[5];
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export const formattedDate = (timestamp) => {
    if (timestamp === null || timestamp === undefined) return null;
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export function generateProcessCodeFileName(filename,cdt) {
    const formattedDateTime = getFormattedDateTimeforFilename(cdt);
    const processCode = sessionStorage.getItem("processCode");
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${processCode}_${formattedDateTime}`;
    return fileName;
}

export function generateProcessCodeFileNameAlreadyDTLog(filename,cdt) {
    const formattedDateTime = getAlreadyFormattedDateTimeforFilename(cdt);
    const processCode = sessionStorage.getItem("processCode");
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${processCode}_${formattedDateTime}`;
    return fileName;
}

export function generateProcessCodeFileNameAlreadyDT(filename,cdt) {
    const formattedDateTime = formattedDate(cdt);
    const processCode = sessionStorage.getItem("processCode");
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${processCode}_${formattedDateTime}`;
    return fileName;
}

export function generateFileNameDated(filename,cdt) {
    const formattedDateTime = getFormattedDateTimeforFilename(cdt);
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${formattedDateTime}`;
    return fileName;
}

export function generateFileNameDatedAlreadyDT(filename,cdt) {
    const formattedDateTime = getAlreadyFormattedDateTimeforFilename(cdt);
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${formattedDateTime}`;
    return fileName;
}

export function generateParamCodeFileName(filename, paramcode, cdt) {
    const formattedDateTime = getFormattedDateTimeforFilename(cdt);
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${paramcode}_${formattedDateTime}`;
    return fileName;
}

export function generateParamCodeFileNameAlreadyDT(filename, paramcode, cdt) {
    const formattedDateTime = getAlreadyFormattedDateTimeforFilename(cdt);
    const fileName = `${filename.replace(".wav", "").replace(".mp4", "").replace(".m4a", "").replace(".mp3", "")}_${paramcode}_${formattedDateTime}`;
    return fileName;
}

export function convertBytesToMB(bytes) {
    if (bytes === 0) {
      return "0 KB";
    } else if (bytes < 1024) {
      return `${bytes} Bytes`;
    } else if (bytes >= 1024 && bytes < 1024 * 1024) {
      const kilobytes = bytes / 1024;
      return kilobytes.toFixed(2) + " KB";
    } else {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(2) + " MB";
    }
}

export function getCurrentDateTimeforFilename() {
    const filedate = new Date();
    const year = filedate.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (filedate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so add 1
    const day = filedate.getDate().toString().padStart(2, '0');
    const hours = filedate.getHours().toString().padStart(2, '0');
    const minutes = filedate.getMinutes().toString().padStart(2, '0');
    const seconds = filedate.getSeconds().toString().padStart(2, '0');
  
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export const formattedStartDateTime = () => {
    const date = new Date();
    const formattedDateString = date
        .toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        })
        .replace(/\//g, "/")
        .replace(",", " ");
    const [month, day, yearAndTime] = formattedDateString.split("/");
    return `${day}-${month}-${yearAndTime}`;
};