import React from "react";
import { Link } from "react-router-dom";
import images from "../../imports";

function ProjectManager() {
    const items = [
        {
            imgSrc: images["documents-folder--v1.png"],
            alt: "documents-folder--v1",
            text: "Project Management",
            to: "/project_management",
        },
        {
            imgSrc: images["conference-background-selected.png"],
            alt: "conference-background-selected",
            text: "User Management",
            to: "/user_management",
        },
        {
            imgSrc: images["personal-projects.png"],
            alt: "conference-background-selected",
            text: "My Projects",
            to: "/projects",
        }
    ];
    return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            {items.map((item, index) => (
                <Link
                    to={item.to}
                    key={index}
                    className="bg-yellow-300 cursor-default lg:cursor-pointer flex flex-col tracking-wider space-y-4 items-center rounded-md py-20 p-5"
                >
                    <img
                        width="40"
                        height="40"
                        src={item.imgSrc}
                        alt={item.alt}
                    />
                    <p>{item.text}</p>
                </Link>
            ))}
        </div>
    );
}

export default ProjectManager;