export const handleApiError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        return error.response.data.message;
    } else if (error.request) {
        // The request was made but no response was received
        return "Network error. Please try again later.";
    } else {
        // Something happened in setting up the request that triggered an Error
        return "Oops, something went wrong. Please try again";
    }
};