import React, { useState } from 'react'
import { ixologo, API_URL } from '../../imports'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [loginError, setLoginError]=useState(false);
  const [successMsg, setSuccessMsg]=useState('');

  const navigate = useNavigate();

  const clearError = () => {
    setTimeout(() => {
        setUsernameError("");
        setLoginError("");
    }, 2000);
  };

  const validateUsername = (value) => {
    // Regular expression for no spaces in between and not starting with a space
    const usernamePattern = /^[^\s]+$/;
    const errormsgusername= usernamePattern.test(value);
    return errormsgusername;
  };
   
  const EmailSubmit = async(event) => {
      event.preventDefault();

  // Input validation
  setUsernameError('');

  if (!username) {
    setUsernameError('Please enter your email address.');
  } else if (!validateUsername(username)) {
    setUsernameError('Please enter a valid email address.');
  }

  if (username && validateUsername(username)) {
      axios.post(`${API_URL}/auth/forgotpassword`, {
                  email: username
              })
              .then((response) => {
                setSuccessMsg(true);
                window.document.getElementById("sucsp").innerHTML=response.data.message;
                setTimeout(() => {
                  navigate('/');
                }, 3000);
              })
              .catch((error) => {
                setLoginError(true);
                const errorMessage = handleApiError(error);
                window.document.getElementById("errdsp").innerHTML = errorMessage;
                clearError();
        });
  }};
  
  return (
    <div className='w-full flex justify-center items-center h-screen font-GoogleSans tracking-wider bg-gray-50 select-none fixed'>
      <Link to='/' className='absolute top-3 right-5 text-indigo-600 font-bold tracking-wide'>Back to Sign In</Link>
    <div className='flex flex-col 2xl:w-auto gap-4 px-10 py-14 md:border md:bg-white md:rounded-md'>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src={ixologo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
            Forgot Password
          </h2>
        </div>
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${loginError ? 'block' : 'hidden'}`}></p>
        <p id="sucsp" className={`text-blue-800 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${successMsg ? 'block' : 'hidden'}`}></p>
        <div className="md:mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-5" onSubmit={EmailSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                Enter the Email associated with your account and we'll send an email with instructions to reset your password.
              </label>
              <div className="mt-4 md:mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="email"
                  value={username}
                  onChange={(event) =>
                      setUsername(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  ${usernameError ? 'ring-red-300' : ''}`}
                />
                 {usernameError && <p className="text-xs mt-2 text-red-500">{usernameError}</p>}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full tracking-widest justify-center rounded bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send Instructions
              </button>
            </div>
          </form>
          </div>
    </div>
    </div>
  )
}

export default ForgotPassword