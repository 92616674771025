import React, { useCallback, useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    EyeIcon,
} from "@heroicons/react/24/outline";
import Popup from "./Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput, IndeterminateCheckbox, StatusFilter } from "./Filters";
import ReadModal from "./ReadModal";
import LogTable from "./LogTable";
import { handleApiError } from "./APIUtils";
import FetchingDataLoader from "./FetchingDataLoader";
import NoDataFound from "./NoDataFound";
import ClosingDataLoader from "./ClosingDataLoader";
import { formattedDate, generateProcessCodeFileNameAlreadyDT } from "./CommonUtils";

export default function NewFilewiseStatusLogs({
    fetchFilewiseAPIURL,
    fileDetailsAPIURL,
    CloseAPIURL,
    process_code,
}) {
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [modalTitle, setModalTitle] = useState(false);
    let [detailsState, setDetailsState] = useState(false);
    let [fileInfoData, setFileInfoData] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [closeLoader, setCloseLoader] = useState(false);
    const [jsonValue, setJsonValue] = useState(null);

    function closePopup() {
        setIsOpenPopup(false);
        setCloseLoader(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    function closeReadModal() {
        setIsOpenRead(false);
    }

    const FileDetailsData = useCallback(
        async (filename) => {
            axios
                .post(
                    `${API_URL}/${fileDetailsAPIURL}`,
                    { filename: filename },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const keyOrder = [
                        "Status",
                        "Status Code",
                        "Status Message",
                        "Params",
                        "Finalized",
                        "Processed By",
                        "Execution Date",
                        "Response",
                        "id",
                    ];

                    // Map over the entries and create objects with the desired key order
                    const formattedData = response.data.data.map((entry) => {
                        const formattedEntry = {};
                        keyOrder.forEach((key) => {
                            formattedEntry[key] = entry[key];
                        });
                        return formattedEntry;
                    });
                    setFileInfoData(formattedData);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [setFileInfoData, setMsg, setIsSuccess, openPopup, fileDetailsAPIURL]
    );

    const getProcessResponse = useCallback(async (id) => {
        axios
            .post(
                `${API_URL}/entries/getresponse`,
                {
                    id: id,
                    process_code: process_code,
                    proj_code: sessionStorage.getItem("projectCode"),
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                // Decode the base64 string
                const decodedJsonString = atob(response.data.data);
                setJsonValue(JSON.parse(decodedJsonString));
                // setJsonValue(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    },[process_code,openPopup]);

    const openReadModal = useCallback(
        (e, options) => {
            setRowData(e);
            if (options === "response") {
                setModalTitle(`Response of ${e.filename}`);
                setDetailsState(false);
                getProcessResponse(e.id);
            } else {
                setModalTitle(` ${e.filename}`);
                setDetailsState(true);
                FileDetailsData(e.filename);
            }
            setIsOpenRead(true);
        },
        [
            setRowData,
            setModalTitle,
            setDetailsState,
            FileDetailsData,
            setIsOpenRead,
            getProcessResponse
        ]
    );

    const ReloadData = async () => {
        setRowSelection({});
        setLoader(true);
        axios
            .get(
                `${API_URL}/${fetchFilewiseAPIURL}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    };

    const JsonDownloadButton = ({ id, JSONfileName, disabledState }) => {
        const downloadJson = () => {
            axios
                .post(
                    `${API_URL}/entries/getresponse`,
                    {
                        id: id,
                        process_code: process_code,
                        proj_code: sessionStorage.getItem("projectCode"),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    // Decode the base64 string
                    const decodedJsonString = atob(response.data.data);
                    const blob = new Blob(
                        [JSON.stringify(JSON.parse(decodedJsonString))],
                        {
                            type: "application/json",
                        }
                    );
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = JSONfileName + ".json";
                    link.click();
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        };

        return (
            <button
                className="p-1 rounded-md border disabled:text-gray-300"
                onClick={downloadJson}
                disabled={disabledState}
            >
                <p className="flex gap-1" title="json file download">
                    <ArrowDownCircleIcon className="h-5" />
                </p>
            </button>
        );
    };

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 650,
                header: () => <span>File Name</span>,
                cell: ({ row }) => (
                    <div
                        title="Click to get File Details"
                        onClick={() =>
                            openReadModal(row.original, "filedetails")
                        }
                        disabled={row.original.response ? false : true}
                        className="text-blue-600 cursor-default lg:cursor-pointer disabled:text-black"
                    >
                        {row.original.filename}
                    </div>
                ),
            },
            {
                accessorKey: "status",
                enableColumnFilter: true,
                filterFn: 'equals',
                size: 50,
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            className={`${
                                row.original.status === "success" &&
                                "bg-green-500"
                            } ${
                                row.original.status === "error" && "bg-red-500"
                            } ${
                                row.original.status === "Unprocessed" &&
                                "bg-yellow-500"
                            }  ${
                                row.original.status !== "success" &&
                                row.original.status !== "error" &&
                                row.original.status !== "Unprocessed" &&
                                "bg-yellow-500"
                            } 
                            text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}
                        >
                            {row.original.status && row.original.status}
                            {!row.original.status && "Unprocessed"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "status_code",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Status Code</span>,
                cell: ({ row }) => <center>{row.original.status_code}</center>,
            },
            {
                accessorKey: "status_message",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Status Message</span>,
                cell: ({ row }) => (
                    <span title={row.original.status_message} className="max-w-xs truncate">
                        {row.original.status_message}
                    </span>
                ),
            },
            {
                accessorKey: "closing",
                enableColumnFilter: false,
                size: 50,
                header: () => <span>Finalized</span>,
                cell: ({ row }) => (
                    <center>{row.original.closing === true ? 1 : ""}</center>
                ),
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 100,
                header: "Execution Date",
                cell: ({ row }) => <div>{formattedDate(row.original.creation_date)}</div>,
            },
            {
                accessorKey: "creation_by",
                enableColumnFilter: false,
                size: 50,
                header: "Processed By",
                cell: ({ row }) => (
                    <span
                        className="capitalize max-w-xs truncate"
                        title={row.original.creation_by}
                    >
                        {row.original.creation_by}
                    </span>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border disabled:text-gray-300 text-green-800"
                            disabled={row.original.status_code ? false : true}
                            onClick={() =>
                                openReadModal(row.original, "response")
                            }
                        >
                            <EyeIcon className="h-4" />
                        </button>
                        <JsonDownloadButton
                            id={row.original.id}
                            JSONfileName={
                                row.original.status_code==='0' &&
                                row.original.creation_date &&
                                generateProcessCodeFileNameAlreadyDT(
                                    row.original.filename,
                                    row.original.creation_date
                                )
                            }
                            disabledState={row.original.status_code==='0'? false : true}
                        />
                    </div>
                ),
            },
        ],
        [openReadModal]
    );

    const [data, setData] = useState([]);

    const CloseAPI = async (closeData) => {
        setLoader(true);
        setCloseLoader(true);
        setRowSelection({});
        axios
            .post(
                `${API_URL}/${CloseAPIURL}`,
                {
                    fileslist: closeData,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setMsg(response.data.message);
                setIsSuccess(true);
                setTimeout(() => {
                    openPopup();
                    ReloadData();
                }, 100);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };
    const handleSelectedClose = () => {
        // Filter the Files based on the rowSelection
        const selectedCloseFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedCloseFiles.length > 0) {
            const fileNames = selectedCloseFiles.map((file) => file.filename);
            CloseAPI(fileNames);
        }
    };

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rowSelection, setRowSelection] = React.useState({});

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel()
    });

    const renderTableCellIndex = (row) => {
        return table.getFilteredRowModel().rows.indexOf(row)+1 || table.getSortedRowModel().rows.indexOf(row)+1 || row.index+1;
    };

    const tableContainerRef = React.useRef(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(
                    `${API_URL}/${fetchFilewiseAPIURL}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoader(false);
                    }, 100);
                });
        };
        fetchInitialData();
    }, [openPopup, setData, fetchFilewiseAPIURL]);

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            {/* <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={handleDeleteState}
            /> */}
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <>
                        {detailsState && (
                            <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                                <LogTable
                                    data={fileInfoData}
                                    filename={rowData.filename}
                                    process_code={process_code}
                                />
                            </div>
                        )}
                        {!detailsState && (
                            <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                                {jsonValue && (
                                    <pre className="pretxtwrap text-sm">
                                        {JSON.stringify(
                                            jsonValue,
                                            null,
                                            2
                                        )}
                                    </pre>
                                )}
                                {!jsonValue && "No Response Found."}
                            </div>
                        )}
                    </>
                }
            />
            <div className="flex justify-between m-3 gap-2">
                <div className="flex md:gap-2">
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                    <div className="hidden md:block">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <div key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <div key={header.id}>
                                    {header.column.getCanFilter() ? (
                                        <div>
                                            <StatusFilter column={header.column} table={table} />
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ))}
                    </div>
                </div>
                <div className="flex gap-2">
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                </div>
            </div>
            {Loader &&
                (!closeLoader ? <FetchingDataLoader /> : <ClosingDataLoader />)}
            {!Loader && data.length === 0 && <NoDataFound />}
            {!Loader && data.length > 0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-processtable-h border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="bg-gray-200">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort() && header.column.id!=='status' 
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingTop}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                                {virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr
                                            key={row.id}
                                            className="bg-white transition duration-300 ease-in-out hover:bg-gray-100"
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className="border p-2 whitespace-nowrap"
                                                            key={cell.id}
                                                        >
                                                            {cell.column.id === "index" && <center>{renderTableCellIndex(row)}</center>}
                                                            {cell.column.id!=='index' && flexRender(cell.column.columnDef.cell,cell.getContext())}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingBottom}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {Object.keys(rowSelection).length > 0 && (
                        <div className="flex justify-end lg:justify-start gap-2 m-3">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={handleSelectedClose}
                            >
                                Finalize
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
