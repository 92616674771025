import React, { useEffect, useState } from 'react'
import { ixologo, API_URL } from '../../imports'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';

function ResetPassword() {
  let { token } = useParams();
  const [passwordError, setPasswordError] = useState({
    newpassword: '',
    confirmpassword: ''
  });
  const [newpassword, setNewpassword]=useState('');
  const [confirmpassword, setConfirmpassword]=useState('');
  const [responseError, setResponseError]=useState(false);
  const [successMsg, setSuccessMsg]=useState('');

  const navigate = useNavigate();

  const clearError = () => {
    setTimeout(() => {
      setPasswordError({
        newpassword: '',
        confirmpassword: ''
      });
      setResponseError(false);
    }, 2000);
  };

  const validatePassword = (value) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()?_+])[\w!@#$%^&*?()_+]{8,}$/;
    const pwdError = passwordPattern.test(value);
    return pwdError;
  };
   
  const ResetPasswordSubmit = async(event) => {
      event.preventDefault();

  // Input validation
  setPasswordError({
    newpassword: '',
    confirmpassword: ''
  });

  if (!newpassword) {
    setPasswordError(prevState => ({...prevState, 'newpassword': 'Please enter your new password.'}));
  } else if (!validatePassword(newpassword)) {
    setPasswordError(prevState => ({...prevState, 'newpassword': 'Password should contain at least 8 characters, one uppercase letter, one lowercase letter, one number, one special character and no spaces.'}));
  }

  if (!confirmpassword) {
    setPasswordError(prevState => ({...prevState, 'confirmpassword': 'Please enter your new password again.'}));
  }
  else if (confirmpassword!==newpassword) {
    setPasswordError(prevState => ({...prevState, 'confirmpassword': "The Password that you entered doesn't match the new password."}));
  }

  if (newpassword && confirmpassword && confirmpassword===newpassword && validatePassword(newpassword)) {
      axios.patch(`${API_URL}/auth/resetpassword/${token}`, {
        newpassword: newpassword
      })
      .then((response) => {
        setSuccessMsg(true);
        window.document.getElementById("sucsp").innerHTML=response.data.message;
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch((error) => {
        setResponseError(true);
        const errorMessage = handleApiError(error);
        window.document.getElementById("errdsp").innerHTML = errorMessage;
        clearError();
      });
  }};

  useEffect(()=>{
    axios.get(`${API_URL}/auth/checkresetpassword/${token}`)
    .catch((error) => {
      setResponseError(true);
      const errorMessage = handleApiError(error);
      window.document.getElementById("errdsp").innerHTML = errorMessage;
      clearError();
      setTimeout(() => {
        navigate('/');
      }, 3000);
    });
  },[token, navigate])
  
  return (
    <div className='w-full flex justify-center items-center h-screen font-GoogleSans tracking-wider bg-gray-50 select-none fixed'>
      <Link to='/' className='absolute top-3 right-5 text-indigo-600 font-bold tracking-wide'>Back to Sign In</Link>
    <div className='flex flex-col 2xl:w-auto gap-4 px-10 py-14 md:border md:bg-white md:rounded-md'>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src={ixologo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
            Reset your Password
          </h2>
        </div>
        <p id="errdsp" className={`text-red-500 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${responseError ? 'block' : 'hidden'}`}></p>
        <p id="sucsp" className={`text-blue-800 sm:mx-auto sm:w-full sm:max-w-sm border shadow text-center text-sm p-2 bg-gray-50 mt-4 ${successMsg ? 'block' : 'hidden'}`}></p>
        <div className="md:mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-5" onSubmit={ResetPasswordSubmit}>
            <p className='text-sm'>Please enter and confirm your new password below. Once submitted, these passwords will reset your existing password.</p>
            <div>
              <div className="mt-4 md:mt-2">
                <input
                  id="newpassword"
                  name="newpassword"
                  type="password"
                  placeholder='New Password'
                  value={newpassword}
                  onChange={(event) =>
                      setNewpassword(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  ${passwordError.newpassword ? 'ring-red-300' : ''}`}
                />
                 {passwordError.newpassword && <p className="text-xs mt-2 text-red-500">{passwordError.newpassword}</p>}
              </div>
              <div className="mt-5">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  placeholder='Confirm Password'
                  value={confirmpassword}
                  onChange={(event) =>
                      setConfirmpassword(event.target.value)
                  }
                  required
                  className={`block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  ${passwordError.confirmpassword ? 'ring-red-300' : ''}`}
                />
                 {passwordError.confirmpassword && <p className="text-xs mt-2 text-red-500">{passwordError.confirmpassword}</p>}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full tracking-widest justify-center rounded bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset Password
              </button>
            </div>
          </form>
          </div>
    </div>
    </div>
  )
}

export default ResetPassword