import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoutes() {
  const token = localStorage.getItem('access_token');
  const isAuthorized = (token) ? true : false;

  useEffect(() => {
    if (!isAuthorized) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [isAuthorized]);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return (
    <>
      {isAuthorized ? (
        <Outlet />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default ProtectedRoutes;