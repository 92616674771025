import React from "react";
import TransposeFlatteningMappingProcess from "../Common/TransposeFlatteningMappingProcess";
import ProcessLayout from "../Common/ProcessLayout";

function TransposeFlatteningMapping() {

    const tabs = [
        {
            id: 1,
            title: "Transpose",
            content: (
                <TransposeFlatteningMappingProcess
                    fetchDataURL="gettransposedfiles"
                />
            ),
        },
        {
            id: 2,
            title: "Flattening",
            content: (
                <TransposeFlatteningMappingProcess
                    fetchDataURL="getflattenedfiles"
                />
            ),
        },
        {
            id: 3,
            title: "Mapping",
            content: (
                <TransposeFlatteningMappingProcess
                    fetchDataURL="getmappedfiles"
                />
            ),
        },
    ];

    return (
        <>
            <ProcessLayout startProcess={false} tabs={tabs} batching={false}/>
        </>
    );
}

export default TransposeFlatteningMapping;
