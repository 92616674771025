import React, { useCallback, useEffect, useState} from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports'
import FetchingDataLoader from '../Common/FetchingDataLoader';
import NoDataFound from '../Common/NoDataFound';
import { useNavigate } from 'react-router-dom';
import { handleApiError } from '../Common/APIUtils';

export default function InstanceMonitor() {
  const [Loader, setLoader]=useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  const [data,setData]=useState([]);
  
  const navigate = useNavigate();

  function closePopup() {
    setIsOpenPopup(false);
  }
  

  function openPopup() {
    setIsOpenPopup(true);
  }

  const getMADStatus = (instanceData) => {
    const madInstance = instanceData.find(instance => instance.instance === "MAD");
    return madInstance ? madInstance.status : false;
  };

  const ReloadData = useCallback(async (loadprops) => {
    setLoader(loadprops);
    axios
        .get(`${API_URL}/instancemonitor`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            setData(response.data.data);
            if(process.env.NODE_ENV==="production" && getMADStatus(response.data.data)===false){
              navigate('/');
            }
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          if(process.env.NODE_ENV==="production" && errorMessage==="Network error. Please try again later."){
            navigate('/');
          }
          else{
            setMsg(errorMessage);
          }
          openPopup();
        })
        .finally(()=>{
          setLoader(false);
        });
  },[navigate]);

  const handleStartStopState = useCallback(async(insv,action) => {
    try {
            await axios
                  .post(`${API_URL}/instancemonitor`, {'instance':insv.instance,'action':action}, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                .then((response) => {
                    setIsSuccess(true);
                    setMsg(response.data.message);
                    setData([...data.map(item => item.instance === insv.instance ? response.data.data : item)]);
                    setIsOpenPopup(true);
                });
        } catch (error) {
            setIsSuccess(false);
            setIsOpenPopup(true);
        }
  },[data]);

  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 10,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'name',
        enableColumnFilter:false,
        size: 500,
        header: () => <span>Instance Name</span>,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        enableColumnFilter:false,
        enableSorting:false,
        cell:({ row }) => (
          <div className='flex justify-center'><div className={`${row.original.status === true ? 'bg-green-300  text-green-900':'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl`}>{row.original.status === true ? "Running" : "Stopped"}</div></div>
        )
      },
      {
        accessorKey:'Action',
        header: 'Action',
        size: 50,
        enableColumnFilter:false,
        enableSorting:false,
        cell:({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
          <button className="p-1 px-2 text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm" onClick={()=>handleStartStopState(row.original,!row.original.status)}>
          {row.original.status === true ? "Stop" : "Start"}
          </button>
          </div>
         )
       },
    ],
    [handleStartStopState]
  );

  const [globalFilter, setGlobalFilter] = React.useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const tableContainerRef = React.useRef(null);
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: data.length,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (
        table.getFilteredRowModel().rows.indexOf(row) + 1 ||
        table.getSortedRowModel().rows.indexOf(row) + 1 ||
        row.index + 1
    );
  };

useEffect(()=>{
  axios.get(`${API_URL}/instancemonitor`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            setData(response.data.data);
        })
        .catch((error) => {
            setMsg(error.response.data.message);
            openPopup();
        })
        .finally(()=>{
          setLoader(false);
        });
},[])

  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <div className='flex justify-between items-center my-2 mx-3 gap-2'>
        {/* <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          placeholder="Search..."
        /> */}
        <p className="font-medium text-lg">Instances</p>
      <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={()=>ReloadData(true)}>
      <ArrowPathIcon className='h-5 text-green-700'/>
      </button>
      </div>
      {Loader && <FetchingDataLoader/>}
      {!Loader && data.length===0 && <NoDataFound/>}
      {!Loader && data.length>0 &&
      <>
      <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto'>
        <table className='flex-inline w-full border-collapse'>
          <thead className='shadow bg-gray-100'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className='border p-2.5 whitespace-nowrap'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                        <div
                          className={`${
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none justify-start'
                              : 'justify-center'
                          } flex`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] || null}
                        </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className='border p-2 whitespace-nowrap' key={cell.id}>
                        {cell.column.id === "index" && (
                            <center>{renderTableCellIndex(row)}</center>
                        )}
                        {cell.column.id !== "index" &&
                        flexRender(
                            cell.column
                                .columnDef
                                .cell,
                            cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </>
      }
    </div>
  );
}