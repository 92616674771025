import React from 'react';
import { loadingbar } from '../../imports';

function Loader() {
  
  return (
    <div className='flex flex-col gap-4 bg-white items-center justify-center fixed w-full overflow-auto font-GoogleSans' style={{minHeight:"86vh"}}>
      <img className='h-10' src={loadingbar} alt='Loading...'/>
    </div>
  )
}

export default Loader