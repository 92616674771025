import React, { useState, useRef, useEffect } from 'react';
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

const Accordion = ({ groupedTasks, onCancel, selectedTab }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
      setOpenIndex(openIndex === index ? null : index);
  };

  return (
      <div className="bg-white border border-gray-200 rounded-lg">
          {groupedTasks && groupedTasks.map((item, index) => (
              <AccordionItem
                  key={item.projectCode}
                  projectCode={item.projectCode}
                  projectName={item.projectName}
                  creationDate={item.creationDate}
                  items={item.tasks}
                  isOpen={openIndex === index}
                  onClick={() => handleClick(index)}
                  onCancel={() => onCancel(item.projectName, item.projectCode, selectedTab)}
              />
          ))}
      </div>
  );
};


const AccordionItem = ({ projectName, items, isOpen, onClick, onCancel }) => {
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  return (
      <div className="border-b border-gray-200">
          <div
              onClick={onClick}
              className="w-full text-left px-4 py-2 bg-gray-100 focus:outline-none"
          >
              <div className="flex justify-between items-center">
                  <span>{projectName}</span>
                  <div className="flex px-4 py-2 align-middle justify-center gap-2">
                      <button
                          onClick={(e) => {
                              e.stopPropagation();
                              onCancel();
                          }}
                          className="inline-flex justify-center border border-red-300 bg-red-100 text-red-900 hover:bg-red-200 ps-2 pe-3 py-1.5 md:py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 cursor-pointer"
                      >
                          Cancel
                      </button>
                      <span>
                          {isOpen ? (
                              <MinusIcon className="h-6 w-6 text-gray-500" />
                          ) : (
                              <PlusIcon className="h-6 w-6 text-gray-500" />
                          )}
                      </span>
                  </div>
              </div>
          </div>
          <div
              ref={contentRef}
              style={{ maxHeight: height }}
              className="overflow-hidden transition-max-height duration-500 ease-in-out"
          >
              <table className="min-w-full bg-white border border-gray-200 overflow-auto">
                  <thead className="bg-gray-100">
                      <tr className="border-b border-gray-300">
                          <th className="py-2 px-4 text-left text-black font-semibold">Id</th>
                          <th className="py-2 px-4 text-left text-black font-semibold">Filename</th>
                          <th className="py-2 px-4 text-left text-black font-semibold">Status</th>
                      </tr>
                  </thead>
                  <tbody>
                      {items.map((file, index) => (
                          <tr key={index}>
                              <td className="border py-2 px-4 text-left text-gray-800">{index + 1}</td>
                              <td className="border py-2 px-4 text-left text-gray-800">{file.filename}</td>
                              <td className="border py-2 px-4 text-left text-gray-800">{file.status}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
      </div>
  );
};

export default Accordion;


