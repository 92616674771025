import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon, PencilIcon, TrashIcon, PlusCircleIcon, FunnelIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { initialpageSize } from '../Common/Contants';
import Popup from '../Common/Popup';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import InputModal from '../Common/InputModal';
import axios from 'axios';
import { API_URL } from '../../imports'
import { DebouncedInput, SelectTextFilterWithAllOption, StatusAdminFilter } from '../Common/Filters';
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import PageSizeDropdown from '../Common/PageSizeDropdown';
import { formattedDate } from '../Common/CommonUtils';
import { Menu, Transition } from '@headlessui/react';
import SearchableSingleSelector from '../Common/SearchableSingleSelector';
import { Portal } from "react-portal";


export default function ProjectAssignment() {
  let [nonAdminUsers, setNonAdminUsers] = useState([]);
  let [projects, setProjects] = useState([]);

  let [selectedProject, setSelectedProject] = useState({});
  let [selectedUser, setSelectedUser] = useState({});

  let [isOpen, setIsOpen] = useState(false);
  let [rowData, setRowData] = useState([]);
  let [edit, setEdit] = useState(false);
  const [Loader, setLoader] = useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
  const [activeDropdown, setActiveDropdown] = useState(null);
  const buttonRefs = useRef({});
  const dropdownRef = useRef(null);

  const calculatePosition = useCallback((id) => {
    const buttonRef = buttonRefs.current[id];
    if (buttonRef) {
        const rect = buttonRef.getBoundingClientRect();
        setDropdownPosition({
            top: rect.bottom + window.scrollY,
        });
        setActiveDropdown((prev) => (prev === id ? null : id));
    }
}, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    const handleKeyDown = (event) => {
      const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
      if (keysToCloseDropdown.includes(event.key)) {
        setActiveDropdown(null);
      }
    };
    const handleScroll = () => {
      setActiveDropdown(null);
    };
  
    if (activeDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [activeDropdown]);


  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRowData([]);
  }

  const openModal = useCallback(async (e, option) => {
    setRowData(e);
    if (option === 'edit') {
      setEdit(true);
      setSelectedProject(projects.filter(project => project.project_code === e.project_code)[0] || {});
      setSelectedUser(nonAdminUsers.filter(user => user.user_id === e.user_id)[0] || {});
    } else if (option === 'create') {
      setEdit(false);
      setSelectedProject({});
      setSelectedUser({});
    }
    setIsOpen(true);
  }, [projects, nonAdminUsers]);

  const ReloadData = async (loadprops) => {
    setLoader(loadprops);
    axios
      .get(`${API_URL}/projectusers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 100);
      });
  };

  const handleCreateState = async () => {
    setIsOpen(false);
    let create_form = { project_code: selectedProject.project_code, user_id: selectedUser.user_id };
    try {
      await axios
        .post(`${API_URL}/projectusers`, create_form, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleEditState = async () => {
    let statu = { project_code: selectedProject.project_code, user_id: selectedUser.user_id };
    try {
      await axios
        .patch(`${API_URL}/projectusers/${rowData.id}`, statu, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpen(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpen(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle(`Project assignment status - ${e.projectName}`)
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async () => {
    let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
    try {
      await axios
        .patch(`${API_URL}/projectusers/status/${rowData.id}`, statusData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenStatusModal(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenStatusModal(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };


  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(e) {
    setRowData(e);
    setConfirmationMsg("Are you sure that you want to delete that project assignment?")
    setIsOpenConfirmPopup(true);
  }

  const handleDeleteState = async () => {
    try {
      await axios
        .delete(`${API_URL}/projectusers/${rowData.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'project_name',
        enableColumnFilter: true,
        size: 100,
        header: () => <span>Project Name</span>
      },
      {
        accessorKey: 'user_name',
        enableColumnFilter: true,
        enableSorting: false,
        size: 200,
        header: () => <span>User</span>,
        cell: ({ row }) => (
          <div className="text-start">
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-0 m-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              {row.original.user_id} - {row.original.user_name}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter: false,
        size: 150,
        header: 'Created Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.creation_date)}</div>
        )
      },
      {
        accessorKey: 'creation_by',
        enableColumnFilter: false,
        size: 50,
        header: 'Created By',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        enableColumnFilter: true,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex justify-center'><div onClick={() => openStatusModal(row.original)} className={`${row.original.status === true ? 'bg-green-300  text-green-900' : 'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}>{row.original.status === true ? "Active" : "Inactive"}</div></div>
        )
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
            <Menu as="div" ref={(el) => (buttonRefs.current[row.original.id] = el)}>
              <div>
                <Menu.Button className="border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    calculatePosition(row.original.id)
                  }
                  }
                >
                  <EllipsisHorizontalIcon className="h-4 text-black" />
                </Menu.Button>
              </div>
              <Portal>
                <Transition
                  as={Fragment}
                  show={activeDropdown === row.original.id}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-30 right-8 md:right-16 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                      top: `${dropdownPosition.top}px`,
                    }}
                    ref={dropdownRef}
                  >
                    <button
                      className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-blue-800 w-full text-sm"
                      onClick={() => {
                        openModal(row.original, "edit")
                        setActiveDropdown(null)
                      }}
                    >
                      <PencilIcon className="h-4 text-blue-800 mr-2" />
                      <span>Edit</span>
                    </button>
                    {localStorage.getItem('role') === 'ADMIN' &&
                      <button className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-red-800 w-full text-sm"
                        onClick={() => {
                          openConfirmPopup(row.original)
                          setActiveDropdown(null)
                        }}>
                        <TrashIcon className='h-4 text-red-800 mr-2 rounded-md border' />
                        <span>Delete</span>
                      </button>}
                  </Menu.Items>
                </Transition>
              </Portal>
            </Menu>
          </div>
        )
      },
    ],
    [openModal, activeDropdown, calculatePosition, dropdownPosition.top]
  );

  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(initialpageSize);


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  });

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
  const pageCount = Math.ceil(data.length / pageSize);
  const pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setPageIndex(0)
  };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (
      table.getFilteredRowModel().rows.indexOf(row) + 1 ||
      table.getSortedRowModel().rows.indexOf(row) + 1 ||
      row.index + 1
    );
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(`${API_URL}/projectusers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    const fetchNonAdminUsers = async () => {
      axios
        .get(`${API_URL}/users/nonadmin`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setNonAdminUsers(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        });
    };
    const fetchProjects = async () => {
      axios
        .get(`${API_URL}/projects`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setProjects(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        });
    };
    fetchInitialData();
    fetchNonAdminUsers();
    fetchProjects();
  }, []);

  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={handleDeleteState}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <InputModal overflowVisible={true} isOpen={isOpen} closeModal={closeModal} title={edit === false ? 'Assign project users' : 'Reassign project users'} savefn={edit === false ? handleCreateState : handleEditState}
        modalInputs={
          <div className='space-y-4'>
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Project
              </label>
              {/* <select
                id="project"
                name="project"
                className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                value={rowData.project_code}
                onChange={(e) => setRowData({ ...rowData, project_code: e.target.value })}
              >
                <option value="">Select Project</option>
                {projects.map((item, index) => (
                  <option key={index} value={item.project_code}>
                    {item.project_name} - {item.project_code}
                  </option>
                ))}
              </select> */}
              <SearchableSingleSelector
                items={projects}
                selected={selectedProject}
                setSelected={setSelectedProject}
                filterby="project_name"
                additionalshowprop="project_code"
                nooverlap={true}
                selectorIndex="project_name"
                noDataFoundMessage="No Projects found"
              />
            </div>
            <div>
              <label htmlFor="users" className="block text-sm font-medium leading-6 text-gray-900">
                Users
              </label>
              {/* <select
                id="users"
                name="users"
                className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                defaultValue={rowData.user_id}
                onChange={(e) => setRowData({ ...rowData, user_id: e.target.value })}
              >
                <option value="">Select User</option>
                {nonAdminUsers.map((item, index) => (
                  <option key={index} value={item.user_id}>
                    {item.user_id} - {item.name}
                  </option>
                ))}
              </select> */}
              <SearchableSingleSelector
                items={nonAdminUsers}
                selected={selectedUser}
                setSelected={setSelectedUser}
                filterby="name"
                additionalshowprop="user_id"
                nooverlap={true}
                selectorIndex="name"
                noDataFoundMessage="No Users found"
              />
            </div>
          </div>
        }
      />
      <InputModal isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="true"
                checked={rowData.status === true || rowData.status === "true"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                Active
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="false"
                checked={rowData.status === false || rowData.status === "false"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                Inactive
              </label>
            </div>
          </div>
        }
      />
      <div className='flex justify-between m-3 gap-2'>
        <div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder="Search..."
          />
        </div>
        <div className='flex gap-2'>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
            <ArrowPathIcon className='h-5 text-green-700' />
          </button>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                <span className="absolute -inset-1.5" />
                <FunnelIcon className="h-5 w-auto" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  {data.length > 0 &&
                    table
                      .getHeaderGroups()
                      .map((headerGroup) => (
                        <div
                          key={headerGroup.id}
                          className="space-y-1"
                        >
                          {headerGroup.headers.map(
                            (header) => (
                              <div
                                key={header.id}
                              >
                                {header.column.getCanFilter() &&
                                  header.column.id === "status" ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      Status
                                    </label>
                                    <StatusAdminFilter
                                      column={
                                        header.column
                                      }
                                      table={
                                        table
                                      }
                                    />
                                  </div>
                                ) : null}
                                {header.column.getCanFilter() &&
                                  header.column.id === "project_name" ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      Project
                                    </label>
                                    <SelectTextFilterWithAllOption
                                      column={
                                        header.column
                                      }
                                      table={
                                        table
                                      }
                                    />
                                  </div>
                                ) : null}
                                {header.column.getCanFilter() &&
                                  header.column.id === "user_name" ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      User
                                    </label>
                                    <SelectTextFilterWithAllOption
                                      column={
                                        header.column
                                      }
                                      table={
                                        table
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => openModal('e', 'create')}>
            <PlusCircleIcon className='h-5' />
          </button>
        </div>
      </div>
      {Loader && <FetchingDataLoader />}
      {!Loader &&
        <>
          <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto relative'>
            <table className='flex-inline w-full border-collapse'>
              <thead className='shadow bg-gray-100'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='border p-2 whitespace-nowrap'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort()
                                  ? 'cursor-pointer select-none justify-start'
                                  : 'justify-center'
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ?
                    (
                      <tr>
                        <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                          No Data Found
                        </td>
                      </tr>
                    )
                    :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td className='border p-2 whitespace-nowrap' style={{ height: `${paddingTop}px` }} />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                    {cell.column.id === "index" && (
                                      <center>{renderTableCellIndex(row)}</center>
                                    )}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column
                                          .columnDef
                                          .cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                          </tr>
                        )}
                      </>
                    )
                }
              </tbody>
            </table>
          </div>
          {
            rows.length > 0 && (
              <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
                <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
                  <button title='First Page' onClick={() => setPageIndex(0)}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                  <button title='Previous Page'
                    onClick={() => setPageIndex(pageIndex - 1)}
                    disabled={pageIndex === 0}
                    aria-current="page"
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {pageNumbers.length === 1 &&
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={1}
                      onClick={() => setPageIndex(0)}
                    >
                      {1}
                    </button>
                  }
                  {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                    if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                      if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber - 1)}
                          >
                            {pageNumber}
                          </button>
                        );
                      }
                      else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber)}
                          >
                            {pageNumber + 1}
                          </button>
                        );
                      }
                      else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(0)}
                          >
                            {1}
                          </button>
                        );
                      }
                    } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                      return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                    }
                    return null;
                  })}
                  <button title='Next Page' onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === (table.getPageCount() - 1)}
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
                <PageSizeDropdown
                  dataLength={table.getPrePaginationRowModel().rows.length}
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  totalRows={table.getPrePaginationRowModel().rows.length}
                />
              </div>
            )
          }
        </>
      }
    </div>
  );
}